.monitoring-config {
	@extend .h-reset;
	@extend %hr;
	background: $colored-frame-bg;

	.wrapper-full {
		padding-top: $global-gutters/2; padding-bottom: $global-gutters/2;
		line-height: $default-line-height * 1.5;
	}

	&-url {
		display: inline-block; max-width: 100%;
		vertical-align: bottom;
		@include media('>medium'){
			max-width: 25em;
		}
	}
}
