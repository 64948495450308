.filmstrip {
	overflow: auto;

	&-item {
		display: flex;
		flex-flow: row nowrap; justify-content: flex-start; align-items: center;

		&-frame {
			flex: 0 0 11.5em;
			text-align: center;

			&-time {
				border-right: 1px solid $hr-color;
				font-weight: $font-weight-bold;
			}

			&-figure {
				margin: .5em 0;
			}

			&-pic {
				margin: 0 .125em;
				border: 1px solid currentColor;
				color: $hr-color;
			}

			&-completion {
				margin-top: .25em;
			}

			&:last-child .filmstrip-item-frame-time {
				border-right: none;
			}

			&.is-complete {
				& ~ .filmstrip-item-frame.is-complete .filmstrip-item-frame-figure {
					opacity: .25;
				}

				.filmstrip-item-frame-completion {
					font-weight: $font-weight-bold; color: $valid-color;
				}
			}
		}
	}

	&.is-with-legend {
		.filmstrip-item-frame:first-child {
			margin-left: .5em; // So we can see the box-shadow on pic
		}

		.filmstrip-item-frame-time {
			margin-bottom: $default-line-height * 1em;
		}

		.filmstrip-item-frame:first-child .filmstrip-item-frame-pic {
			box-shadow: -.5em 0 0 0 currentColor
		}
	}
}
