.statuts-donut {
	display: flex;
	flex: 1 0 auto; flex-flow: row wrap; justify-content: center; align-items: center;

	& > * {
		margin: 0 .5em;
	}

	&-graph {
		svg {
			display: block; width: 10em !important; height: 10em !important;
		}
	}

	&-legend {
		@extend %ul-reset;

		li {
			margin: .5em 0;
		}

		&-color {
			display: inline-block; width: 1em; height: 1em;

		}
	}
}
