.page-title {
	&-frame {
		display: inline-block; padding: 1em 0;
	}
	&-text {
		font-size: $h1-font-size;
		& > svg {
			margin-right: .5em;
		}
	}
}
