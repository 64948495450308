%ul-reset {
	margin: 0; padding: 0;
	list-style: none;
}

%clearfix {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

%hr {
	border-bottom: 1px solid $hr-color;
}

%hide-checkbox {
	position: absolute; z-index: -1;
	opacity: 0;
	/* DO NOT USE visibility: hidden; TO KEEP MESSAGE WHEN REQUIRED! */
}
