.collapsible-item {
	border-radius: $global-border-radius;
	background-color: $default-bg;

	&-trigger {
		position: relative;
		display: block; width: 100%; padding: $global-gutters; box-sizing: border-box;

		&-caret {
			position: absolute; top: 50%; left: $global-gutters;
			transform: translateY(-50%);
			font-size: 2em; color: $focus-color;
			transition: .3s $caret-timing-function;
		}

		&-link-like {
			color: $link-color; text-decoration: underline;
		}

		&:hover,
		&:focus {
			.collapsible-item-trigger-link-like {
				text-decoration: none;
			}
		}
	}

	&-title {
		display: flex; padding-left: 3em;
		flex-flow: row wrap; justify-content: flex-start; align-items: baseline;

		& > * {
			margin-right: .25em;
		}

		&-name {
			max-width: 20em;
		}
	}

	&-hr {
		margin: 0;
		opacity: 0; visibility: hidden;
	}

	&-content {
		height: 0; overflow: hidden;
	}

	&-inner {
		padding: $global-gutters;
		transform: translateY(-1em);
		opacity: 0; visibility: hidden; pointer-events: none;
		transition: none;

		& > :first-child {
			margin-top: 0;
		}

		& > :last-child {
			margin-bottom: 0;
		}
	}

	&.is-open {
		.collapsible-item-trigger-caret {
			transform: translateY(-50%) rotate(180deg);
		}

		.collapsible-item-hr {
			opacity: 1; visibility: visible;
		}

		.collapsible-item-content {
			height: auto;
		}

		.collapsible-item-inner {
			transform: translateY(0);
			opacity: 1; visibility: visible; pointer-events: auto;
			transition: .25s ceaser(easeOutQuart);
		}
	}

	.no-padding {
		margin: #{-$global-gutters} #{-$global-gutters} 0;
	}
}
