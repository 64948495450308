[class*=' app-warn--'],
[class^='app-warn--'] {
	position: relative;
	padding: .1px $global-gutters;
	a:not([class*='btn']) {
		color: inherit;
	}
}

@each $class, $bg, $color in $variants-list {
	.app-warn--#{$class} {
		background-color: $bg;
		color: $color;
	}
}

.app-warn-close {
	position: absolute; top: 0; right: 0;
	padding: 0 .25em;
}
