[class*='score-color'] {
	display: inline-block; width: .75em; height: .75em;
	&[class*='valid'] { background-color: $valid-color; }
	&[class*='warning'] { background-color: $warning-color; }
	&[class*='alert'] { background-color: $alert-color; }
	&[class*='disable'] { background-color: $regular-color; }
}

.legend-square {
	display: inline-block; width: .875em; height: .875em;
}
