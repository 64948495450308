[data-db-date-picker],
[data-db-date-range-picker] {
	display: inline-block;
}

[data-db-date-picker] {
	 width: 15em;
}

[data-db-date-range-picker] {
	width: 22em;
}


.flatpickr-calendar {
	border-radius: $global-border-radius; box-shadow: 0 .125em .25em $dropdown-border-color;
	font-size: 1em; line-height: inherit;

	&.open {
		z-index: $zindex-dropdown;
	}

	input {
		&,
		&:focus {
			box-shadow: none;
		}
	}
}

.flatpickr-months {

	.flatpickr-month {
		height: 2.5em; overflow: hidden;
		border-radius: $global-border-radius $global-border-radius 0 0;

		input {
			&[disabled] {
				border-color: inherit!important;
				background-color: inherit!important;
				color: inherit!important;
				~ .arrowUp, ~ .arrowDown{
					display: none;
				}
			}
		}
	}

	.flatpickr-prev-month, .flatpickr-next-month {
		height: auto;
		line-height: inherit;
	}
}

.flatpickr-innerContainer {
	border-radius: 0 0 $global-border-radius $global-border-radius;
}
