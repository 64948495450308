.list-table {
	width: 100%; max-width: 100%;
	border-collapse: collapse;
	text-align: left;
	th, td {
		padding: .75em .5em;
		@include media('>large') {
			padding: .75em;
		}
		@include media('>xlarge') {
			padding: .75em 1em;
		}
		@include media('>xxlarge') {
			padding: .75em 1.25em;
		}
		&:first-child:not(.rowspan-fix) {
			padding-left: $global-gutters;
		}
		&:last-child {
			padding-right: $global-gutters;
		}
	}
	thead th {
		@extend %hr, .text-uppercase-first-letter;
		vertical-align: bottom;
	}
	tbody tr {
		background-color: $list-table-tr-bg;
		transition: background-color $default-duration $default-timing-function;
		&:nth-child(even) {
			background-color: $list-table-tr-bg-alternate;
		}
		&:hover {
			background-color: $list-table-tr-bg-hover;
		}
	}
	tbody {
		td,
		th {
			&:not(.is-wrap) {
				white-space: nowrap;
			}
		}
		th {
			font-weight: $font-weight-normal;
		}
	}
	.is-highlight {
		&,
		&:nth-child(even) {
			background-color: $list-table-bg-highlight;
		}
		&:hover {
			background-color: $list-table-tr-bg-hover;
		}
	}
	.is-align-bottom {
		vertical-align: bottom;
	}
	.is-align-top {
		vertical-align: top;
	}
	.is-bordered {
		border: solid $softgrey; border-width: 1px 0;
	}
	.table-full-width,
	.table-75-width,
	.table-half-width,
	.table-third-width,
	.table-twice-width {
		max-width: 0; overflow: hidden;
	}
	.table-full-width,
	.table-75-width,
	.table-half-width,
	.table-third-width {
		min-width: 12em;
	}
	.table-full-width {
		width: 100%;
	}
	.table-75-width {
		width: 75%;
	}
	.table-half-width {
		width: 50%;
	}
	.table-third-width {
		width: 33.3%;
	}
	.table-twice-width {
		min-width: 8em;
	}
	.item-max-sized {
 		max-width: 15em;
	}

	.aligned-values {
		display: table; width: 100%;

		& > * {
			display: table-cell; width: 50%;

			&:first-child {
				padding-right: .25em;
				text-align: right;
			}

			&:last-child {
				padding-left: .25em;
				text-align: left;
			}
		}
	}
}

.monitorings-manage {
	tbody tr:nth-child(even){
		background-color: #fff;
	}
	tbody tr{
		background-color: $list-table-tr-bg;
	}
	tbody:nth-of-type(even) tr{
		background-color: $list-table-tr-bg-alternate;
	}

	tbody {
		transition: background-color $default-duration $default-timing-function;
		&:hover {
			tr{
				background-color: $list-table-tr-bg-hover;
			}
		}
	}
}

.is-for-dashboard {
	col:nth-child(even) {
		background-color: $list-table-tr-bg-alternate;
	}
	td, th {
		padding: .75em .25em;
	}
	thead th {
		border-bottom: none;
	}
	thead tr:last-child th,
	thead th[rowspan] {
		@extend %hr, .text-uppercase-first-letter;
		vertical-align: bottom;
	}
	tbody tr {
		background-color: transparent;
		&:nth-child(even) {
			background-color: rgba(darken($list-table-tr-bg-alternate, 10%), .25);
		}
		&.is-main-item {
			border-top: 1px solid darken($list-table-tr-border-color, 10%);
			background-color: $list-table-tr-bg-group;
			th, td {
				font-weight: $font-weight-bold;
			}
		}
		&.is-sub-item th a {
			margin-left: 1em;
		}
		&:hover {
			background-color: $list-table-tr-bg-hover;
		}
	}
}
