.toolbar {
	@extend %hr;

	.wrapper-full {
		display: flex; padding-top: $global-gutters/4; padding-bottom: $global-gutters/4;
		flex-flow: row wrap-reverse; justify-content: space-between; align-items: center;

		& > * {
			margin: $global-gutters/4 0;
			flex: 0 1 auto;
		}

		& > .is-pushed-right {
			@extend .is-pushed-right;
		}

		.input-w-control {
			width: auto;
		}

		[data-db-date-picker],
		[data-db-date-range-picker] {
			.input-w-control {
				width: 100%;
			}
		}
	}

	[for='inpUrlFilter'] {
		margin-left: $global-gutters;
	}
}
