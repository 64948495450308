.toolbar-aggreg {
	@extend .toolbar;

	.wrapper-full {
		flex-wrap: wrap;

		@include media('>large') {
			flex-wrap: nowrap;
		}

		.toolbar-aggreg-inner > * {
			display: inline-block; margin: .25em 0;
			white-space: nowrap;
		}
	}
}
