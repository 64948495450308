.logged-in {

	.nav-container {
		display: flex;
		flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
		background-color: $nav-bg;
		color: $nav-color;

		@include media('>small') {
			border-right: $nav-border-width solid $nav-border-color;
		}

		@mixin app-logos {
			.app-logo-link-icon-tiny {
				opacity: 1; visibility: visible;
			}
			.app-logo-link-icon-full {
				opacity: 0; visibility: hidden;
			}
		}

		@mixin app-nav-item {
			.app-nav {
				&-item {
					padding: 0;
				}

				&-toggle-icon {
					transform: rotate(180deg);
				}

				&-game-text {
					max-height: 1em;
				}
			}
		}

		@include media('>medium') {
			&.is-narrow {
				@include app-logos;
				@include app-nav-item;
			}
		}

		@include media('>small', '<=medium') {
			&:not(.is-narrow) {
				@include app-logos;
				@include app-nav-item;
			}
		}
	}

	.nav-header {
		height: auto; margin-bottom: $default-line-height * 1em; padding-bottom: 0;
		border-bottom: none;
		background-color: $header-bg;
	}

	.app-logo {
		&-link {
			display: block; padding: $nav-trigger-padding $nav-item-padding;

			@include media('>small') {
				padding: $global-gutters / 2 $nav-item-padding;
			}

			&:hover,
			&:focus {
				background-color: $header-hover-bg;
			}

			&-icon {
				position: relative;
				display: block;

				&-tiny,
				&-full {
					display: block;
					transition: opacity $nav-toggle-duration/2 $default-timing-function;
				}

				&-tiny {
					position: absolute; top: 50%; left: 50%;
					width: 2em; height: auto;
					transform: translate(-50%,-50%);
					opacity: 0; visibility: hidden;
				}

				&-full {
					width: auto; height: $nav-header-logo-height; margin: 0 auto; max-width: 15em;
				}
			}
		}
	}

	.app-nav {

		ul {
			@extend %ul-reset;
		}

		li {
			position: relative;
		}

		&-item {
			@extend .text-ellipsis;
			display: flex; width: 100%; padding: 0 $nav-item-padding; box-sizing: border-box;
			outline: none;
			flex-flow: row nowrap; justify-content: flex-start; align-items: center;
			font-size: $nav-item-font-size; text-decoration: none;

			&-icon,
			&-text {
				padding: $nav-item-padding;
			}

			&-icon {
				color: $nav-item-icon-color;
			}

			&-text {
				flex: 1 1 auto;
				font: $nav-font-style $nav-font-weight 1em/1 $nav-font-family; color: $nav-item-text-color;
				transition: color 0s linear $default-duration/2; // avoid blink transition effect
			}

			&-arrow {
				color: $nav-item-text-color;

				@include media('<=small') {
					transform: rotate(90deg);
				}
			}

			&-icon,
			&-arrow {
				svg {
					display: block;
				}
			}

			.user-quota {
				display: flex; width: 1.5em; height: 1em; margin: 0 -.25em; box-sizing: border-box;
				border: 2px solid $nav-item-icon-color; border-radius: .5em;
				color: $nav-item-text-color; text-align: center;

				&.is-warning {
					border-color: darken($warning-color, 10%);
					background-color: $warning-color;
				}

				&.is-alert {
					border-color: darken($alert-color, 10%);
					background-color: $alert-color;
				}

				&-text {
					margin: auto;
					font: $font-weight-bold .625em/1 $default-font-family;
				}
			}

			&--cta {
				background-color: $nav-item-cta-bg;

				.app-nav-item-icon {
					color: $nav-item-cta-icon-color;
				}

				.app-nav-item-text {
					color: $nav-item-cta-text-color;
				}
			}

			&:hover,
			&:focus,
			&.is-focused {
				background-color: $nav-item-hover-bg;

				.app-nav-item-icon {
					color: $nav-item-hover-icon-color;
				}

				.app-nav-item-text,
				.app-nav-item-arrow {
					color: $nav-item-hover-text-color;
				}

				.user-quota {
					color: $focus-color;

					&.is-warning,
					&.is-alert {
						color: $nav-item-text-color;
					}
				}
			}

			&.is-active {
				background-color: $nav-item-active-bg;

				.app-nav-item-icon {
					color: $nav-item-active-icon-color;
				}

				.app-nav-item-text,
				.app-nav-item-arrow {
					color: $nav-item-active-text-color;
				}

				.user-quota {
					border-color: $white;
					color: $nav-item-active-text-color;
				}
			}

		}

		hr {
			margin: $default-line-height * .5em 0;
			border-bottom-color: $header-bg;
		}

		&-sub-dashboards {
			max-height: 0; max-width: $sub-nav-max-width; overflow: hidden;
			background-color: $subnav-bg;
			font: $default-font-style $default-font-weight 1em/#{$default-line-height} $default-font-family; color: $subnav-color;
			transition: $default-duration $default-timing-function;

			&.is-focused {
				max-height: (($default-line-height * 1em) + ($nav-item-padding * 2)) * 10;
			}

			@include media('>small') {
				position: absolute; top: 0; left: 100%; z-index: $zindex-subnav;
				max-height: none; overflow: initial;
				opacity: 0; visibility: hidden;

				&::before {
					position: absolute; top: .625em; left: 0; z-index: -1;
					display: block; width: .75em; height: .75em;
					transform: rotate(45deg); transform-origin: top left;
					background-color: $subnav-bg;
					content: '';
				}

				&.is-focused {
					max-height: none;
					transform: translateX(1rem);
					opacity: 1; visibility: visible;
				}
			}

			.is-standard {
				border-bottom: 1px solid $darkgrey;
			}

			&-item {
				display: block; margin-left: $nav-item-padding; padding: $nav-item-padding $nav-item-padding * 2;
				color: $subnav-item-color; text-decoration: none;

				&:hover,
				&:focus {
					background-color: $subnav-item-hover-bg;
					color: $subnav-item-hover-color;
				}
			}
		}

		&-game {
			background-color: $nav-game-bg;
			color: $nav-game-color;

			&:hover,
			&:focus {
				background-color: $nav-game-hover-bg;

				.app-nav-item-text {
					color: $nav-game-hover-color;
				}

				.app-nav-game-link {
					text-decoration: underline;
				}
			}

			&-icon-svg {
				width: 1.75em; height: 1.75em; margin: -.375em;
			}

			&-text {
				max-height: 3em;
				transition: $nav-toggle-duration $default-timing-function;
			}

			&-title,
			&-sstitle,
			&-link {
				display: block;
				line-height: 1.2;
			}

			&-title,
			&-sstitle {
				font-size: .625em;
			}

			&-sstitle {
				opacity: .5;
			}

		}

		&-toggle {
			display: flex; padding-right: $nav-item-padding; overflow: hidden;
			flex-flow: row nowrap; justify-content: flex-end; align-items: center;
			outline: none;
			background-color: $nav-toggle-bg;
			font-size: 1.5em; text-align: right; color: $nav-toggle-color;

			@include media('<=small') {
				display: none;
			}

			&:hover,
			&:focus {
				background-color: $nav-toggle-hover-bg;
				color: $nav-toggle-hover-color;
			}

			&-icon {
				transition: transform $nav-toggle-duration $default-timing-function;
			}
		}

	}

	.app-nav-bottom {
		display: flex;
		flex-flow: column nowrap; justify-content: flex-end; align-items: stretch;
		flex: 1 0 auto;
	}

	#menu-goto-cs {
		font-size: 1.2em;
		width: 85%;
		margin: 5% 7.5%;
		border-color: $nav-goto-cs-border-color;
		background-color: $nav-goto-cs-bg;
		
		&:hover, &:focus {
		background-color: $nav-goto-cs-hover-bg;

			.app-nav-item-text{
				color: $nav-goto-cs-hover-color;
			}
		}
	}
}
