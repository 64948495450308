/* Code snippet to be included in several media queries */
@mixin narrow-tooltip {
	td {
		display: block; padding: 0 !important;
	}

	td:nth-child(odd) {
		text-align: left;
	}

	td:nth-child(even) {
		padding-left: 2em !important;
	}
}



.comparison-items-grid {
	--column-count: 1;
	display: grid;
	grid-template-columns: repeat(var(--column-count), 1fr ); grid-gap: $global-gutters $global-gutters; grid-auto-flow: row dense; justify-items: stretch; align-items: stretch;

	& > * {
		min-width: 0;
	}

	&.is-2-items {
		@include media('>52rem') {
			--column-count: 2;
		}
		@include media('>52rem', '<=75rem') {
			.analysis-item-metas-tooltip {
				@include narrow-tooltip;
			}
		}
	}

	&.is-repeated-items {
		@include media('>40rem') {
			grid-template-columns: 2fr 1fr;
		}
		@include media('>75rem') {
			.analysis-item:last-child .analysis-item-metas-tooltip {
				@include narrow-tooltip;
			}
		}

	}

	&.is-3-items,
	&.is-5-items,
	&.is-6-items,
	&.is-9-items {
		@include media('>30rem', '<=60rem') {
			--column-count: 2;
		}
		@include media('>60rem') {
			--column-count: 3;
		}
		@include media('>30rem') {
			.analysis-item-metas-tooltip {
				@include narrow-tooltip;
			}
		}
	}

	&.is-4-items,
	&.is-7-items,
	&.is-8-items,
	&.is-10-items,
	&.is-11-items,
	&.is-12-items {
		@include media('>30rem', '<=75rem') {
			--column-count: 2;
		}
		@include media('>75rem') {
			--column-count: 4;

			.analysis-item-metas svg {
				display: none;
			}
		}
		@include media('>30rem') {
			.analysis-item-metas-tooltip {
				@include narrow-tooltip;
			}
		}
	}
}

.analysis-item {
	display: flex;
	flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
	padding: $global-gutters;
	border-top: .5em solid transparent; border-radius: $global-border-radius;
	background-color: $default-bg;

	&-hr {
		margin-left: -$global-gutters; margin-right: -$global-gutters;
	}

	&-infos {
		display: flex; margin: -.5em 0;
		flex: 1 0 auto; flex-flow: row wrap-reverse; justify-content: space-around; align-items: center;

		& > * {
			margin: .5em 0;
		}
	}

	&-snapshot {
		width:11.25em; max-height: 11.25em; box-sizing: border-box;

		&-inner {
			max-height: 11.25em; box-sizing: border-box; overflow: hidden;
			border: 1px solid $softgrey;
		}

		&.is-small {
			padding: 0 1.5625em; max-height: 8.125em;

			.analysis-item-snapshot-inner {
				max-height: 8.125em;
			}
		}

		img {
			width: auto;
		}
	}

	&-score {
		display: flex; min-width: 18em; max-width: 25em;
		flex: 0 1 60%; flex-flow: row nowrap; justify-content: space-between; align-items: center;

		&.is-only-score {
			display: block; min-width: initial; max-width: initial;
			flex: 0 0 auto;
		}

		& > * {
			flex: 0 0 auto;
		}

		&-donut {
			$size: 7em;
			width: $size; height: $size;

			svg {
				width: $size !important; height: $size !important;
			}
		}

		&-fails,
		&-warnings,
		&-successes {
			display: flex; width: 2.5em; height: 2.5em;
			justify-content: center; align-items: center;
			border-radius: 50%;
			font-size: 1.25em; color: $white;
		}

		&-fails {
			background-color: $alert-color;
		}

		&-warnings {
			background-color: $warning-color;
		}

		&-successes {
			background-color: $softgrey;
		}
	}

	&-metas {
		position: relative;
		display: flex;
		flex: 0 0 auto; flex-flow: row wrap; justify-content: space-between; align-items: center;

		&-tooltip {
			position: absolute; top: 150%; left: 0; right: 0; z-index: $zindex-sticky-top + 1;
			padding: .5em;
			transform: translateY(.5em);
			border-radius: $global-border-radius;
			background-color: $tooltip-bg;
			color: $tooltip-color;
			opacity: 0; visibility: hidden; pointer-events: none;
			transition: $default-duration $default-timing-function;

			&::before {
				position: absolute; top: -.5em; left: 1em;
				width: 0; height: 0;
				transform: rotate(45deg);
				border: .5em solid transparent; border-left-color: $tooltip-bg; border-top-color: $tooltip-bg;
				content: '';
			}

			table {
				width: 100%;
			}

			td {
				padding: .25em .5em !important;
				vertical-align: top;
			}

			td:nth-child(odd) {
				text-align: right; white-space: nowrap;
			}

			tr:first-child td:last-child {
				word-break: break-all;
			}

			@include media('<small') {
				@include narrow-tooltip;
			}
		}

		&-config:hover,
		&-config:focus {
			& + .analysis-item-metas-tooltip {
				transform: translateY(0);
				opacity: 1; visibility: visible;
			}
		}
	}

	@include media('>52rem') {
		&.is-infos-mirror .analysis-item-infos {
			flex-direction: row-reverse;
		}

		&.is-score-mirror .analysis-item-score {
			flex-direction: row-reverse;
		}
	}
}

.alert-item {
	background-color: rgba($alert-color, .15);

	&-container {
		padding: $global-gutters; box-sizing: border-box;
		background-color: $alert-color;
		color: $white;

		@include media('>52rem') {
			width: calc(50% - #{$global-gutters / 2}); margin-left: auto;
		}
	}
}

.comparison-advanced-table {
	thead {
		color: $white;
	}

	&-th-ref {
		background-color: var(--color-1);
	}

	&-th-switch {
		width: 9em; padding-left: 0; padding-right: 0;
		background-image: linear-gradient(to right, var(--color-1) 50%, var(--color-2) 50%);
	}

	&-th-comp {
		background-color: var(--color-2);
	}

	.btn-switch-comparison {
		margin: #{-$input-vpadding} 0;
		border-radius: #{($default-line-height / 2 * 1em) + $input-vpadding};
	}

	td.is-url {
		@include media('>small') {
			word-break: break-all;
		}
	}
}

.comparison-speed-index {
	display: flex;
	flex-flow: row wrap; justify-content: flex-start; align-items: baseline;

	& > * {
		margin-right: 1em;
	}

	&-item {
		margin-top: .25em; margin-bottom: .25em;

		&:not(.text-white)::before {
			display: inline-block; width: .75em; height: .75em; margin-right: .25em;
			background-color: var(--color);
			content: '';
		}

		&.text-white {
			padding: 0 .25em;
			background-color: var(--color);
		}
	}
}
