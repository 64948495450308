.features-breadcrumb {
	@extend %ul-reset;
	display: flex;
	flex-flow: row wrap; justify-content: center; align-items: center;
	text-align: center;

	li {
		@include media(">large") {
			flex: 1 1 auto;
		}

		& > a,
		& > span {
			display: block; padding: 1em;
			border-bottom: 2px solid transparent;
			color: inherit; text-decoration: none;
		}
		& > a:hover,
		& > a:focus,
		& > span {
			border-bottom-color: currentColor;
		}
	}
}

.features-col {
	display: flex;
	flex-flow: column nowrap;

	.col-text {
		flex: 1 0 auto;
	}
}
