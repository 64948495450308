.ngpopup-container {
    z-index: 9000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-color: rgba(43,61,85,0.7);
    overflow: auto;
}

.ngpopup-container.leanModalContainer {
	z-index: 3000;
}

.ngpopup-container.leanModalContainer p {
	margin-top: 50px;
	margin-right: 10px;
}

#ngpopup {
	z-index: 2500;
    width: 800px;
    padding: 20px;
    color: #516579;
    text-align: left;
    border-radius: 2px;
    margin: 80px auto 0;
    background-color: #FFF;
/*     box-shadow: 0 0 0 5px rgba(255,255,255,.1); */
}

.scrollablePopup {
    position: absolute;
	overflow: auto;
    overflow-x: hidden;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

#ngpopup.confirmDelete {
	width: 600px;
}

/*
 * Popup de login only
 */
#ngpopup.loginPopup,#ngpopup.signupPopup{
	width: 400px;
}

#ngpopup.loginPopup .ngpopup-login, #ngpopup.signupPopup .ngpopup-signup{
	width: initial;
	margin-right:18px;
	margin-left:18px;
	float: none;
}

#ngpopup.loginPopup .ngpopup-login .login-fields {
	margin-top:35px;
}

#ngpopup.loginPopup .ngpopupHeader span {
	float: right;
	margin-right: -35px;
}

#ngpopup.loginPopup .field, #ngpopup.signupPopup .field {
	font-size: 14px;
}

#ngpopup.loginPopup .field input, #ngpopup.signupPopup .field input{
	width: 80%;
	margin-top: 5px;
}

#ngpopup.loginPopup span.fui-field-icon, #ngpopup.signupPopup span.fui-field-icon{
	padding-top: 16px;
	top: 17px;
}

#ngpopup.loginPopup .field label, #ngpopup.signupPopup .field label{
	color: #516579;
	font-weight: normal;
}

#ngpopup.loginPopup  .actions, #ngpopup.signupPopup .actions {
	text-align: center;
	margin-right: 5px;
	float:none;
}

#ngpopup.loginPopup .login-actions, #ngpopup.signupPopup .signup-actions, #ngpopup.signupPopup .signup-actions-validated{
	margin-right:0;
}

#ngpopup.loginPopup .subscribeAction {
	margin-top: 50px;
}

#ngpopup.loginPopup .subscribeAction hr {
	border: none;
	border-bottom: 3px #ebeff1 solid;
	background-color: #ebeff1;
	color: #ebeff1;
	margin-bottom: 40px;
}

#ngpopup.signupPopup .field p.error {
	float: none;
}

#freeAccountPresentation {
	float: right;
	margin-left: 20px;
	margin-top: 12px;
	width: 45%;
}

#freeAccountPresentation p{
	font-size: 14px;
	line-height: 24px;
}

#freeAccountPresentation li{
	font-size: 13px;
}

#freeAccountPresentation li h4 {
	margin-bottom: 5px;
	margin-top: 15px;
	font-size: 15px;
}

#ngpopup h2 {
	margin-top:0;
}

#ngpopup .ngpopupHeader {
	margin-bottom: 10px;
	border-bottom: 1px solid #ebeff1;
}

#ngpopup .ngpopupHeader h2 {
	padding-bottom: 1px;
}

#ngpopup #forgotPasswordAction {
	font-size: 12px;
}

#ngpopup .ngpopup-signup {
	float: left;
	margin-left: 15px;
}

#ngpopup .ngpopup-signup .signup-actions, #ngpopup .ngpopup-signup .signup-actions-validated, #ngpopup.mixedLoginSignupPopup .login-actions {
	font-weight: bold;
	padding: 10px 15px;
	font-size: 14px;
}

#ngpopup .ngpopup-signup .signup-actions-validated{
	padding: 8px 73px 9px;
}

#ngpopup .close, #ngpopup .maximizeButton {
    color: #CCC;
    padding: 8px;
    margin-left: 5px;
    margin-top:-23px;
    margin-right:-14px;
    text-align: right;
    font-weight:bold;
    text-transform: uppercase;
    font-size:17px;
    cursor:pointer;
}

#ngpopup .maximizeButton {
	font-size: 19px;
	margin-top: -25px;
}

#ngpopup .close:hover, #ngpopup .maximizeButton:hover {
    color: #000;
}

#ngpopup.maximize{
	width:95%;
	height:90%;
	margin: 10px auto 10px;
}
#ngpopup.maximize .scroll{
	max-height:90%;
}

#ngpopup.maximize .harContainer{
	height: calc(100% - 275px);
}

#ngpopup.maximize .harContainerInner{
	height: 100%;
}

#ngpopup.maximize .harContainer iframe{
	height: 100%;
}

.ngpopup {
	cursor: pointer;
}

#ngpopup.upsellingPopupContainer {
	width: 600px;
	padding-top: 10px;
}

#ngpopup.upsellingPopupContainer.downloadPdfPopup {
	padding-top: 20px;
}

#ngpopup.upsellingPopupContainer h2 {
	font-size: 20px;
}

.upsellingPopup {
	text-align: center;
	margin: auto;
}
.upsellingPopup .introPopup {
	font-size: 25px;
}

.upsellingPopup a.principalButton {
	padding: 10px 50px;
}

.upsellingPopup .textualContent {
	font-size: 16px;
	line-height: 1.8em;
}

.upsellingPopup .upsellingBottom p {
	margin: 0 80px;
}

.upsellingBottom p.buttonContainer {
	margin-top: 20px;
	margin-bottom: 20px;
}

.upsellingBottom p.unclickableUrl {
	color: #0E141A;
}

#unauthorizedLogin {
	margin-bottom: 10px;
	margin-top: 10px;
}

#unauthorizedPopup .width46left{
	position: relative;
}

#unauthorizedLoginForm {
	color:#989898;
	font-size: 14px;
	margin-top: 30px;
	text-align: right;
}

#ngpopup.trialPopup {
	width: 530px;
}

#ngpopup.trialPopup label{
	color: #2c3e50;
	font-size: 16px;
}

.trialPopup input[type="text"], .trialPopup input[type="email"], .trialPopup input[type="password"]{
	border-radius: 5px;
	padding: .6em 1em .6em 2.4em;
	margin-bottom: 26px;
	font-size: 1.1em;
	margin-top: 9px;
	width: 80%;
}

.trialPopup input[type="checkbox"] {
	margin: 10px 0 0;
}

#ngpopup.trialPopup .ngpopupHeader{
	margin-bottom: 35px;
}

.trialPopup .placeholder {
	color: #CCC;
	font-size: 1.6em;
	position: absolute;
	left: .5em;
	top: 1.7em;
}

.trialPopup p.error{
	margin: 0;
}

.trialPopup input.error{
	border-color: red;
	margin-bottom: 8px;
}

#freeTrialButton{
	font-size: 18px;
	font-weight: 600;
	padding: 12px 20px;
}

.trialPopup form div {
	position: relative;
}

.trialPopup .button {
	width: 220px;
	margin-bottom: 9px;
}

.trialPopup .button.loading {
	padding: 8px 0 9px;
	border: none;
	font-size: 22px;
}

/*
 * Onboarding feedback modal
 */
#ngpopup.feedbackModal{
    width: 450px;
}

#feedbackModalContainer textarea{
    width: 96%;
    height: 70px;
    margin-bottom: 30px;
    resize: vertical;
}

#feedbackModalContainer .buttonContainer{
    text-align: center;
}

#feedbackModalContainer .buttonContainer .prettyButton{
    padding: 9px 16px;
    margin-right: 15px;
}

#ngpopup.downloadPdfPopup #whiteLabelPromotion{
	margin:40px auto;
	color: #516579;
    text-align: center;
}

#ngpopup.downloadPdfPopup #whiteLabelPromotion .whiteLabelPromotionText{
    margin-top: 25px;
}

#ngpopup.downloadPdfPopup img{
	margin: 0 auto;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
}

#ngpopup.downloadPdfPopup h3{
    color: #516579;
    font-size: 20px;
    font-weight: 800;
}

#ngpopup.downloadPdfPopup ul{
    margin: 20px 0 20px 100px;
    padding-left: 0;
    list-style-type: none;
    text-align: left;
    font-size:16px;
    font-weight: 600;
}

#ngpopup.downloadPdfPopup ul li{
    margin-top: 12px;
}

#ngpopup.downloadPdfPopup li:before{
	 content: '- ';
}

#ngpopup.downloadPdfPopup #configurationPdf{
	margin: 0 0 30px 70px;
}

#ngpopup.downloadPdfPopup .pdfConf{
    text-align: left;
	margin: 15px 0 0 20px;
}

#ngpopup.downloadPdfPopup .pdfConf span{
    margin-bottom: 8px;
    display: inline-block;
    font-size: 16px;
    cursor:pointer;
}

#ngpopup.downloadPdfPopup .pdfConf span.sprite{
    margin: 0 0 -1px 10px;
}

#ngpopup.downloadPdfPopup .pdfConf .ion{
    font-size: 19px;
    padding-right: 12px;
    vertical-align: top;
}

#ngpopup.downloadPdfPopup .pdfConf .ion-checkbox{
	height: 12px;
	width: 12px;
	font-size: 14px;
	padding: 0 2px 4px;
	margin-right: 8px;
	border-radius: 5px;
	border: 2px solid #516579;
}

#ngpopup.downloadPdfPopup .button{
    font-weight: 800;
}

#ngpopup.downloadPdfPopup .ctaButton{
    margin-bottom: 10px;
}

#ngpopup.downloadPdfPopup.multiConfPopup{
    width: 450px;
}

#ngpopup.downloadPdfPopup.multiConfPopup #pdfDownloadContainer, #ngpopup.configureDashboardPopupContainer #pdfDownloadContainer{
	text-align: center;
	margin-top: 40px;
}

#ngpopup.downloadPdfPopup .pdfConf li:before,
#ngpopup.downloadPdfPopup .subPdfConf li:before{
	 content: none;
}

#ngpopup.downloadPdfPopup .subPdfConf{
    margin: 0 0 0 60px;
    margin-bottom: 10px;
}

#ngpopup.downloadPdfPopup .subPdfConf.disabled{
    color: #ccc;
}

#ngpopup.downloadPdfPopup .subPdfConf li{
    display: inline;
    margin-right: 20px;
}

#ngpopup.downloadPdfPopup .subPdfConf li,
#ngpopup.downloadPdfPopup .pdfConf li{
	cursor: pointer;
}

#ngpopup.downloadPdfPopup .subPdfConf.disabled li{
    cursor: default;
}

#multiConfPdf h3 {
	padding-left: 4px;
	padding-top: 15px;
}

#pdfDownloadContainer .button {
	padding: 10px 17px;
	font-size: 16px;
	font-weight: 600;
}

#ngpopup.videoPopup {
	width: 605px;
}

#ngpopup .harContainer {
	min-height: 150px;
}

#ngpopup.WaitingPdfPopup {
	width: 400px;
}

#ngpopup.featureImgPopupContainer {
	width: 815px;
}

#ngpopup.featureImgPopupContainer.onLeaveSubscribePopup {
	width: 730px;
}

#ngpopup.featureImgPopupContainer .close {
	float: right;
}

#ngpopup.featureImgPopupContainer .actions {
    float: none;
    text-align: center;
}

#ngpopup.featureImgPopupContainer img {
	border: 1px solid #E0E0E0;
	border-radius: 5px;
    padding: 5px;
}

#ngpopup.featureImgPopupContainer .ngpopup-signup {
	margin-left: 42px;
}

#ngpopup.featureImgPopupContainer.onLeaveSubscribePopup .ngpopup-signup {
    margin-left: 0px;
}

#ngpopup.featureImgPopupContainer.onLeaveSubscribePopup .ngpopup-signup span.fui{
    top: 20px;
}

.featureImgPopupContainer .signupPopup #showHidebuttonIcons {
	right: 92px;
    top: 27px;
}

.featureImgPopupContainer .signupPopup .cgfield {
	margin-top: 20px;
}

/*
 * On leave subscribe modal
 */
.onLeaveSubscribePopup h2 {
	margin-left: 42px;
}

.onLeaveSubscribePopup .punchline {
	text-align: center;
	padding-bottom: 20px;
	font-size: 20px;
}

.onLeaveSubscribePopup .leftPart {
	padding: 38px 0 0 20px;
}

.onLeaveSubscribePopup p {
	font-size: 16px;
}

#ngpopup.onLeaveSubscribePopup img {
	margin: 0;
	padding: 0;
}

.onLeaveSubscribePopup ul {
	margin-bottom: 40px;
}

.onLeaveSubscribePopup .signupPopup #showHidebuttonIcons {
	right: 20px;
}

#temporaryDiscountPopup p {
	font-size: 15px;
}

#temporaryDiscountPopup .strongTitle {
	margin: 15px auto;
	font-size: 26px;
	font-weight: bold;
}

#temporaryDiscountPopup li {
	text-align: left;
	padding: 0;
	list-style: none;
	font-size: 14px;
	color: #555;
	margin-bottom: 10px;
}


#temporaryDiscountPopup li .ion {
	font-size: 22px;
    color: #2BA6CB;
}

#temporaryDiscountPopup  .last {
	margin: 25px 0px;
}
.onLeaveDiscountCircle {
/*	top: 0;
    width: 100%;
    padding-top: 15px;
    position: absolute;*/
    text-align: center;
}

.onLeaveDiscountInnerCircle {
 	padding: 2px;
    width: 150px;
    margin: auto;
    height: 150px;
    border-radius: 50%;
    background-color: #d75553;
}

.onLeaveDiscountCircleText {
	color: #fff;
	padding: 1px;
    height: 144px;
    font-size: 16px;
    border-radius: 50%;
    border: 2px solid #fff;
}

.onLeaveDiscountCircleText p {
	margin: 0;
}

.onLeaveDiscountCircleText .discountValue {
	color: #fff;
    font-weight: bold;
    font-size: 55px;
    padding-top: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.onLeaveDiscountCircleText .discountUnit {
	font-size: 31px;
}

.onLeaveDiscountCircle .discountCode {
	padding: 0;
	color: #D75553;
    font-size: 18px;
    display: inline-block;
    background-color: rgba(241, 241, 241, 0.85);
}

.saveAuditParam img {
	margin-top: 20px;
}

.noPDFPopup img.appScreen {
	margin: 0;
}

/* Timeline modal */
.timelineInfo {
	font-size:40px;
	font-weight:bold;
	color: #fff;
}

.globalResults h2 {
	color: #ebeff1;
	font-size: 20px;
}

.avgInfo {
	font-size: 30px;
	font-weight: bold;
}

.weightOK {
	color: #2dcb71;
}

.weightAVG {
    color: #e57d21;
}

.weightBAD {
    color: #bf392b;
}

#ngpopup.customTimings table{
	width: 100%
}
