input,
.input-like,
select,
.select-like,
textarea {
	display: inline-block; min-width: 0; /* width: 100%; */ margin: $input-vmargin 0; padding: $input-vpadding $input-hpadding; /* box-sizing: border-box; */
	border: $input-border-width solid $input-border-color; box-shadow: 0 0 $input-shadow-width rgba($input-border-color, $input-shadow-opacity); border-radius: $global-border-radius; outline: none;
	background-color: $input-bg;
	color: $input-color; font: $input-font-style $input-font-weight 1em/#{$default-line-height} $input-font-family; text-align: left;
	vertical-align: baseline;
	&::placeholder {
		color: $input-placeholder-color;
		transition: color $default-duration $default-timing-function;
	}
	&:focus {
		border-color: $input-focus-border-color; box-shadow: 0 0 $input-shadow-width rgba($input-focus-border-color, $input-shadow-opacity);
		&::placeholder {
			color: $input-focus-placeholder-color;
		}
	}
	&.is-warning {
		border-color: $warning-color; // Not currentColor because of Chrome autofill style
		color: $warning-color;
	}
	&.is-alert {
		border-color: $alert-color; // Not currentColor because of Chrome autofill style
		color: $alert-color;
	}
	&.is-code {
		font-family: monospace;
	}
	&:disabled,
	&[disabled],
	&[aria-disabled='true'],
	&.is-disabled {
		border-color: $input-disabled-color;
		background-color: $input-disabled-bg;
		color: $input-disabled-color;
		cursor: not-allowed; pointer-events: none;
		&::placeholder {
			color: $input-disabled-placeholder-color;
		}
	}
}

select {
	padding-top:    calc(#{$input-vpadding} - #{$input-border-width});
	padding-bottom: calc(#{$input-vpadding} - #{$input-border-width});
}

.select-like {
	position: relative;
	padding-right: 2em;

	&-icon {
		display: inline-block; width: 2.5em; margin-left: calc( #{$input-hpadding * -1} - #{$input-border-width}); margin-right: .25em;
		line-height: .9; text-align: center;
		vertical-align: middle;
	}

	&-caret {
		position: absolute; top: 50%; right: .5em;
		width: 1em; height: 1em;
		transform: translateY(-50%);
		color: $input-border-color;
		transition: transform $default-duration * 2 $caret-timing-function;
	}

	&:hover,
	&:focus {
		color: $focus-color;
	}

	&:focus {
		.select-like-caret {
			transform: translateY(-50%) rotate(180deg);
			color:  $input-focus-border-color;
		}
	}

	&:disabled,
	&[disabled],
	&[aria-disabled='true'],
	&.is-disabled {
		.select-like-caret {
			color: $input-disabled-color;
		}
	}
}

.input-w-control {
	position: relative;
	display: inline-block; width: 100%; margin: $input-vmargin 0;
	input {
		margin: 0; padding-right: 1.5em;
	}
	.btn--reset {
		position: absolute; top: 0; right: 0;
		margin: 0; padding: $input-vpadding;
		color: $focus-color;
		opacity: .5;
		&:hover,
		&:focus {
			opacity: 1;
		}
	}
	.is-empty + button[type] {
		display: none;
	}
}

input.is-sized,
select.is-sized,
textarea.is-sized,
.input-like.is-sized,
.select-like.is-sized,
.input-w-control.is-sized,
.switchbox {
	max-width: 15em; margin: $input-vmargin $input-hmargin;
	&:first-child {
		margin-left: 0;
	}
	&:last-child {
		margin-right: 0;
	}
}

.switchbox {
	position: relative;
	display: inline-block;
	vertical-align: baseline;

	label {
		@extend input;
		position: relative;
		display: block; width: $switchbox-width; margin: 0;
		background-color: $switchbox-bg;
		text-align: center;
		cursor: pointer;
		&::before {
			content: '\00a0'; /* space */ pointer-events: none;
		}
		.mark {
			position: absolute; top: 50%; left: $switchbox-mark-offset;
			display: block; width: $switchbox-mark-height; height: $switchbox-mark-height; padding: $switchbox-mark-padding; box-sizing: border-box;
			transform: translateY(-50%);
			border: $switchbox-mark-border-width solid $switchbox-mark-border-color; border-radius: $global-border-radius;
			background-color: $switchbox-mark-bg;
			color: $switchbox-mark-color;
			pointer-events: none;
			transition: $default-duration ceaser(easeOutBack);
			&-check,
			&-cross {
				transition: opacity $default-duration $default-timing-function;
			}
			&-check {
				opacity: 0;
			}
			&-cross {
				opacity: 1;
			}
		}
	}

	[type='checkbox'] {
		@extend %hide-checkbox;

		&:checked + label {
			border-color: $switchbox-chkd-border-color;
			background-color: $switchbox-chkd-bg;
			.mark {
				transform: translate($switchbox-mark-translation, -50%);
				border-color: $switchbox-chkd-mark-border-color;
				color: $switchbox-chkd-mark-color;
				&-check {
					opacity: 1;
				}
				&-cross {
					opacity: 0;
				}
			}
		}
		&:not(:checked) + label:hover,
		&:not(:checked):focus + label {
			border-color: darken($switchbox-border-color, $switchbox-hover-darken);
			background-color: darken($switchbox-bg, $switchbox-hover-darken);
			.mark {
				padding: $switchbox-mark-hover-padding;
				border-color: darken($switchbox-mark-border-color, $switchbox-hover-darken);
				color: darken($switchbox-mark-color, $switchbox-hover-darken);
			}
		}
		&:checked + label:hover,
		&:checked:focus + label {
			border-color: darken($switchbox-chkd-border-color, $switchbox-hover-darken);
			background-color: darken($switchbox-chkd-bg, $switchbox-hover-darken);
			.mark {
				padding: $switchbox-mark-hover-padding;
				border-color: darken($switchbox-chkd-mark-border-color, $switchbox-hover-darken);
				color: darken($switchbox-chkd-mark-color, $switchbox-hover-darken);
			}
		}

		&:disabled,
		&[aria-disabled='true'],
		&.is-disabled {
			& + label {
				border-color: $switchbox-disabled-border-color;
				background-color: $switchbox-disabled-bg;
				cursor: not-allowed; user-select: none; pointer-events: none;
				.mark {
					border-color: $switchbox-disabled-mark-border-color;
					background-color: $switchbox-disabled-mark-bg;
					color: $switchbox-disabled-mark-color;
				}
			}
		}
	}
}

.checkbox,
.radio {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	label {
		position: relative;
		display: block; width: 1em; height: 1em; margin: 0;
		border: $input-border-width solid $checkbox-border-color;
		background-color: $checkbox-bg;
		cursor: pointer;
		transition: $default-duration $default-timing-function;
		.mark {
			display: block; box-sizing: border-box;
			opacity: 0; pointer-events: none;
			transition: opacity $default-duration $default-timing-function;
		}
	}
	& + label {
		vertical-align: middle;
	}
}

.checkbox label {
	border-radius: $global-border-radius;
	.mark {
	 	padding: .1em;
	}
}
.radio label {
	border-radius: 50%;
	.mark {
	 	padding: .25em;
	}
}

.checkbox [type='checkbox'],
.radio [type='radio'] {
	@extend %hide-checkbox;
}
.checkbox [type='checkbox'],
.radio [type='radio'] {
	&:checked + label {
		border-color: $checkbox-chkd-border-color;
		background-color: $checkbox-chkd-bg;
		color: $checkbox-chkd-mark-color;
		.mark {
			opacity: 1;
		}
	}
}
.checkbox [type='checkbox']:not(:checked) + label:hover,
.checkbox [type='checkbox']:not(:checked):focus + label,
.radio [type='radio']:not(:checked) + label:hover,
.radio [type='radio']:not(:checked):focus + label {
	border-color: darken($checkbox-border-color, $checkbox-hover-darken);
	background-color: darken($checkbox-bg, $checkbox-hover-darken);
}
.checkbox [type='checkbox']:checked + label:hover,
.checkbox [type='checkbox']:checked:focus + label,
.radio [type='radio']:checked + label:hover,
.radio [type='radio']:checked:focus + label {
	border-color: darken($checkbox-chkd-border-color, $checkbox-hover-darken);
	background-color: darken($checkbox-chkd-bg,$checkbox-hover-darken);
}
.checkbox [type='checkbox'],
.radio [type='radio'] {
	&:disabled,
	&[aria-disabled='true'],
	&.is-disabled {
		& + label {
			border-color: $checkbox-disabled-border-color;
			background-color: $checkbox-disabled-bg;
			color: $checkbox-chkd-mark-color;
			cursor: not-allowed; user-select: none; pointer-events: none;
		}
	}
}

fieldset,
.fieldset-like {
	margin: $global-gutters 0 $global-gutters * 4;
}

legend, .legend {
	display: flex; margin-bottom: $global-gutters;
	flex-flow: row nowrap; align-items: center;
	font-style: italic;

	&::after {
		@extend %hr;
		margin-left: 1em;
		flex: 1 1 auto;
		content: '';
	}
}

.form-group,
.form-group--center,
.form-group--nowrap {
	display: flex; margin-left: -$input-hmargin; margin-right: -$input-hmargin;
	flex-flow: row wrap; align-items: flex-end;
	& > * {
		margin-left: $input-hmargin; margin-right: $input-hmargin;
		&:first-child {
			margin-left: 0;
		}
		&:last-child {
			margin-right: 0;
		}
	}
	.form-group-item-full-width {
		flex: 1 1 auto;
	}
}
.form-group--center {
	justify-content: center;
}
.form-group--nowrap {
	flex-wrap: nowrap;
}
