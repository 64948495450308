// Calculate rem font size in a responsive context
@mixin rwd-rem($size: $default-font-size) {
	font-size: map-get($media-ratio, 'xsmall') * $size;

	@each $breakpoint, $value in $breakpoints {
		@include media(">#{$breakpoint}") {
			font-size: map-get($media-ratio, $breakpoint) * $size;
		}
	}
}
