html,
body {
	@include media('>small') {
		height: 100%;
	}
}

html.is-full-page {
	&,
	body {
		@include media('<=small') {
			height: 100%;
		}
	}
}

.logged-in {

	.root-container {
		
		overflow: hidden;

		@include media('>small') {
			display: flex; height: 100%;
			flex-flow: row nowrap; align-items: stretch;
		}
	}

	.nav-container,
	.page-container {
		transition: $nav-toggle-duration ceaser(easeOutCubic);
	}

	.nav-container {

		@include media('<=small') {
			position: fixed; top: 0; bottom: 0; left: 0; overflow: auto; z-index: $zindex-nav;
			width: $nav-min-width;
			transform: translateX(-100%);
			transition: transform $nav-toggle-duration ceaser(easeOutCubic);

			&.is-active {
				transform: translateX(0);
			}
		}

		@include media('>small') {
			height: 100%; // Shame on Safari >_<v
			min-width: $nav-min-width;
			flex: 0 0 $nav-width;
		}

		@mixin app-nav-narrow {
			min-width: 0;
			flex-basis: $nav-narrow-width;
		}

		@include media('>medium') {
			&.is-narrow {
				@include app-nav-narrow;
			}
		}

		@include media ('>small', '<=medium') {
			&:not(.is-narrow) {
				@include app-nav-narrow;
			}
		}
	}

	.header-mobile-container {

		@include media('<=small') {
			position: fixed; top: 0; right: 0; left: 0; z-index: $zindex-mobile-header;
		}

		@include media('>small') {
			display: none;
		}
	}

	.page-container {
		position: relative;
		overflow: auto;

		@include media('<=small') {
			margin-top: $nav-trigger-height + ($nav-trigger-padding * 2);
		}

		@include media('>small') {
			height: 100%; // Shame on Safari >_<v
			flex: 1 1 auto;
		}
	}

	.main-container {
		display: flex;
		flex-flow: column nowrap; align-items: stretch;

		@include media('>small') {
			min-height: 98vh;
		}
	}

	.fragment-container {
		display: flex;
		flex: 1 0 100%; flex-flow: column nowrap; align-items: stretch;
		//overflow: auto;
	}
}

.logged-out {
	// Default background while waiting for Angular
	// background: $default-bg center / 15% no-repeat url("data:image/svg+xml;charset=UTF-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 29.3' fill='rgb(244,246,247)'><path d='M33.4,7.4c-1,0.8-1.8,1.5-2.7,2.3c-1.3,0.1-2.8,0.3-4.2,0.7c-1.5,0.3-4,1.1-4,1.1l-5.4,3.2l0.2,0.3l0.2,0.3l0.4-0.1c0,0,0.8-0.3,2.7,0.3c1,0.3,1.9,1.2,2.4,1.5c-0.4,0.4-1.5,2.6-1.5,2.6l-0.3,0.2l0.2,0.3l0.2,0.3l0.3-0.2c0,0,2.4-0.7,2.9-1c0.2,0.6,1,1.6,1,2.7c0.2,2-0.3,2.7-0.3,2.7l-0.2,0.3l0.2,0.3l0.2,0.3l4.2-4.7c0,0,1.2-2.2,1.9-3.7c0.6-1.3,1.1-2.8,1.5-4c0.9-0.7,1.8-1.4,2.7-2.2C43.6,4.8,46,0,46,0C46,0,40.7,1.4,33.4,7.4z'/><path d='M11.8,5.8c-3.1,0-6,1.1-8.3,3.3c-2.2,2.2-3.5,5-3.5,8.1c0,3.1,1.1,6,3.3,8.2c2.2,2.2,5,3.5,8.1,3.5c2,0,3.7-0.8,5.2-1.9l1.5,1.8l2.6-8.1l-8.4,1l1.7,2c-0.8,0.6-1.6,0.9-2.5,0.8c-1.9,0-3.8-0.8-5.1-2.2C5,21,4.3,19.2,4.3,17.3c0-1.9,0.8-3.8,2.2-5.1c1.4-1.4,3.2-2.1,5.2-2c1.9,0,3.8,0.8,5.1,2.2l0.5,0.6l3.8-2.2h0l-1.2-1.3C17.8,7.2,14.9,5.8,11.8,5.8z'/><path d='M46.7,9.7c-1.1-1.2-2.5-2.1-4-2.7c0,0,0,0,0,0c-0.6,0.8-2.5,2.7-3.4,3.5l0,0c1.6,0.2,3.2,0.9,4.3,2.2c2.8,2.9,2.7,7.5-0.2,10.3c-1.4,1.4-3.2,2.1-5.2,2c-1.9,0-3.8-0.8-5.1-2.2l-1.7-1.7l0,0c0,0-0.8,1.4-1.2,1.9c-0.4,0.5-1.4,1.6-1.4,1.6l1.2,1.2c2.2,2.2,5,3.5,8.1,3.5c3.1,0,6-1.1,8.2-3.3C51.1,21.6,51.2,14.3,46.7,9.7z'/></svg>");

	.main-container {
		min-height: 90vh;
	}

	.nav-container-inner {
		max-width:120rem
	}
}

.is-light-template {

	.root-container {
		display: flex; height: 100%; overflow: hidden;
		flex-flow: row nowrap; align-items: stretch;
	}

	.page-container {
		position: relative;
		height: 100%; margin-top: 0; overflow: auto;
		flex: 1 1 auto;
	}

	.main-container {
		display: flex; min-height: 100%;
		flex-flow: column nowrap; align-items: stretch;
	}

	.fragment-container {
		display: flex;
		flex: 1 0 100%; flex-flow: column nowrap; align-items: stretch;
	}
}

.is-funnel-template {

	.root-container,
	.page-container,
	.main-container,
	.fragment-container {
		display: block; height: auto; overflow: initial;
	}

}
