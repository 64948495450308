%grid {
	--grid-item-min-width: 1fr;

	display: grid;
	grid-template-columns: repeat( auto-fill, minmax( var(--grid-item-min-width), 1fr ) );
	grid-auto-flow: row dense; justify-items: stretch; align-items: stretch;

	& > * {
		min-width: 0;
	}
}

// This mixin allow you to create a grid anywhere with the same basis as main grid system
@mixin grid($from, $to, $width, $scope: '', $gutter: $global-gutters, $classname: 'grid') {
	@for $i from $from through $to {
		$a: ($width / $i) - $gutter;

		#{$scope} .#{$classname}-#{$i} {
			@extend %grid;
			grid-gap: $gutter $gutter;

			@include media('>#{$a}') {
				--grid-item-min-width: #{$a};
			}

			@include media('>#{($a * 2) - $gutter}') {
				.#{$classname}-item--span2 {
					grid-column: auto / span 2;
				}
			}
		}
	}
}

@include grid(1, 4, $wrapper-full-width, '.wrapper-full');
@include grid(1, 4, $wrapper-width, '.wrapper');

.grid-item--full {
	grid-column: 1 / -1;
}
