.flash-message {
	position: fixed; top: $global-gutters; right: $global-gutters * 2; z-index: $zindex-flash;
	max-width: 25em; padding: $global-gutters;
	transform: translateX(5em);
	border: 1px solid $softgrey; border-radius: $global-border-radius; box-shadow: 0 .125em .25em $dropdown-box-shadow;
	background-color: $softwhite;
	color: $regular-color;
	opacity: 0; visibility: hidden; pointer-events: none;
	transition: .25s ceaser(easeOutQuart), border-color 0s, background-color 0s, color 0s;

	&.is-shown {
		transform: translateX(0);
		opacity: 1; visibility: visible; pointer-events: auto;
		transition-duration: .5s, 0s, 0s, 0s;
	}

	&.is-error {
		border-color: lighten($alert-color, 30%);
		background-color: lighten($alert-color, 35%);
		color: darken($alert-color, 5%);
	}

	&.is-warning {
		border-color: lighten($warning-color, 30%);
		background-color: lighten($warning-color, 35%);
		color: darken($warning-color, 5%);
	}

	&.is-info {
		border-color: lighten($info-color, 30%);
		background-color: lighten($info-color, 40%);
		color: darken($info-color, 5%);
	}

	&.is-success {
		border-color: lighten($valid-color, 30%);
		background-color: lighten($valid-color, 35%);
		color: darken($valid-color, 10%);
	}

	a,
	button {
		color: inherit;
	}

	&-content {
		display: flex;
		flex-flow: row nowrap; justify-content: start; align-items: center;
		font-weight: $font-weight-bold;
	}

	&-icon {
		margin-right: $global-gutters;

		svg {
			display: block;
			font-size: 2.5em;
		}
	}

	&-close-button {
		position: absolute; top: 0; right: 0;
		display: block; width: 1.5em; height: 1.5em; padding: .25em;
		line-height: 1;
		opacity: .25;

		&:hover,
		&:focus {
			opacity: 1;
		}
	}
}
