.custom-devices-list {
	tbody {
		tr {
			&,
			&:nth-child(even) {
				background-color: $list-table-tr-bg;
			}
			&:nth-child(even) {
				border-top: 1px solid $list-table-tr-border-color;
			}
		}
		&:nth-of-type(even) tr {
			background-color: $list-table-tr-bg-alternate;
		}
		&:hover tr {
			background-color: $list-table-tr-bg-hover;
		}
		&.is-editing tr {
			background-color: $list-table-tr-bg-editing;
		}
	}
}
