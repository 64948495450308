.logged-out {

	.nav-container {
		padding: $global-gutters 0;
		border-bottom: 1px solid $softgrey;
		background-color: $softwhite;

		&-inner {
			display: flex;
			flex-flow: row nowrap; justify-content: space-between; align-content: center;
		}
	}

	.front-logo {
		display: inline-block;

		&-icon {
			width: 18em; height: auto;
			@include media(">large") {
				width: 28em;
			}
		}
	}

	.front-nav {
		@extend %ul-reset;

		@include media("<=medium") {
			position: fixed; top: 0; left: 0; bottom: 0; overflow: auto; z-index: $zindex-nav;
			padding: $global-gutters;
			transform: translateX(-100%);
			border-right: 1px solid $softgrey;
			background-color: $softwhite;
			font-size: $nav-item-font-size;
			transition: transform $nav-toggle-duration ceaser(easeOutCubic);
		}

		@include media(">medium") {
			display: flex;
			flex-flow: row nowrap; justify-content: flex-start; align-items: center;
		}

		&.is-active {
			transform: translateX(0);
		}

		& > * {
			margin: 1em 0;
			@include media(">medium") {
				margin: 0 .125em;
			}
			@include media(">large") {
				margin: 0 .25em;
			}
			@include media(">xlarge") {
				margin: 0 .375em;
			}
		}

		&-logo {
			margin-top: 0;

			.front-logo {
				display: block;

				&-icon {
					width: 12em;
				}
			}
		}

		&-item {
			display: block;
			font-weight: $font-weight-bold; color: inherit; text-decoration: none;

			@include media(">medium") {
				display: inline-block; padding: .25em;
			}

			&:hover,
			&:focus {
				text-decoration: underline;
			}

			&.dropdown-toggle {

				&:hover,
				&:focus {
					text-decoration: none;
				}
			}
		}

		.nav-log-btn {
			display: block; width: 100%;
		}

		&-trigger {
			font-size: 1.5em; font-weight: $font-weight-bold;

			&:hover,
			&:focus {
				color: $focus-color;
			}

			&::before {
				position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: $zindex-nav-trigger;
				background-color: rgba($darkblue, .75);
				content: ''; visibility: hidden; opacity: 0; pointer-events: none;
				transition: $nav-toggle-duration ceaser(easeOutCubic);
			}

			&.is-active::before {
				visibility: visible; opacity: 1; pointer-events: auto;
			}

			&-icon {
				vertical-align: middle;
			}
		}

	}
}
