.footer {

	ul {
		@extend %ul-reset;

		li {
			margin: $footer-list-item-margin;
		}
	}

	h3,
	a,
	.btn--link-like {
		color: inherit;
	}

	&-first {
		padding: $global-gutters * 2 0;
		background-color: $footer-first-bg;
		color: $footer-first-color;

		a,
		.btn--link-like {
			text-decoration: none;

			&:hover,
			&:focus {
				text-decoration: underline;
			}
		}
	}

	&-second {
		padding: $global-gutters 0;
		background-color: $footer-second-bg;
		color: $footer-second-color;

		p {
			margin: 0;
		}
	}
}

.social-links {
	display: flex; max-width: 10em;
	flex-flow: row nowrap; justify-content: space-between; align-items: center;
	font-size: 1.5em;

	a {
		&:hover,
		&:focus {
			color: $white;
		}
	}
}
