.scenario-report-summary {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: $global-gutters $global-gutters; justify-items: stretch; align-items: stretch;

	@include media('>xlarge') {
		grid-template-columns: 1fr 2fr;
	}

	@include media('>xxlarge') {
		grid-template-columns: 1fr 3fr;
	}

	&-grid {
		@extend %grid;
		--grid-item-min-width: 18em;
	}
}

#scenario-video-item {
	position: relative;

	#scenario-video-transition-title {
		position: absolute; top: 0; right: 0; bottom: 0; left: 0;
		padding: 1em;
		background-color: rgba(0, 0, 0, .75);
		transition: opacity .25s ease-out;

		&.is-hidden {
			opacity: 0;
		}

		p {
			color: white; font-size: 1.25em; font-family: Arial, Helvetica, sans-serif;
		}
	}

	h3 {
		margin-bottom: 20px;
	}
}


