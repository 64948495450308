tags-input.recipients-select {

	.tags {
		border-radius: .5rem;
		-webkit-box-shadow: 0 0 2px rgba(171,180,190,.75);
		box-shadow: 0 0 2px rgba(171,180,190,.75);
		margin: .25em 0;
		padding: .25em;

		.tag-item, .tag-item.selected {
			background: none;
			height: 34px;
			padding: 4px 5px;


			ti-tag-item {
				margin: .25em;

				.remove-button {
					padding-left: .5em;
					text-decoration: none;
				}

				.remove-button:hover {
					color: #12A6C6;
				}
			}
		}
		input {
			height: 34px; 
			transition: none;
		}
	}

	.tags:focus {
		border-color: #12A6C6;
		-webkit-box-shadow: 0 0 2px rgba(18,166,198,.75);
		box-shadow: 0 0 2px rgba(18,166,198,.75);
	}

	
	.autocomplete .suggestion-item .recipient-name {
		margin-left: 20px;

		em {
			color: inherit;
		}
	}

}
