%button {
	display: inline-block; margin: $input-vmargin $input-hmargin; padding: $input-vpadding $btn-hpadding; box-sizing: border-box;
	border: $input-border-width solid transparent; box-shadow: 0 0 $input-shadow-width transparent; outline: none; border-radius: $global-border-radius;
	background: transparent;
	font: $button-font-style $button-font-weight 1em/#{$default-line-height} $button-font-family; text-align: center; text-decoration: none;
	vertical-align: baseline;

	&:disabled,
	&[aria-disabled='true'],
	&.is-disabled {
		cursor: not-allowed; user-select: none; pointer-events: none;
	}

	&:first-letter {
		text-transform: uppercase;
	}

	&:first-child {
		margin-left: 0;
	}

	&:last-child {
		margin-right: 0;
	}
}

%button--small {
	margin: 0; padding: 0 $input-hpadding / 2;
}

%button--big {
	margin: 0; padding: $input-vpadding * 2 $btn-hpadding * 2;
}

@mixin colorize-button($border, $background, $color) {
	@if($border != none) {
		border-color: $border;
		@if($border != transparent) {
			box-shadow: 0 0 $input-shadow-width rgba($border, $input-shadow-opacity);
		}
	}
	@if($background != none) {
		background-color: $background;
	}
	@if($color != none) {
		color: $color;
	}
}

@mixin disabled-button($border, $background, $color) {
	&:disabled,
	&[aria-disabled='true'],
	&.is-disabled {
		&,
		&:hover,
		&:focus {
			@include colorize-button($border, $background, $color);
		}
	}
}

@each $class, $color, $text in $variants-list {

	.btn--#{$class}--primary,
	.btn--#{$class}--primary--small,
	.btn--#{$class}--primary--big {
		@extend %button;
		@include colorize-button(darken($color, $btn-hover-ratio), $color, $text);

		&:hover,
		&:focus {
			z-index: 1;
			@include colorize-button(none, darken($color, $btn-hover-ratio), none);
		}
		&:active {
			@include colorize-button(none, darken($color, $btn-hover-ratio*2), none);
		}

		@include disabled-button($btn-disabled-color, $btn-disabled-bg, $btn-disabled-color);
	}

	.btn--#{$class},
	.btn--#{$class}--small,
	.btn--#{$class}--big {
		@extend %button;
		@include colorize-button($color, $white, $color);

		&:hover,
		&:focus,
		&:active {
			z-index: 1;
			@include colorize-button(darken($color, $btn-hover-ratio), none, $text);
		}
		&:hover,
		&:focus {
			@include colorize-button(none, $color, none);
		}
		&:active {
			@include colorize-button(none, darken($color, $btn-hover-ratio), none);
		}

		@include disabled-button($btn-disabled-color, $btn-disabled-bg, $btn-disabled-color);
	}

	.btn--#{$class}--ghost,
	.btn--#{$class}--ghost--small,
	.btn--#{$class}--ghost--big {
		@extend %button;
		@include colorize-button(transparent, transparent, $color);

		&:hover,
		&:focus {
			z-index: 1;
			@include colorize-button(darken($color, $btn-hover-ratio), $color, $text);
		}
		&:active {
			@include colorize-button(darken($color, $btn-hover-ratio), darken($color, $btn-hover-ratio), $text);
		}

		@include disabled-button(transparent, transparent, $btn-disabled-color);
	}

	.btn--#{$class}--small,
	.btn--#{$class}--primary--small,
	.btn--#{$class}--ghost--small {
		@extend %button--small;
	}

	.btn--#{$class}--big,
	.btn--#{$class}--primary--big,
	.btn--#{$class}--ghost--big {
		@extend %button--big;
	}
}

.is-full-width {
	width: 100%;
}

.with-loader {
	position: relative;

	&-text,
	.loading-spinner {
		transition: inherit; transition-property: opacity;
	}

	.loading-spinner {
		position: absolute; top: 50%; left: 50%;
		transform: translate(-50%, -50%);
		font-size: 3em;
		opacity: 0;
	}

	&.is-loading {
		.with-loader-text {
			opacity: 0;
		}
		.loading-spinner {
			opacity: 1;
		}
	}
}

.btn--reset,
.btn--link-like,
.btn--delete,
.btn--favorite {
	display: inline; margin: 0; padding: 0;
	border: none; outline: none;
	background: none;
	font: inherit; text-align: inherit;
	vertical-align: baseline; appearance: none;

	&[disabled],
	&[aria-disabled='true'],
	&.is-disabled {
		color: $btn-disabled-color;
		cursor: not-allowed; user-select: none; pointer-events: none;
	}
}

.btn--delete {
	color: $inactive-color;
	&:hover,
	&:focus {
		color: $alert-color;
	}
	&[disabled],
	&[aria-disabled='true'],
	&.is-disabled {
		color: $inactive-color;
	}
}

.btn--favorite {
	color: $inactive-color;
	vertical-align: text-bottom;
	&:hover,
	&:focus {
		.full {
			opacity: 1;
		}
		&[disabled],
		&[aria-disabled='true'],
		&.is-disabled {
			.full {
				opacity: 0;
			}
		}
	}
	.full {
		opacity: 0;
	}
	&.is-active {
		color: $favorite-color;
		&:hover,
		&:focus {
			.full {
				opacity: 0;
			}
			&[disabled],
			&[aria-disabled='true'],
			&.is-disabled {
				.full {
					opacity: 1;
				}
			}
		}
		.full {
			opacity: 1;
		}
	}
	svg {
		display: block;
		transition: opacity $default-duration $default-timing-function;
	}
}

.btn--sortable-col {
	@extend .btn--reset, .text-uppercase-first-letter;
	display: block; width: 100%;
	color: $focus-color;

	&:hover,
	&:focus {
		text-decoration: underline;
	}
}

[class*=' group-btn'],
[class^='group-btn'] {
	display: inline-flex;
	flex-flow: row wrap; align-items: center;
	&[class*='--nowrap'] {
		flex-wrap: nowrap;
	}
	[class*='btn'] {
		margin: 0;
		border-radius: 0;
	}
	& > [class*='btn']:not(:first-of-type),
	& > *:not([class*='btn']):not(:first-of-type) [class*='btn'] {
		border-left-width: $input-border-width / 2;
	}
	& > [class*='btn']:not(:last-of-type),
	& > *:not([class*='btn']):not(:last-of-type) [class*='btn'] {
		border-right-width: $input-border-width / 2;
	}
	& > [class*='btn']:first-of-type,
	& > *:not([class*='btn']):first-of-type [class*='btn'] {
		border-top-left-radius: $global-border-radius;
		border-bottom-left-radius: $global-border-radius;
	}
	& > [class*='btn']:last-of-type,
	& > *:not([class*='btn']):last-of-type [class*='btn'] {
		border-top-right-radius: $global-border-radius;
		border-bottom-right-radius: $global-border-radius;
	}
}
