.tabs {
	display: flex; margin: 0; padding-top: .25em; overflow: hidden;
	flex-flow: row wrap; justify-content: flex-start; align-items: flex-end;
	background-color: $tabs-bg;
	list-style: none;
	&:not([class*='wrapper']) {
		padding-left: .25em; padding-right: .25em;
	}
	&-item {
		@extend .btn--reset;
		position: relative;
		padding: .75em 1em;
		border-bottom: .15em solid $tabs-border-color; outline: none;
		color: inherit; text-decoration: none;
		cursor: pointer;
		&:not(.is-active) {
			color: $tabs-inactive-color;
			.text-focus {
				color: inherit;
			}
		}
		&-count-badge {
			position: absolute; top: 0; right: 0;
			padding: .25em .5em;
			border-radius: 1em;
			background-color: $tabs-badge-bg;
			font-size: .625em; line-height: 1; font-weight: bold; color:$tabs-badge-color;
		}
		&.is-active {
			border-bottom-color: $tabs-active-border-color; box-shadow: 0 0 .25em $tabs-border-color;
			background-color: $tabs-active-bg;
		}
		&.is-disabled {
			color: $tabs-disabled-color;
			pointer-events: none; cursor: not-allowed;
			.tabs-item-count-badge {
				background-color: $tabs-disabled-color;
			}
		}
		&:hover,
		&:focus {
			border-bottom-color: $tabs-hover-border-color;
			color: $tabs-hover-color;
		}
		svg {
			color: inherit;
		}
		&.is-alert {
			color: $alert-color;
			.tabs-item-count-badge {
				background-color: $alert-color;
			}
		}
	}

	&-target-frame {
		margin: $global-gutters 0;
	}
}

.title-tabbed {
	padding: 0;
	font-weight: $default-font-weight; color: $default-color;
}
