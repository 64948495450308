/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
	text-rendering: optimizeLegibility;
	text-size-adjust: 100%;
	font-kerning: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	background: $default-bg;
}

::-moz-selection {
	background: $selection-bg;
	color: $selection-color; text-shadow: none;
}

::selection {
	background: $selection-bg;
	color: $selection-color; text-shadow: none;
}

hr {
	height: 0; margin: $default-line-height * 1em 0; padding: 0;
	border: 0;
	@extend %hr;
}

h1 {
	@extend %hr;
	margin: 0; padding: 1em 0;
	background: $colored-frame-bg;
	&.h-reset {
		border: none;
		background: none;
	}
}

.logged-out {
	h1 {
		background: none;
	}
}

audio, canvas, iframe, img, video {
	vertical-align: middle;
}

fieldset {
	margin: 0; padding: 0;
	border: 0;
}

a, button, input, select {
	transition: $default-duration $default-timing-function, margin 0s;
}

input[type="search"]::-webkit-search-cancel-button {
	display: none;
}

textarea {
	resize: vertical;
	transition: $default-duration $default-timing-function, height 0s;
}

button {
	color: inherit;
	cursor: pointer;
}

abbr[title] {
	text-decoration: none;
}

figure {
	margin: 0;
}

img {
	/* width: auto; */ max-width: 100%; height: auto;
}

svg {
	display: inline-block; width: 1em; height: 1em;
	fill: currentColor;
	vertical-align: baseline;
}

a svg,
button svg {
	text-decoration: none;
	pointer-events: none;
}

code,
pre {
	border-radius: $global-border-radius / 2;
	background-color: $code-bg;
}

code {
	padding: 0 .25em;
	color: $alert-color;
}

pre {
	padding: 1em; margin: $default-line-height * 1em 0; overflow: auto;
	line-height: inherit; white-space: pre;
}

kbd {
	display: inline-block; padding: 0 .5em;
	border: solid 1px #ccc; border-top-color: #ddd; border-bottom-color: #ddd; border-radius: .25em; box-shadow: 0 1px 0 #bbb, 0 2px .125em #eee;
	background: linear-gradient(#f0f0f0, #fafafa);
	font-family: monospace, monospace; text-shadow: 0 1px 0 white;
}
