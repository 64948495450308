.dashboard-events {
	&-item {
		&-title {
			margin-bottom: 0;
		}
		&-date {
			font-style: italic;
		}
	}
}

.dashboard-title {
	@extend h1;
	margin-top: $global-gutters;
	background: $dashboard-title-bg;
	font-weight: $font-weight-normal;

	&:not(.title-tabbed) {
		display: flex; padding: $global-gutters;
		flex-flow: row wrap; justify-content: space-between; align-items: center;

		@include media('>small') {
			flex-wrap: nowrap;
		}

		@include media('<=small') {
			& > .is-pushed-right {
				margin-top: 1em;
			}
		}
	}

	.tabs {
		padding-left: 0; padding-right: 0;
	}
}

.dashboard-analysis-form {
	padding: .1px 0;

	.analysis-form-main-settings {
		margin: $global-gutters*2 0 $global-gutters;
	}
}

.dashboard-monitoring-stats {
	display: flex; margin: -1.4em 0;
	flex-flow: row wrap; justify-content: space-around; align-items: flex-end;
	text-align: center;

	&-item {
		margin: 1.5em;
		flex: 0 0 13em;
	}
}
