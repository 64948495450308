@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	src: local('Open Sans'), local('OpenSans'),
	     url('/assets/fonts/opensans/open-sans-v13-latin-regular.woff2') format('woff2'),
	     url('/assets/fonts/opensans/open-sans-v13-latin-regular.woff') format('woff');
}

@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 600;
	src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
	     url('/assets/fonts/opensans/open-sans-v13-latin-600.woff2') format('woff2'),
	     url('/assets/fonts/opensans/open-sans-v13-latin-600.woff') format('woff');
}
/*
@font-face {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 700;
	src: local('Open Sans Bold'), local('OpenSans-Bold'),
	     url('/assets/fonts/opensans/open-sans-v13-latin-700.woff2') format('woff2'),
	     url('/assets/fonts/opensans/open-sans-v13-latin-700.woff') format('woff');
}
*/
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Condensed Light'), local('RobotoCondensed-Light'),
       url('/assets/fonts/roboto/robotocondensed-light-webfont.woff2') format('woff2'),
       url('/assets/fonts/roboto/robotocondensed-light-webfont.woff') format('woff');
}
/*
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto Condensed'), local('RobotoCondensed-Regular'),
       url('/assets/fonts/roboto/robotocondensed-regular-webfont.woff2') format('woff2'),
       url('/assets/fonts/roboto/robotocondensed-regular-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: local('Roboto Condensed Bold'), local('RobotoCondensed-Bold'),
       url('/assets/fonts/roboto/robotocondensed-bold-webfont.woff2') format('woff2'),
       url('/assets/fonts/roboto/robotocondensed-bold-webfont.woff') format('woff');
}
*/
