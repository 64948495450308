.graph-legend {
	@extend %ul-reset;
	display: flex;
	flex-flow: row wrap; justify-content: left; align-item: center;

	&.is-centered {
		justify-content: center;
	}

	&-item {
		max-width: 20em; margin: .25em;
		flex: 0 1 auto;
		@include media('>20em') {
			margin: .25em 1em;
		}
	}

	&-color {
		display: inline-block; width: .75em; height: .75em;
		background-color: currentColor;
	}
}
