.tos {
	section {
		@extend .fieldset-like;
	}

	dt {
		margin-top: 1em;
		font-weight: $font-weight-bold;
	}

	abbr {
		text-decoration: underline dotted;
	}
}
