.external-tooltip {
	position: absolute; z-index: 1;
	max-width: 30em; padding: $global-gutters;
	border-radius: $global-border-radius;
	background-color: rgba($darkblue, .9);
	color: $white;
	opacity: 0; visibility: hidden; pointer-events: none;

	&.visible {
		opacity: 1; visibility: visible;
		transition: $default-duration $default-timing-function;
	}

	.legendColor {
		display: inline-block; width: 1em; height: 1em; margin-right: .25em;
		border: 1px solid $softwhite; border-radius: $global-border-radius;
	}
}
