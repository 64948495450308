@include grid(1, 3, $wrapper-width, '', $global-gutters * 2, 'payment-funnel-grid');

.payment-funnel {
	min-height: 82vh;

	&-stepper {
		@extend %ul-reset;
		display: flex; margin: #{$global-gutters} #{$global-gutters * -0.5} #{$global-gutters*3};
		flex-flow: row nowrap; justify-content: center; align-items: stretch;

		& > li {
			position: relative;
			margin: 0 $global-gutters / 2; padding: .5em;
			flex: 1 0 auto;
			background: $darkblue;
			color: $white; text-align: center;

			&::before,
			&::after {
				position: absolute; top: 50%;
				display: block; width: 0; height: 0;
				border: .8125em solid transparent;
			}

			&:not(:last-child)::after {
				right: 0; z-index: 0;
				transform: translate(49%, -50%) rotate(45deg);
				border-top-color: $darkblue; border-right-color: $darkblue;
				content: '';
			}

			&:not(:first-child)::before {
				left: 0; z-index: 2;
				transform: translate(-51%, -50%) rotate(45deg);
				border-top-color: $white; border-right-color: $white;
				content: '';
			}

			&.is-current {
				background: $blue;
				font-weight: $font-weight-bold;

				&::after {
					border-top-color: $blue; border-right-color: $blue;
				}

				& ~ * {
					background: $softwhite;
					color: $default-color;

					&::after {
						border-top-color: $softwhite; border-right-color: $softwhite;
					}
				}
			}

			& > * {
				position: relative; z-index: 1;
			}

			button {
				display: block; width: 100%;
				color: inherit;
			}
		}
	}

	&-summary {
		padding: .01em 0;
		text-align: center;
	}

	&-choice {
		padding: 1em;
		border-radius: $global-border-radius;
		background-color: $softwhite;
	}

	&-offer {
		&.w-margin {
			margin-bottom: 1.5em;
		}
	}

	&-reinsurance {
		display: flex;
		flex-flow: row nowrap; justify-content: center; align-items: center;

		& > *:first-child {
			margin-right: .5em;
		}

		&.marg-v {
			margin: 3em 0;
		}

		&-desktop {
			@include media('<=656px') {
				display: none;
			}
		}

		&-mobile {
			@include media('>656px') {
				display: none;
			}
		}

		&-container {
			margin: 6em 0 4em;
		}
	}

	&-form {
		@include media('>976px') {
			grid-column: auto / span 2;
		}

		.form-grid {
			display: grid;
			grid-template-columns: repeat( auto-fill, minmax( 20rem, 1fr ) );
			grid-gap: $global-gutters $global-gutters; grid-auto-flow: row dense; justify-items: stretch; align-items: stretch;

			& > * {
				min-width: 0;
			}
		}
	}

	&-callback {
		margin-top: 5%; margin-bottom: 5%;
		text-align: center;

		&-icon {
			margin: .125em 0;
			font-size: 12em;
		}

		&-title {
			font-size: 2em; font-weight: $font-weight-bold;
		}

		&-subtitle {
			font-size: 1.5em;
		}

		&-resume {
			max-width: 30em; margin-left: auto; margin-right: auto; padding: .01em $global-gutters $global-gutters;
			border: 1px solid $hr-color; border-radius: $global-border-radius;
		}

		ul {
			@extend %ul-reset;
		}

		.marg-v {
			margin-top: 2em; margin-bottom: 2em;
		}
	}
}

