.dropdownNEW {
	position: relative;
	display: inline-block;

	&-toggle {
		overflow: hidden;
	}

	&-text {

	}

	&-caret {
		transition: transform $default-duration * 2 $caret-timing-function;
	}

	&-menu {
		@extend %ul-reset;
		@include rwd-rem();
		position: absolute; top: 100%; z-index: $zindex-dropdown;
		display: block; width: max-content; margin: 0 -1px; overflow: hidden;
		transform: translateY(-.5em);
		border: 1px solid $dropdown-border-color; box-shadow: 0 .125em .25em $dropdown-box-shadow; border-radius: $global-border-radius;
		background-color: $dropdown-bg;
		line-height: $default-line-height; font-weight: $font-weight-normal; text-align: left;
		opacity: 0; visibility: hidden;
		transition: $default-duration $default-timing-function;

		&.is-sticky-left {
			left: 0;
		}

		&.is-sticky-right {
			right: 0;
		}

		&.is-sticky-both {
			right: 0; left: 0;
			width: 100%; box-sizing: border-box;
		}

		&.is-sized {
			max-height: 30em; overflow-y: auto;
		}

		&-header {
			display: block; max-width: 15em; padding: .5em;
			background-color: $dropdown-header-bg;
			text-align: center; color: $dropdown-header-color;
		}

		&-row {
			@extend %hr;
			display: flex;
			flex-flow: row nowrap; justify-content: center; align-items: center;
		}

		&-title {
			display: block; padding: .75em .5em .25em;
			border-bottom: 1px solid $dropdown-border-color;
			font-size: .875em; font-weight: $font-weight-bold;
		}

		&-item {
			@extend .btn--reset;
			display: flex; width: 100%; box-sizing: border-box;
			flex: 1 1 auto;
			flex-flow: row nowrap; justify-content: flex-start; align-items: center;
			color: inherit; text-decoration: none; white-space: nowrap;

			&.is-selected {
				background: $dropdown-item-selected-bg;
			}

			&:not([disabled]):not([aria-disabled='true']):not(.is-disabled) {
				&:hover,
				&:focus {
					background: $dropdown-item-hover-bg;
					color: $dropdown-item-hover-color;
				}
			}

			&-icon {
				display: flex; width: 2.5em; height: 2.5em;
				flex: 0 0 2.5em;
				background-color: rgba(darken($dropdown-item-hover-bg, 10%), .5);

				& > * {
					margin: auto;
				}
			}

			&-text {
				padding: .25em .5em;
			}
		}

		&-hr {
			@extend %hr;
			border-bottom-color: rgba(darken($dropdown-item-hover-bg, 10%), .5);
		}


	}
	
	&-sub {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	&.is-active {
		.dropdownNEW-caret {
			transform: rotate(180deg);
		}
		.dropdownNEW-menu {
			transform: translateY(0);
			opacity: 1; visibility: visible;
		}
	}
}
