.input-alert-value {
	width: 5em;
}

.list-table .is-deleted {
	background-color: lighten($alert-color, 30%);
}

.alert-overflow {
	max-height: 450px;
	overflow-y: scroll;
}
