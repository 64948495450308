.dashboard-reporting-page {
	.frequency-list {
		@extend %ul-reset;
			margin-left: $global-gutters * -1; margin-right: $global-gutters * -1;

		li {
			display: inline-block; margin: .5em $global-gutters;
		}
	}
}
