.monitoring-graph-item {
	display: flex; padding: $global-gutters;
	flex-flow: column nowrap;
	border-radius: $global-border-radius;
	background: $default-bg;
	transition: box-shadow $default-duration $default-timing-function;

	&-title {
		@extend .h-reset;
		& > * {
			vertical-align: middle;
		}
	}

	&-hr {
		margin-left: -$global-gutters; margin-right: -$global-gutters;
	}

	&-container {
		position: relative;
	}

	&-metrics-button-frame {
		padding: $global-gutters 0;
	}
}

[class*=' grid-'],
[class^='grid-'] {
	.monitoring-graph-item {
		height: 100%; box-sizing: border-box;
	}
}
