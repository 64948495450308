.scenario-step-summary {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: $global-gutters $global-gutters; justify-items: stretch; align-items: stretch;

	@include media('>large') {
		grid-template-columns: 2fr 3fr;
	}

	&-evol-item {
		margin: 1em 2%;
		flex: 1 0 29%
	}
}
