[class*=' notice-'],
[class^='notice-'] {
	display: block; padding: .5em;
	font-weight: $font-weight-bold; //text-align: center;
	a:not([class*='btn']),
	.btn--link-like {
		color: inherit;
	}
}

@each $class, $color in $variants-list {
	.notice-#{$class} {
		background-color: rgba($color, .15);
		color: $color;
	}
}
