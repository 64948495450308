.dareboost-sunset-banner {
    background-color: $spinner-looping-bg;
    border-radius: $global-border-radius;
    margin-bottom: 30px;
    padding: 30px;

    p {
        margin: 0;
        margin-bottom: 10px;
    }

    .dareboost-sunset-cta {
        margin-top: 50px;
        text-align: center;

        .button {
            font-weight: bold;
        }
    }
}
