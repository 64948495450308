.funnav-container {
	padding: $global-gutters 0;

	@include media('>430px') {
		.funnav-back {
			margin-top: -2.5em;
		}
	}

	.front-logo {
		display: inline-block;

		&-icon {
			width: 15em; height: auto;
			@include media(">large") {
				width: 18em;
			}
		}
	}

}
