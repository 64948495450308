/*
 These style are meant to overides legacy styles
 Remove when possible
*/

header:not(.unconnectHeader):not(.nav-header):not(.app-header):not(.app-header-mobile) {
	background-color: transparent;
	height: auto;
	padding-bottom: 0;
	border-bottom: none;
}

.app-header {
	background-color: transparent;
	height: auto;
	border-bottom: none;
}

.app-header-mobile {
	height: auto;
	padding-bottom: 0;
	border-bottom: none;
}

body.unconnected:not(.mobile-compatible) {
	min-width: 980px;
}

body:not(.mobile-compatible) .main-container{
	min-width: 960px;
}

h2 {
	color: $title-color;
	font-size: 1.5em;
	padding: 0;
	margin: .85em 0;
}

.monitoring-metrics-popin h2 {
	margin: .85em 0;
	padding: 0;
}

/*
hr.clear {
	margin: 0;
}
*/
.sprite {
	display: inline-block;
}

.appMenu a {
	text-decoration: none;
}

.appMenu img {
	vertical-align: baseline;
}

.unconnectHeader {
	img {
		height: 40px;
		vertical-align: baseline;
	}
	.sprite {
		display: block;
	}
}

.img-w-100 {
	width: 100%;
}

.dropdown.langParent {
	margin-left: 0; margin-top: 0; float: none;

	.sprite {
		display: block;
	}
}

#missionMenu {
	font-size: 14px;
	img {
		height: 40px;
	}
}

#quotasMenu {
	font-size: 14px;
}

.mainContainer {
	margin: auto;
}

.dropdown-menu {
	margin: 0;
	font-size: 14px;
}

.darkBlueButton {
	color: #fff;
	display: inline-block;
	background-color: #34465e;
	border: 1px solid #34465e;
}

ul.pagination {
	border-radius: 0;
	box-shadow: none;
	&>li {
		display: list-item;
		cursor: inherit;
	}
}

label {
	color: inherit;
	font-weight: inherit;
	font-size: 1em;
}

.tooltip {
	&.top,
	&.right,
	&.bottom,
	&.left {
		margin: 0;
	}
}
 /*
#ngpopup .ngpopupHeader {
	h2{
		margin: 0; padding: 0;
	}
	hr {
		margin: 0.5em 0;
	}
}
*/

.list-table .rwd-over-large {
	@include media(">xlarge", "<=xxlarge") {
		display: none;
	}
}
.list-table .rwd-under-large {
	@include media(">xlarge", "<=xxlarge") {
		display: initial;
	}
}

.dbModuleTABLE table {
	height: initial;
	padding: 0;
	font-size: inherit;
}
.dbModuleTABLE thead td {
	color: inherit;
	background-color: transparent;
	border-left: none;
}
.dbModuleTABLE tbody tr {
	border-top: none !important;
}

#profile {
	ul.tabs {
		padding-bottom: 0;
		padding-left: 2rem;
		margin-top: 0;
		margin-bottom: 0;
		li {
			font-size: inherit;
			display: inherit;
			list-style: none;
			padding: 0;
			color: inherit;
			&:hover {
				cursor: inherit;
				text-decoration: inherit;
			}
		}
	}
	.formContainer {
		width: auto;
		background-color: transparent;
		border: none;
		margin: 0;
		color: inherit;
		padding: 0;
		font-size: inherit;
		hr {
			border-color: $hr-color;
		}
		&.billingContainer {
			width: 610px; margin: 0 auto;
		}
	}
	#submitPasswordForm {
		padding-top: .25em;
	}
}

#whitelabelPreview {
	.width80middle {
		margin: 0 auto;
	}
	#firstPDFPage h1 {
		margin: inital;
		border: none;
		background: transparent;
	}
	.logo {
		max-height: 80px;
		max-width: 500px;
	}
}
#whiteLabelContainer #whiteLabelError {
	background-color: $warning-color;
	border: 1px solid #123F9F;
	color: $white;
	padding: $global-gutters;
	margin: 0;
	border-bottom: 0;
	a {
		color: inherit;
		text-decoration: underline;
		&:hover,
		&:focus {
			text-decoration: none;
		}
	}
}

#markEvol svg {
	width: 170px; height: 170px;
}

#advancedSettingsArea {
	top: 40px;
}

footer {
	clear: none;
	color: inherit;
	font-size: inherit;
	padding: 0;
	background-color: transparent;
	border-top: none;
}

.fragment-container {
	@extend %clearfix;
}

#introContent #urlField, .comparisonFormContainer #urlField, .homeTop #urlField {
	z-index: 30;
}

.border-box-sized,
.input-like,
.select-like,
.switchbox label,
.input-w-control input {
	box-sizing: border-box;
}
.border-box-sized,
.input-like,
.select-like,
.input-w-control input {
	width: 100%;
}
.analysis-form {
	&-name,
	&-url {
		&-input {
			width: auto;
		}
	}
}

input:disabled,
textarea:disabled,
select:disabled {
	border-color: $input-disabled-color !important;
	background-color: $input-disabled-bg !important;
	color: $input-disabled-color !important;
}

.readOnly .isdisabledReadOnly:hover,
.readOnly .isdisabledReadOnly *:hover,
.readOnly .isdisabledReadOnly:hover,
.readOnly .isdisabledReadOnly *:hover {
	background-color: $btn-disabled-bg !important;
}


.homeTop #analyseButton,
.homeTop #waitingAnalyseButton {
	right: -569px !important;
}

.chart-loader {
	position: absolute; top: 0; right: 0; bottom: 0; left: 0;
	display: flex;
	background-color: rgba(white, .8);
	font-size: 5em;
	& > .sk-fading-circle {
		margin: auto;
	}
}

#trackingView .trackingTopBox {
	margin-top: 0;
}

#rangeDataContainer {
	position: sticky; top: 0; z-index: 1;
	height: auto; margin-top: $global-gutters; margin-bottom: $global-gutters;

	.box {
		margin: 0;
		border: solid $softwhite; border-width: .5rem 0;
	}
}
.trackingChartsContainer {
	min-height: auto;
}

.monitoring-dbModuleSTATUS,
.monitoring-dbModuleRESOURCESSTATUS {
	.moduleTitle {
		display: none;
	}
}
.monitoring-repeatedViewTable {
	width: 100%; margin: 0 !important;
}

.dbModuleSTATUS .captionStatus > span {
	display: block; margin: .5em 0;
}

.analysis-item-metas .tableTooltipConfig {
	width: auto; padding-top: 0;
	border-collapse: collapse;
	table-layout: initial;

	td {
		width: auto; /* padding: 0; */
		vertical-align: initial;
	}
}
