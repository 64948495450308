.loading-page {
	&-info,
	&-focus {
		margin: 5% 0 15%;
	}
	&-url {
		& > * {
			display: block;
		}
		@include media(">medium") {
			display: flex;
			flex-flow: row nowrap; justify-content: space-between;
			& > :first-child {
				flex: 0 1 auto;
			}
			& > :last-child {
				margin-left: 2em;
				flex: 0 0 auto;
			}
		}
	}
	&-waiting {
		margin: 15% 0;
		.loading-spinner {
			display: block; width: auto; margin: 0 auto;
			font-size: 4em;
		}
		&-message {
			margin-top: 1em;
			transition: opacity .15s ceaser(easeInOutCubic);
			&.fade-out {
				opacity: 0;
			}
		}
	}
}
