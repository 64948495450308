#ngpopup.shareByEmail {
	width: 368px;
}

#ngpopup.shareByEmail.shareTip {
	width: 470px;
}

.shareByEmail form {
	padding: 20px
}

.shareByEmail.shareTip form {
	padding: 20px
}

.shareByEmail label {
	font-size: 15px;
	color: #999;
	font-weight: bold;
	margin: 10px;
}

.shareByEmail input, .shareByEmail textarea {
	font-size: 12px;
	border-radius: 5px;
	box-shadow: none;
	padding: 9px 15px 9px 15px;
	margin-bottom: 20px;
	margin-top: 5px;
	color: #2B3D4F;
	width: 288px;
}

.shareByEmail:not(.shareTip) a{
    padding: 6px 15px 6px 15px;
    margin: 10px 25px 0px 15px;
    display: block;
    text-align: center;
}

.shareByEmail:not(.shareTip) a:hover{
    background-color: #2ba6cb;
    cursor: default;
}

.shareByEmail a.button:active{
    position: initial;
}

.shareByEmail input.button {
	margin: 10px 15px 0;
	color: #fff;
}

.shareByEmail #shareRecipientSeparator{
    margin-left: 10px;
    font-size: 13px;
    color: #999;
}

#urlReportToShare{
	word-break: break-all;
}
