/*
 * Be careful it's seems to use 0px instead of 0 can cause some issue to rendering PDF !
 * Bug find with this code :  
 * .logo {
 *	margin: 40px 0 40px 0;
 * 	max-height: 80px;
 *	max-width: 500px;
 *	width: auto;
}
 */
#firstPDFPage .h1 {
	color: black;
	font-size: 3.3em;
	text-align: center;
	margin:100px 20px 0 20px;
	font-weight: normal;
}

.headResume {
	border-radius: 5px;
	width: 90%;
	padding-right: 5px;
	margin: 0 auto;
	font-weight: normal;
	font-size: 20px;
	color: #3e3e3e;
}

.logo {
	margin: 40px 0 40px 0;
	max-height: 80px;
	max-width: 640px;
	width: auto;
}

.blueBold{
	font-weight: bold;
	color:#2eb6d2;
}

#firstPDFPage .url {
	color:#2eb6d2;
	font-size: 18px;
	font-weight:normal;
	text-align:center;
	margin-bottom:100px;
}

.imgCenter{
	display:block;
	margin: 0 auto; 
}

/*
 * Small Snasphot
 */
 #firstPDFPage .snapshot{
 	margin:80px 0 20px 0;
 }
 
 
#firstPDFPage .smallSnapshotContainer, #firstPDFPage .smallSnapshotMobileContainer{
	/*Border right */
	border-right-style:solid;
	border-right-color:#2c3e50;
	/*Border left */
	border-left-style:solid;
	border-left-color:#2c3e50;
	margin:auto;
	background-color: grey;
}

#firstPDFPage .smallSnapshotContainer{
	min-height:70px;
	width:180px;
	border-right-width:5px;
	border-left-width:6px;
	box-sizing: content-box;
}

#firstPDFPage .snapshot.mobileSnapshot {
	margin-top: 50px;
}

#firstPDFPage .snapshot.mobileSnapshot .url {
	margin-bottom: 50px;
}

#firstPDFPage .smallSnapshotMobileContainer {
	width:126px;
}

#firstPDFPage .smallSnapshotMobileContainer img {
	width:126px;
}

.subRight{
	text-align: right;
	font-size: 16px;
	display: block;
}

.introBordered{
	margin-top:0;
	border: 2px solid #2B3D4F;
    padding: 15px;
}

.snapchotComparisonContainer {
	position: relative;
}

.vs {
	position: absolute;
	top: 36%;
	width: 100%;
	text-align: center;
	font-weight: bold;
	font-size: 58px;
	color: #9DADAF;
	margin: 0;
}

/*
 * Mobile specific
 */
#firstPDFPage.pdfCoverMobile .h1  {
	margin-top: 50px;
}
