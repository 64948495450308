// Legacy code overrides -_-v
#ngpopup.timeline-popup {
	width: 100%; height: auto; min-height: 100%; margin: 0; padding: 0; box-sizing: border-box;
}

.report-header {
	margin-bottom: .5em;

	&-name {
		display: flex;
		flex-flow: row nowrap; justify-content: flex-start; align-items: center;

		& > * {
			flex: 0 0 auto;
		}

		&-text {
			min-width: 1em; margin-right: .5em; padding: calc(#{$input-vpadding / 2} + #{$input-border-width}) 0; overflow: hidden;
			flex: 0 1 auto;
			white-space: nowrap; text-overflow: ellipsis;
		}

		&-separator {
			padding-bottom: .125em;
			line-height: 1;
		}
	}

	&-form {
		display: flex;
		flex-flow: row nowrap; justify-content: flex-start; align-items: stretch;

		input {
			width: auto; margin: 0 -1px 0 0; padding: #{$input-vpadding / 2} #{$input-hpadding};
			flex: 1 0 auto;
			border-top-right-radius: 0; border-bottom-right-radius: 0;
		}

		button {
			margin: 0;
			flex: 0 0 auto;
			border-top-left-radius: 0; border-bottom-left-radius: 0;
		}
	}
}
