.login-wrapper {
	width: 100%; max-width: 26em; height: 100%; padding: $global-gutters; margin: 0 auto; box-sizing: border-box;
	flex: 1 0 auto;
	background-color: $white;
}

.login-db-logo {
	width: 7.5em;
	font-size: 2em;
}

.login-icon {
	display: block; width: 1em; height: 1em; padding: .3em .35em .5em .45em; margin: 25% auto 0;
	border: .05em solid $focus-color; border-radius: 50%;
	font-size: 2.5em;
}

.login-error ul {
	text-align: left;
}

#login-cs:hover {
	background-color: $softwhite;
}

#login-cs span.text-left {
	color: $default-color;
	margin-left: 20px;
}

#login-cs svg {
	width: auto;
	height: 50px;
}
