.monitoring-group-item {
	display: flex; padding: $global-gutters;
	flex-flow: column nowrap;
	border-radius: $global-border-radius;
	background: $default-bg;
	transition: box-shadow $default-duration $default-timing-function;

	&.is-favorite {
		box-shadow: 0 0 0 2px $favorite-color;
	}

	&-title {
		@extend .h-reset;
		& > * {
			vertical-align: middle;
		}
	}

	/*
	position: relative;
	&-btn-delete {
		position: absolute; top: .25em; right: .25em;
		width: $default-line-height * 1em;
		text-align: center;
	}
	*/

	hr {
		margin-left: -$global-gutters; margin-right: -$global-gutters;
	}

	&-info {
		flex: 1 1 auto;

		&-icon {
			font-size: 3em; text-align: center;
		}

		@include media('>medium') {
			display: flex; max-width: 90%; margin: 0 auto;
			flex-flow: row nowrap; justify-content: flex-start; align-items: center;

			&-icon {
				margin-right: .5em;
			}

			&-text {
				margin: 0;
				flex: 1 1 auto;
			}
		}
	}
}

[class*=' grid-'],
[class^='grid-'] {
	.monitoring-group-item {
		height: 100%; box-sizing: border-box;
	}
}
