#doc .mainContainer {
    width:auto; 
    max-width: 1000px;
}


.banner h1, .childBanner h1, .documentationPage .banner .mainTitle {
	color: #2c3e50;
	font-size: 26px;
	line-height: 130%;
	letter-spacing: .2px;
	text-align: center;
	padding: 50px 0;
	margin: 0;
	font-weight: 400;
	background: inherit;
	border-bottom: none;
}

.banner h1 .bold {
	font-size: 30px;
	letter-spacing: 1px;
	font-weight: bold;
}

#doc .banner {
   border-bottom: 1px solid #F0F0F0;
   background-color: #fcfcfc;
}

#doc.documentationPage .mainTitle {
    display: block;
}

#doc h1, #doc.documentationPage .mainTitle {
	font-size: 28px;
	font-weight: 600;
	padding: 60px 0 20px;
}
/*
#doc h2, #doc.documentationPage h1  {
	font-size: 28px;
	font-weight: normal;
}
*/
#doc.documentationPage h1  {
    color: #2b3d4f;
    padding: 0;
    margin: 40px 0 0 0;
    background: none;
    border-bottom: none;
}
/*
#doc h3 {
	font-size: 22px;
	font-weight: 600;
    padding-top: 80px;
    margin-bottom: 15px;
}

#doc h3.firstAction {
	padding-top: 15px;
}

#doc h4 {
	margin-bottom: 2px;
}
*/
#doc .banner p {
    text-align: center;
    color: #2C3E50;
    font-size: 18px;
    width: 75%;
    margin: auto;
    padding-bottom: 60px;
}

#doc nav {
	font-size: 16px;
	margin: 40px 0 0;
}

#doc nav a {
	color: #334860;
	text-decoration: none;
}

#doc nav a.active {
    color: #2ba6cb;
    font-weight: 600;
}

#doc nav ul {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

#doc nav li {
	margin: 10px 0;
}

#doc .fixToTop {
	margin-top: 40px;
    background-color: #fff;
    border-radius: 5px;
    padding: 5px;
}

#doc .subNav {
	font-size: 14px;
	padding-left: 40px;
}

#doc .subNav li {
	margin: 5px 0;
}


#doc .subNav a {
	color: #999;
	padding-left:5px;
    font-size: 13px;
}

#doc .subNav .subNavTitle {
	font-weight: bold;
    color: #334860;
    cursor:pointer;
}

#doc .subNav .subNavTitle a {
    font-weight: bold;
    color: #334860;
    padding-left: 0px;
    font-size: 14px;
}

#doc .active a {
    text-decoration: underline;
}


.apiContent, .docContent {
    color: #2c3e50;
    margin-bottom: 50px;
}

.docContent p, .docContent ul li, .docContent td {
	line-height: 1.7em;
	font-size: 16px;
}

#doc td {
	line-height: 1.5em;
	font-size: 14px;
}


.docContent li, .apiContent li {
    padding: 5px 0px;
}

.apiContent .url {
	color: #9dc1e4;
}

.apiRoute, .additionalTip {
	border: 1px solid #9dc1e4;
    padding: 10px;
    margin: 5px 0;
    border-radius: 5px;
    border-left: 10px solid #9dc1e4;
}

.additionalTip {
    margin-top: 50px;
    margin-bottom: 50px;
}

.apiRoute .requestType, .additionalTip .additionalTipTitle {
	padding: 5px 10px;
    background-color: #9DC1E4;
    color: #fff;
    font-weight: bold;
    margin-right: 20px;
    border-radius: 5px;
}

.additionalTip .additionalTipTitle {
    display: inline-block;
    margin-bottom: 15px;
}

.apiContent pre {
	padding-left: 20px;
}

.apiRequest {
	background-color: #E6EFF8;
}

.apiResponse {
	background-color: #FFE9BF;
}

.botable.requestParam tr:nth-child(odd) {
    background-color: #F2F7FB;
}

.botable.responseAttributes tr:nth-child(odd) {
	background-color: #FFFAEF;
}

.apiContent .curlExample {
    color: #333;
    padding: 10px 20px;
    margin-bottom: 10px;
    line-height: 1.6;
    display: block;
}

#doc .apiQuote {
	margin-top: 60px;
	text-align: center;
	display: inline-block;
}

#doc .apiQuote a {
	color: #fff;
}

.apiQuote p {
	color: #334860;
}

/************************
*                       *
*      Release note     *
*                       *
************************/
#doc #releaseNote{
    margin-top: 40px;
}

#doc #menuFilter {
    padding: 10px;
    color: #2c3e50;
    float:left;
}

#doc #menuFilter .subNav {
    padding-left: 10px;
}

#doc #menuFilter h3, #doc #detailsVersions h3{
    padding-top: 5px;
}

#doc #detailsVersions .detailsVersion{
	padding-bottom: 100px;
}

#doc #menuFilter li{
	padding: 5px 0;
	cursor: pointer;
}

#doc #menuFilter li a{
    display: block;
}

#doc #menuFilter .apiDate{
	float: right;
    padding-right: 25px;
}


#doc #menuFilter a:hover .apiDate{
	text-decoration: underline;
}

#doc #detailsVersions{
    margin-bottom: 50px;
}

#doc #detailsVersions h2 {
    margin: 0;
}

#doc #detailsVersions .releaseNoteRouteBloc{
    padding-left: 30px;
}

#doc #detailsVersions .codeApiTitle{
    font-weight: 600;
    font-size: 12px;
}

#doc #detailsVersions .apiDate{
	font-size: 15px;
}

#doc img {
    margin: 45px;
}

pre.oldApiVersion:BEFORE {
    content: "old version";
    display: block;
    text-decoration: underline;
    color: #e57d21;
}

pre.newApiVersion:BEFORE {
    content: "new version";
    display: block;
    text-decoration: underline;
    color: #27ae60;
}


#doc .mobileOnly {
    display: none;
}

@media screen and (max-width: 1000px){
    #doc.documentationPage .docContent {
        width: 100% !important;
    }

    #doc .mobileOnly {
        text-align: center;
        margin: 50px;
        display: block;
    }
}

@media screen and (max-width: 500px){
    #doc.documentationPage .docContent img{
        width: 100% !important;
        margin: 30px 0px;
    }
}

#doc #docRoot nav li {
    width: 300px;
    margin: 10px auto;
}
