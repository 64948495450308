.video {

	&-player {
		display: flex; margin: #{$default-line-height * 1em} 0;
		flex-flow: row wrap; justify-content: center; align-items: center;

		& > * {
			flex: 0 1 auto;
		}

		video,
		img { // img for unavailable videos
			width: 100%; height: auto; box-sizing: border-box;
			border: .125em solid $hr-color;
		}

		&.is-with-legend {
			video,
			img {
				border-left-width: .5em;
			}
		}

		&.is-for-one,
		&.is-for-two {
			.video-time {
				font-size: 1.5em; text-align: center;
			}
		}

		&.is-for-two {
			.video-item {
				@include media('>xlarge') {
					margin: 0 .5em;
				}
			}
		}

		&.is-for-multi {
			flex-wrap: nowrap;

			.video-time {
				position: relative; top: -1.75em;
				margin-bottom: $default-line-height * -1em;
				font-size: 1.25em; text-align: center;
				text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
			}
		}

		&.is-col-9,
		&.is-col-10 {
			flex-wrap: wrap;

			& > * {
				flex: 0 1 calc(20% - .25em);
			}
		}

		&.is-col-11,
		&.is-col-12 {
			flex-wrap: wrap;

			& > * {
				flex: 0 1 calc(16.66% - .25em);
			}
		}
	}

	&-column {
		margin: 0 .125em;

		video,
		img {
			margin: .125em 0;
		}
	}

	&-controls {
		/*
		position: sticky; bottom: 0;
		margin: 0 #{$global-gutters * -1} #{$global-gutters * -1}; padding: 0 $global-gutters $global-gutters;
		border-top: 1px solid $hr-color;
		background-color: $default-bg;
		*/

		&-btn-play {
			position: relative;

			.play-icon,
			.pause-icon {
				transition: inherit, color 0s;
			}

			.pause-icon {
				position: absolute; top: 50%; left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0; visibility: hidden; pointer-events: none;
			}

			&.is-playing {
				.play-icon {
					opacity: 0; visibility: hidden; pointer-events: none;
				}

				.pause-icon {
					opacity: 1; visibility: visible; pointer-events: auto;
				}
			}
		}

		&-speed {
			display: inline-block;

			input {
				@extend %hide-checkbox;
			}

			label {
				cursor: pointer;
				transition: $default-duration $default-timing-function;

				&::first-letter {
					text-transform: none;
				}
			}

			input:checked + label {
				border-color: darken($focus-color, 15%);
				background: $focus-color;
				color: $white;
				pointer-events: none; cursor: not-allowed;
			}

			&-title {
				margin: 0 .5em;
			}
		}
	}

	&-seeker {
		// from https://css-tricks.com/sliding-nightmare-understanding-range-input/

		$track-width      : 100%;
		$track-height     : .5em;
		$thumb-size       : 1em;
		$track-color      : $softwhite;
		$fill-color       : $focus-color;
		$thumb-color      : $focus-color;
		$thumb-hover-color: $default-color;

		@mixin track($fill: 0) {
			width: $track-width; height: $track-height; box-sizing: border-box;
			border: none;
			background: $track-color;

			@if $fill == 1 {
				background: linear-gradient($fill-color, $fill-color) 0/ var(--sx) 100% no-repeat $track-color;
			}
		}

		@mixin fill() {
			height: $track-height;
			background: $fill-color
		}

		@mixin thumb() {
			width: $thumb-size; height: $thumb-size; box-sizing: border-box;
			border: none; border-radius: 50%;
			background: $thumb-color;
			transition: $default-duration $default-timing-function;
		}

		@mixin thumb-h() {
			transform: scale(1.2);
			background: $thumb-hover-color;
		}

		&, &::-webkit-slider-thumb {
			-webkit-appearance: none;
		}

		--min: 0;
		--max: 100;
		--val: 0;

		--range: calc(var(--max) - var(--min));
		--ratio: calc((var(--val) - var(--min))/var(--range));
		--sx: calc(.5*#{$thumb-size} + var(--ratio)*(100% - #{$thumb-size}));

		display: block; width: $track-width; height: $thumb-size; margin: $global-gutters 0; padding: 0;
		border: none; box-shadow: none;
		background: transparent;
		font: 1em/1 arial, sans-serif;
		cursor: ew-resize;

		&:hover,
		&:focus {
			border: none; box-shadow: none;
		}

		&::-webkit-slider-runnable-track {
			@include track(1);
		}
		&::-moz-range-track { @include track; }
		&::-ms-track { @include track; }

		&::-moz-range-progress { @include fill; }
		&::-ms-fill-lower { @include fill; }

		&::-webkit-slider-thumb {
			margin-top: .5*($track-height - $thumb-size);
			@include thumb;
		}
		&::-moz-range-thumb { @include thumb; }
		&::-ms-thumb {
			margin-top: 0;
			@include thumb;
		}

		&:hover,
		&:focus {
			&::-webkit-slider-thumb { @include thumb-h; }
			&::-moz-range-thumb { @include thumb-h; }
			&::-ms-thumb { @include thumb-h; }
		}

		&::-ms-tooltip { display: none; }
	}
}
