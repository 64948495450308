.flexGrid {
	display: flex; width: 100%; margin: 0; padding-left: 0;
	flex-flow: row wrap;
}

.flexGrid > * {
	min-width: 150px; margin: 0.8%; box-sizing: border-box;
	flex: 1 0 15%;
	list-style: none;
}

[class^="dashboardModule_2"] {
	flex: 2.1 0 31.6%;
	min-width: 300px;
}

[class^="dashboardModule_3"] {
	flex: 3.2 0 48.2%;
	min-width: 450px;
}

[class^="dashboardModule_4"] {
	flex: 4.3 0 64.8%;
	min-width: 600px;
}

[class^="dashboardModule_5"] {
	flex: 5.4 0 80.6%;
	min-width: 750px;
}

[class^="dashboardModule_6"] {
	flex: 6.5 0 97.2%;
}

@media (max-width:480px) {
	.flexGrid [class^="dashboardModule_3"],
	.flexGrid [class^="dashboardModule_4"],
	.flexGrid [class^="dashboardModule_5"],
	.flexGrid [class^="dashboardModule_6"] {
		min-width: 96%;
	}
}

@media (max-width:620px) {
	.flexGrid [class^="dashboardModule_4"],
	.flexGrid [class^="dashboardModule_5"],
	.flexGrid [class^="dashboardModule_6"] {
		min-width: 96%;
	}
}

@media (max-width:780px) {
	.flexGrid [class^="dashboardModule_5"],
	.flexGrid [class^="dashboardModule_6"] {
		min-width: 96%;
	}
}
