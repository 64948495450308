/**
 * Abstracts ------------------------------------------------------------------
 */

%scenario-component-item {
	position: relative;
	display: flex; margin: $global-gutters 0;
	flex-flow: row nowrap; justify-content: flex-start; align-items: stretch;
	border: 1px solid $scenario-step-item-border-color; border-radius: $global-border-radius;
	background-color: $scenario-step-item-bg;
}

%scenario-component-item-arrows {
	&::before,
	&::after {
		position: absolute; left: 1.125em;
		width: 1.25em; height: 1.25em;
		transform: translateY(-50%) rotate(45deg);
		border: solid $scenario-step-item-border-color; border-width: 0 1px 1px 0;
		content: "";
	}
	&:before {
		top: 0; z-index: 1;
		background-color: $scenario-step-item-bg;
	}
	&:after {
		top: 100%; z-index: 2;
		background-color: $scenario-step-item-number-bg;
	}
}


%scenario-component-item-number,
%scenario-component-item-screenshot,
%scenario-component-item-icon {
	position: relative;
	display: flex;
	flex: 0 0 3.5em;
	border-radius: #{$global-border-radius - .1rem} 0 0 #{$global-border-radius - .1rem};
	color: $scenario-step-item-number-color;
}

%scenario-component-item-number,
%scenario-component-item-icon {
	background: $scenario-step-item-number-bg;
}

%scenario-component-item-screenshot,
%scenario-component-item-icon {
	overflow: auto;
	flex-basis: 8em;
	@include media(">medium") {
		flex-basis: 9em;
	}
	@include media(">large") {
		flex-basis: 10em;
	}
	@include media(">xlarge") {
		flex-basis: 11.5em;
	}
}

%scenario-component-item-screenshot {
	padding: 1em $global-gutters;
}

%scenario-component-item-icon {
	padding: 0 $global-gutters;
	color: $scenario-step-item-icon-color;
}

%scenario-component-item-number-inner,
%scenario-component-item-screenshot-inner,
%scenario-component-item-screenshot-msg,
%scenario-component-item-icon-inner {
	margin: auto;
}

%scenario-component-item-screenshot-inner {
	position: relative;
	max-height: 12em; overflow: hidden;
	&::after {
		--from-color: rgba($white, 0);
		--to-color: rgba($white, 1);
		position: absolute; top: 10em; left: 0; right: 0;
		height: 2em;
		background: linear-gradient(to bottom, var(--from-color) 0%, var(--to-color) 100%);
		content: ''; pointer-events: none;
	}
}

%scenario-component-item-content {
	display: flex;
	flex: 1 1 auto; flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
	@include media(">small") {
		flex-direction: row;
	}

	.capturing-step {
		margin-top: 1em;

		& > svg {
			height: 1.5em;
			margin-right: 0.5em;
			width: 1.5em;
		}
	}
}

%scenario-component-item-content-inner {
	padding: $global-gutters;
	flex: 1 1 auto;
}

%scenario-component-item-status {
	margin-right: 1em;
}

%scenario-component-item-alerts {
	margin: 0 -.5em;
}

%scenario-component-item-meta {
	padding: $global-gutters;
	border: solid $scenario-step-item-border-color; border-width: 1px 0 0;
	@include media(">small") {
		display: flex;
		flex: 0 0 20%; flex-flow: column nowrap; justify-content: space-between; align-items: stretch;
		border-width: 0 0 0 1px;
	}
}

%scenario-component-item-control {
	display: flex;
	justify-content: flex-end;
	margin-top: $global-gutters;
}

%scenario-component-item-order {
	position: absolute; left: 50%;
	transform: translateX(-50%);
}

%scenario-component-item-order-up {
	@extend %scenario-component-item-order;
	top: .5em;
}

%scenario-component-item-order-down {
	@extend %scenario-component-item-order;
	bottom: .5em;
}


/**
 * Usage ----------------------------------------------------------------------
 */

.scenario-step-item {
	@extend %scenario-component-item;

	&.with-arrows      { @extend %scenario-component-item-arrows; }
	&-number           { @extend %scenario-component-item-number; }
	&-number-inner     { @extend %scenario-component-item-number-inner; }
	&-screenshot       { @extend %scenario-component-item-screenshot; }
	&-screenshot-inner { @extend %scenario-component-item-screenshot-inner; }
	&-screenshot-msg   { @extend %scenario-component-item-screenshot-msg; }
	&-icon             { @extend %scenario-component-item-icon; }
	&-icon-inner       { @extend %scenario-component-item-icon-inner; }
	&-content          { @extend %scenario-component-item-content; }
	&-content-inner    { @extend %scenario-component-item-content-inner; }
	&-alerts           { @extend %scenario-component-item-alerts; }
	&-metas            { @extend %scenario-component-item-meta; }
	&-control          { @extend %scenario-component-item-control; }
	&-order-up         { @extend %scenario-component-item-order-up; }
	&-order-down       { @extend %scenario-component-item-order-down; }
}

.scenario-action-item {
	@extend %scenario-component-item;
	&.with-arrows      { @extend %scenario-component-item-arrows; }
	&-number           { @extend %scenario-component-item-number; }
	&-number-inner     { @extend %scenario-component-item-number-inner; }
	&-content          { @extend %scenario-component-item-content; }
	&-content-inner    { @extend %scenario-component-item-content-inner; }
	&-status           { @extend %scenario-component-item-status; }
	&-control          { @extend %scenario-component-item-control; }
	&-order-up         { @extend %scenario-component-item-order-up; }
	&-order-down       { @extend %scenario-component-item-order-down; }
}

.scenario-action-list {
	@extend %scenario-component-item;

	&-number           { @extend %scenario-component-item-number; }
	&-number-inner     { @extend %scenario-component-item-number-inner; }
	&-content          { @extend %scenario-component-item-content; }
	&-content-inner    { @extend %scenario-component-item-content-inner; }
}


/**
 * Specifics ------------------------------------------------------------------
 */

.scenario-step-item,
.scenario-action-item {
	&.is-new,
	&.is-creating,
	&.is-see-more {
		&,
		&::before,
		&::after {
			border-style: dashed;
		}
	}
	&.is-optional {
		border-style: dashed;
	}
}
.scenario-step-item {
	&.is-creating {
		.scenario-step-item-order-up {
			top: 1em;
		}
		.scenario-step-item-order-down {
			bottom: 1em;
		}
	}
	&.is-new {
		.scenario-step-item-number,
		&::after {
			background-color: $scenario-step-item-bg;
		}
		.scenario-step-item-content {
			padding: $global-gutters * 2 $global-gutters;
			& > * {
				margin: auto;
			}
		}
	}
	&.with-overflow-content {
		.scenario-step-item-content,
		.scenario-step-item-content-inner {
			overflow: hidden;
		}
	}
	&.with-screenshot {
		@include media(">small") {
			&::before,
			&::after {
				left: 6.25em;
			}
			&::after {
				background-color: $scenario-step-item-bg;
			}
			&.is-alert {
				&::after {
					background-color: $alert-color; // specificity fail -_-v
				}
				.scenario-step-item-screenshot-inner::after {
					--from-color: rgba($alert-color, 0);
					--to-color: rgba($alert-color, 1);
				}
			}
		}
	}
	&.with-legend {
		&:not(.is-technical) {
			border-left-width: .5em; border-left-color: var(--item-legend-color);
		}
		.scenario-step-item-number,
		.scenario-step-item-screenshot {
			border-radius: 0;
			color: var(--item-legend-color);
		}
		.scenario-step-item-icon {
			// border-left-width / 2 = .25em
			padding: 0 calc(#{$global-gutters} + .25em);
		}
	}
	&.is-technical {
		@include media(">small") {
			&::before,
			&::after {
				left: 6.75em;
			}
			&::after {
				background-color: $scenario-step-item-number-bg;
			}
			&.is-alert {
				&::after {
					background-color: $alert-color; // specificity fail -_-v
				}
			}
		}
	}
	&.is-alert {
		.scenario-step-item-number,
		.scenario-step-item-screenshot,
		.scenario-step-item-icon,
		&::after {
			background-color: $alert-color;
			color: $white;
		}
	}
}
.scenario-action-item {
	&.is-creating {
		.scenario-action-item-order-up {
			top: 1em;
		}
		.scenario-action-item-order-down {
			bottom: 1em;
		}
	}
	&.is-new {
		.scenario-action-item-number,
		&::after {
			background-color: $scenario-step-item-bg;
		}
		.scenario-action-item-content {
			padding: $global-gutters * 2 $global-gutters;
			& > * {
				margin: auto;
			}
		}
	}
	&.is-see-more {
		&::after {
			background-color: $scenario-step-item-bg;
		}
		.scenario-action-item-content {
			padding: $global-gutters / 4 $global-gutters;
			& > * {
				margin: auto;
			}
		}
	}

	@include grid(2, 3, 76rem);
}

.scenario-step-item {
	.alerts-list {
		@extend %ul-reset;
		display: inline;
		&-text {
			margin: .25em .5em;
		}
		li {
			display: inline-block; margin: .25em .5em;
		}
		&-item {
			@extend %button, %button--small;
			@include colorize-button(darken($softwhite, 5%), $softwhite, inherit);
			font-weight: $font-weight-normal;
			&:hover,
			&:focus {
				@include colorize-button($softgrey, $softwhite, inherit);
			}
			@include disabled-button(none, none, $btn-disabled-color);
		}
	}
}

.scenario-action-item {
	&.is-warning {
		.scenario-action-item-number,
		.scenario-action-item-title {
			color: $warning-color;
		}
	}
	&.is-alert {
		.scenario-action-item-number,
		.scenario-action-item-title {
			color: $alert-color;
		}
	}
	&.is-main {
		.scenario-action-item-number,
		&::after {
			background-color: $scenario-step-main-item-number-bg;
			color: $scenario-step-main-item-number-color;
		}
		.scenario-action-item-order-up,
		.scenario-action-item-order-down {
			@include colorize-button(transparent, transparent, inherit);
			&:hover,
			&:focus {
				@include colorize-button($softwhite, $white, $focus-color);
			}
		}
		&.is-warning {
			.scenario-action-item-number,
			&::after {
				background-color: $warning-color;
			}
		}
		&.is-alert {
			.scenario-action-item-number,
			&::after {
				background-color: $alert-color;
			}
		}
	}
}

.scenario-action-list {
	&-number {
		display: block; padding: 1em 0;

		&-inner {
			transform: rotate(180deg) translateX(.125em);
			writing-mode: vertical-lr;
		}

		&-icon {
			transform: rotate(90deg);
		}
	}
}


/**
 * Other classes --------------------------------------------------------------
 */

.scenario-small-action {
	&-list {
		@extend %ul-reset;
	}
	&-item {
		--height: 2.25em;
		--max-per-line: 1;
		display: inline-flex; max-width: calc(100% / var(--max-per-line));
		flex-flow: row nowrap; justify-content: flex-start; align-items: stretch;
		@include media(">medium", "<=large") {
			--max-per-line: 2;
		}
		@include media(">large", "<=xlarge") {
			--max-per-line: 3;
		}
		@include media(">xlarge", "<=xxlarge") {
			--max-per-line: 5;
		}
		@include media(">xxlarge") {
			--max-per-line: 7;
		}
		&-arrow {
			height: var(--height);
			flex: 0 0 auto;
			& svg {
				height: var(--height);
				fill: $softwhite; stroke: $softgrey;
			}
			&:first-of-type svg {
				width: calc(var(--height)/3.484); margin-left: auto;
			}
			&:last-of-type svg {
				width: calc(var(--height)/2.632); margin-right: auto;
			}
		}
		&-content,
		&-main-icon,
		&-icon {
			height: var(--height); box-sizing: border-box;
			border: solid; border-width: 1px 0;
		}
		&-main-icon,
		&-icon {
			display: flex; padding: 0 .25em;
			flex: 0 0 auto; justify-content: center; align-items: center;
		}
		&-content,
		&-icon {
			border-color: $softgrey;
			background-color: $softwhite;
		}
		&-content {
			min-width: 0; padding: .375em 0;
			flex: 0 1 auto;
		}
		&-main-icon {
			padding-right: .5em;
			border-color: $focus-color;
			background-color: $focus-color;
			color: $white;
			& + * {
				padding-left: .5em;
			}
		}

		&.is-main {
			.scenario-small-action-item-arrow {
				& svg {
					stroke: $focus-color;
				}
				&:first-of-type svg {
					fill: $focus-color;
				}
			}
			.scenario-small-action-item-content,
			.scenario-small-action-item-icon {
				border-color: $focus-color;
			}
		}

		&.is-valid {
			.scenario-small-action-item-arrow svg {
				stroke: $valid-color;
			}
			.scenario-small-action-item-content,
			.scenario-small-action-item-icon {
				border-color: $valid-color;
			}
			.scenario-small-action-item-icon {
				color: $valid-color;
			}
		}

		&.is-warning {
			.scenario-small-action-item-arrow svg {
				fill: rgba($warning-color, .15); stroke: $warning-color;
			}
			.scenario-small-action-item-content,
			.scenario-small-action-item-icon {
				border-color: $warning-color;
				background-color: rgba($warning-color, .15);
				color: $warning-color;
			}
		}

		&.is-alert {
			&.is-main {
				.scenario-small-action-item-arrow:first-of-type svg {
					fill: $alert-color;
				}
			}
			.scenario-small-action-item-arrow svg {
				fill: rgba($alert-color, .15); stroke: $alert-color;
			}
			.scenario-small-action-item-content,
			.scenario-small-action-item-icon {
				border-color: $alert-color;
				background-color: rgba($alert-color, .15);
				color: $alert-color;
			}
			.scenario-small-action-item-main-icon {
				border-color: $alert-color;
				background-color: $alert-color;
			}
		}
		&.is-optional {
			.scenario-small-action-item-content,
			.scenario-small-action-item-icon {
				border-style: dashed;
			}
		}
	}
}
