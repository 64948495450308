
#playOverlayForSnapshot, #playOverlayForSnapshotMobile {
	position: absolute;
	font-size: 52px;
	font-style: normal;
    font-weight: normal;
    line-height: 1;
	border: 7px solid rgba(158,167,174,0.7);
	border-radius: 50%;
	padding: 9px 23px 9px 27px;
	color: rgba(158,167,174,0.7);
	cursor: pointer;
}

#playOverlayForSnapshot{
    left: 30%;
    top: 7%;
}

#playOverlayForSnapshotMobile{
    left: 17%;
    top: 30%;
}
