.svg-text {
	font-size: .8em;
}

.svg-middle {
	vertical-align: -.125em;
}

.svg-margin {
	margin-right: .25em;
}

.external-link-icon {
	@extend .svg-middle;
	font-size: .75em; color: $regular-color;
}

.is-grayscale {
	filter: grayscale(100%) brightness(1.25);
}

.is-svg-flag {
	filter: saturate(75%) brightness(1.25);
}

.evol-up {
	transform: rotate(-45deg);
}

.evol-down {
	transform: rotate(45deg);
}

.btn-icon {
	display: block; padding: ($input-hpadding / 2) 0;
	vertical-align: middle;
}
