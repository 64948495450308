.doc-menu {
	ul {
		margin-left: .5em !important; padding: .5em 0 .5em 1em !important; // Shame -_-v
		border-left: 1px solid $softgrey;
	}

	li {
		margin: .5em 0 !important; // Shame -_-v
	}

	span {
		font-family: monospace;
	}

	ul {
		a, button {
			font-size: .9em; font-weight: $font-weight-bold;
		}
	}

	ul ul {
		a, button {
			font-weight: $font-weight-normal;
		}
	}

	.is-active {
		a, button {
			text-decoration: underline !important; // Shame -_-v
		}
	}
}
