#titleButtonContainer{
	font-size: 13px;
	text-align: right;
	margin-top: 40px;
}

#titleButtonContainer .dropdown{
	display: inline-block;
	float:none;
}

#titleButtonContainer .dropdown{
	display: inline-block;
	float:none;
}

#titleButtonContainer .dropdown-menu {
	top: auto;
	left: auto;
	min-width: 70px;
	padding: 10px;
}

#titleButtonContainer .dropdown-menu a {
	cursor: pointer;
}

#titleButtonContainer a.dropdown-toggle {
	padding: 8px 16px 8px 16px;
	color: #fff;
}

#titleButtonContainer .button{
	height:21px;
}
#titleButtonContainer .sprite{
	margin-right:5px;
}

#titleButtonContainer .sprite.sprite-compare-icon{
	margin: 0 2px 0 -7px;
}

#titleButtonContainer .sprite-twitter, #titleButtonContainer .sprite-facebook {
	margin-bottom: -5px;
}

.totalTips .button {
    font-size: 16px;
}

.totalTips .button .ion{
    vertical-align: middle;
    font-weight: normal;
}

.mainContainer .globalResults {
	min-height: 190px;
}


.globalResults #pageWeight {
	border-left: 2px solid #9EA7AE;
	padding: 0 2%;
	position: relative;
    box-sizing: border-box;
}

.globalResults #pageWeight .rwValue {
	font-size: 48px;
	font-weight: bold;
	padding: 45px 0;
}

.globalResults #pageWeight .rwValue p {
	margin:0;
}

.globalResults #pageWeight .rwValue .small {
	font-size: 20px;
	font-weight: normal;
	margin-top: -10px;
}

.globalResults #pageWeight .donutsGauge{
    font-size: 23px;
    margin-top: 28px;
}

.globalResults #pageWeight .donutsGauge .small{
    font-size: 13px;
    font-weight: bold;
}

#weightAndReqCaptions{
    font-size: 12px;
    margin: 11px auto 5px;
}

.weightAndReqCaption{
    display: inline-block;
    height: 7px;
    width: 14px;
    margin: 0 4px 1px 7px;
}

.weightAndReqCaptionHtml{
    background-color: #0D549C;
    margin-left:0;
}

.weightAndReqCaptionCss{
    background-color: #1274D9;
}

.weightAndReqCaptionScript{
    background-color: #6FB7FF ;
}

.weightAndReqCaptionImage{
    background-color: #b4daff;
}

.weightAndReqCaptionOther{
    background-color: #ebf5ff;
}

hr.noLegend{
	padding-bottom: 24px;
}

.globalResults .timeGauge {
	margin: auto 2%;
}

.globalResults h2{
	padding:0;
	margin:0;
	text-align: center;
	font-size: 17px;
	color: #EBEFF1;
}

.globalResults h2 a {
	color:inherit;
}

.globalResults h2 a:hover{
	text-decoration: none;
}

.globalResults .tooltip {
	width: 305px;
}

.globalResults .button {
	padding: 5px 20px;
	margin: 0 15px;
}

#requestsCount {
	font-size:50px;
	font-weight:bold;
	color: #fff;
}

#validationResults {
	color: #fff;
	margin: 15px 0 10px;
	padding: 10px 10px 10px 25px;
	background-color: #2b3d4f;
}


#validationResults .w3cContainer {
	cursor: pointer;
}

#validationResults .w3c {
	font-size: 16px;
	vertical-align: super;
}

#validationResults .ttfbSpeedIndex {
	font-size: 18px;
	padding-left: 110px;
	padding-top: 2px;
	float: left;
}

#validationResults .ttfbSpeedIndex div {
	float: left;
	cursor: pointer;
}

#validationResults .speedIndex {
	padding-left: 20px;
}

#detectionResults {
	background-color: #f1f1f1;
	padding: 12px;
	color: #2b3d4f;
	font-size: 12px;
	overflow: hidden;
}

#detectionResults p {
	padding: 0;
	margin: 0;
}

#detectionResults img {
	max-width: 35px;
}

#detectionResults p.secondRow {
	margin-top: 15px;
}

#imgTechnoContainer{
	margin-top: 5px;
}

#noneDetected {
    color: #999999;
    font-size: 18px;
    font-weight: bold;
	margin: 2px 0 0 10px !important;
}

#detectionResults h2{
	margin: 0;
	padding: 0;
	font-size: 20px;
	color:#999;
}

.reportFeatures {
	margin-top: 20px;
}

.reportFeatures p {
	font-size: 15px;
	color: #8C8C8C;
	padding: 0 20px;
}

.reportFeatures .sprite {
	display: inline-block;
}

.reportFeatures .ion {
    font-size: 19px;
    font-weight: bold;
}

.reportFeatures .featureBoxTitle {
	font-size: 22px;
	font-weight: bold;
	margin-bottom: 30px;
	margin-top: 50px;
}

.reportFeatures img.appScreen {
	margin: 0 0 20px;
}

#tipsContainer {
	min-height: 750px;
}

#tipsContainer h5 {
	color: #000;
}

.tipsSummaryBox{
	width: 23%;
	height:70px;
	float: left;
	border:2px solid #efefef;
	margin-right: 1.5%;
	overflow: hidden;
	background-color: #fcfefb;
	margin-top: 20px;
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	-moz-box-shadow: 0px 0px 1px #ccc;
	-webkit-box-shadow: 0px 0px 1px #ccc;
	box-shadow: 0px 0px 1px #ccc;
	display: block;
	z-index: 3010;
}

.tipsSummaryBox .caption{
    color: #bdc6da;
	font-weight: bold;
}

.tipsSummaryBox .total{
	float: right;
	padding-top: 8px;
}

.tipsSummaryBox .link {
	font-weight: bold;
	color: #2ba6cb;
	text-decoration: none;
	float: right;
	margin: 15px 5px 0 0;
	display: inline-block;
	font-size: 13px;
}

.tipsSummaryBox:hover .link{
	text-decoration: underline;
}

.tipsSummaryBox.last{
	margin-right: 0;
}

.tipsSummaryBox h3{
	color: #2b3d4f;
	font-size: 16px;
	font-weight: bold;
	padding: 5px 0 0px 12px;
	margin:0;
	white-space: nowrap;
}

.tipsSummaryBox h3 img{
	margin-right: 5px;
	vertical-align: middle;
	float: right;
	max-width: 50px;
}

.tipsSummaryBox .caption{
    padding: 14px 4px 0 12px;
    font-size: 13px;
}

.tipsSummaryBox .ruleCount {
	font-size: 20px;
	padding-right: 1px;
}
/*
.colorBand{
	float: left;
	height:70px;
	width:5px;
	display: inline-block;
}

.success .colorBand{
	background-color: #27ad5f;
}

 .caption .success{
	color: #27ad5f;
}

.warning .colorBand{
	background-color: #e57d21;
}

 .caption .warning{
    color: #e57d21;
}

.fail .colorBand{
	background-color: #bf392b;
}

 .caption .fail{
    color: #bf392b;
}

.info .colorBand{
	background-color: #3bafcf;
}
*/



.dropdownColorBand{
    float: left;
    height:32px;
    width:4px;
    display: inline-block;
}

.success .dropdownColorBand{
    background-color: #27ad5f;
}

 .caption .success{
    color: #27ad5f;
}

.warning .dropdownColorBand{
    background-color: #e57d21;
}

 .caption .warning{
    color: #e57d21;
}

.fail .dropdownColorBand{
    background-color: #bf392b;
}

 .caption .fail{
    color: #bf392b;
}

.info .dropdownColorBand{
    background-color: #3bafcf;
}


a.tipsSummaryBox a {
	font-weight: bold;
	text-decoration: underline;
	float: right;
	margin: 12px 10px 0 0;
}

.tipsSummaryBox .grade {
	padding: 0 8px 0 5px;
	font-size: 28px;
	font-weight: bold;
	color: #bdc3c7;
}

.tipsSummaryBox:hover, .tipsSummaryBox.selected {
	cursor: pointer;
	background-color: #f8f8f8;
	text-decoration: none;
}

.tipsSummaryBox:hover.good .grade, .tipsSummaryBox.selected.good .grade{
	color: #27ad5f;
}

.tipsSummaryBox:hover.average .grade, .tipsSummaryBox.selected.average .grade{
	color: #e57d21;
}

.tipsSummaryBox:hover.bad .grade, .tipsSummaryBox.selected.bad .grade{
	color: #bf392b;
}

.tips{
	padding-bottom: 40px;
	min-height: 700px;
}

.w3cInfo {
	text-align: center;
}

.w3cTitle {
	font-size: 22px;
	color: #ffffff;
	font-weight: bold;
	padding-top: 15px;
}

.w3cOK {
	font-size: 30px;
	color: #2dcb71;
	font-weight: bold;
}

.w3cError {
	color: #e74b3b;
	font-weight: bold;
}

.w3cFail {
	font-size: 30px;
	color: #e74b3b;
	font-weight: bold;
}

.w3cMore {
	color: #ffffff;
}

#mark .scoreLegend {
	font-size: 18px;
}

.moreTips{
	text-align:center;
	padding:8px;
	background-color:#f1f1f1;
	margin-top: 10px;
	border:1px solid #ebeff1;

}

.moreTips:hover{
	cursor:pointer;
}
.moreTips:hover a{
	color: #2BA6CB;
}

.moreTips a{
	display:block;
	width:100%;
	color: #aaa;
	text-decoration:none;
}

.adviceButtons {
	padding-top: 20px;
}

.adviceButtons a {
	color: #2b3d4f;
    font-size: 13px;
	margin-top: 8px;
	display: inline-block;
	text-decoration: underline;
}

.adviceButtons a:hover {
	color: #2ba6cb;
}

.adviceButtons span{
    width: 15px;
}

.cursorPointer{
	cursor: pointer;
}

.reportMarkDown{
	margin-left: 10px;
}

#signUpTips {
	display:none;
	position: fixed;
  	top: 150px;
	right: 50px;
	text-align: center;
	padding:20px;
	background-color: #fff;
	border: 1px solid #CCC;
	-moz-border-radius: 5px;
	-webkit-border-radius: 5px;
	border-radius: 5px;
}

#signUpTips a {
	margin-top: 10px;
}

#returnTop {
	position: fixed;
  	bottom: 50px;
	right: 50px;
}

#returnTop a {
	margin: 5px;
}

#returnTop .tooltip {
	width: 100px;
}

.returnClassTop{
	width: 36px;
 	height:36px;
 	padding:2px;
 	display:block;
	background: url('../img/ascensceur-icon-s02.png') no-repeat;
	cursor:pointer;
}

.returnClassTop:hover{
	background-image: url('../img/ascensceur-icon-s01.png');
}

.minimizedTip .tipsOverlay {
	height: 70px;
}

#tipsTitle {
	color: #2b3d4f;
	margin-top: 50px;
	font-weight: bold;
}

#tipsTitle .dropdown {
	float: none;
	display: inline;
	padding: 0px 10px;
	text-decoration: underline;
    font-size: 24px;
}

#tipsTitle .dropdown .ion-arrow-down-b {
	font-size: 15px;
}
#tipsTitle .dropdown-menu {
	width: 650px;
    padding-left: 4px;
}

#tipsTitle .dropdown-menu a.selected {
	background-color: rgba(245,245,245,1);
	color: #668;
}

#showAllFilter{
    padding-bottom: 4px;
}

#showAllFilter span {
    margin-left: 8px;
}

#tipsTitle .dropdown-menu a {
	color: #2B3D4F;
    font-size: 15px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: solid 1px #ccc;
    display: inline-block;
    width: 206px;
    overflow: hidden;
    margin-left: 6px;
    line-height: 28px;
    text-decoration: none;
}

#tipsTitle .dropdown-menu a:hover {
	text-decoration: underline;
}

#tipsTitle .dropdown-menu a img {
	height: 16px;
}

#tipsTitle .dropdown-menu a span {
	padding-left: 1px;
    margin-right: 8px;
}

#tipsTitle .dropdown li {
	margin: 5px 30px;
}

.tipsOverlay {
	position: absolute;
	width: 776px;
	height: 90px;
	margin-left: -10px;
	background-color: rgba(250,250,250,0.7);
	box-shadow: 0 1px 7px rgba(250,250,250,0.7);
	text-align: center;
}

.tipsOverlay:HOVER {
	background-color: rgb(250,250,250);
}

.tipsOverlay span {
	display:none;
	margin-top: 15px;
	color: #2BA6CB;
	text-decoration: underline;
}

.blur code {
	color: #E5BFC8;
}

.w3cSource, .w3cSkipped {
  font-style : italic;
}

.w3cSourceCSSLine {
	color: #404040;
	font-size: 12px;
}


#w3cContainer:hover, #timelineContainer:hover, #snapshotContainer.clickable img {
	cursor: pointer;
}

#apisError {
	border-radius: 4px;
    width: 50%;
    margin: auto;
    padding: 0px 10px 0px 10px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

#snapshotWithFold {
	background-size: auto auto;
	background-repeat:no-repeat;
}

#snapshotWithFoldContainer {
	clear: both;
}

#foldLineUrl {
	float: left;
}

#foldLineResolution {
	float: right;
}

#foldLine {
	width: 100%;
	background: black;
	background-color: rgba(0,0,0,0.5);
	filter:alpha(opacity=50);
	border-top: 6px dashed #00bf9c;
}

#resolutionInfos {
	margin:-12px auto;
	text-align: center;
	background:white;
	width: 100px;
	-moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    font-weight:bold;
}

.pollOver{
	z-index: 150;
	position: fixed;
}

.headerPopover{
	float:right;
}

.popover-inner .close{
	float:right;
}

 .voteTextarea{
 	margin:12px 0 5px 6px;
 	width: 92%;
}

 .closeContainer{
 	margin-right:10px;
 	height:12px;
	cursor: pointer;
 }

 .pollContainer{
  padding: 10px 15px 15px 15px;
 }

 .pollContainer h3{
 	margin-bottom:10px;
 	color: #33485d;
 }

.normalWeight{
	font-weight:normal;
}

/***** CONFIG *****/
#configContainer {
	color: #999;
	padding: 15px 0;
}

#configContainer .sprite {
	display: inline-block;
	margin-bottom: -8px;
	margin-right: 3px;
}

#configContainer .ion.before {
    padding-right: 3px;
}

#configContainer .ion {
	font-size: 25px;
	color: #7F8B8D;
	vertical-align: sub;
}

#configContainer .sprite, #configContainer .ion {
    opacity: 0.7;
}

#configContainer .configTitle {
	font-size: 16px;
	font-weight: bold;
}

#configContainer .configElement {
	margin: 0 0 0 15px;
	font-size: 15px;
}

#configContainer .configElement:nth-of-type(1){
	margin-left:0;
}

#configContainer #tooltipConfigHelp{
    margin-left: 13px;
    vertical-align: bottom;
}

#configContainer .button {
	font-weight: bold;
}
#configContainer .button.disabled{
	cursor: default;
	background-color: #ccc;
}

#configContainer .button.disabled:active{
    position: static;
    top: 0;
}

#configContainer .button.disabled:hover{
    background-color: #ccc;
}

/***** CHARTS MANAGEMENT *****/

#timeGauges {
    font-size:23px;
    color: #fff;
	height: 193px;
	padding: 0 0 15px 10px;
}

#timeGauges a {
    text-decoration: none;
}

#timeGauges h2 {
	margin-bottom: 28px;
}

#timeGauges .chartToolTip {
	margin-top:-30px;
	margin-right: 20px;
	font-size: 21px;
	color: #b9c2c6;
	float: right;
}

.coreWebVitals {
	font-size: 16px;
	text-align: left;
	margin-left: 2%;
}

#timeGauges .loadtimeChart {
	position:absolute;
}

.globalResults .chartText {
	position:absolute;
	z-index:1000;
	text-align:center;
	width:140px;
	margin-top: 58px;
	font-weight: bold;
}

#timeGauges .small {
    font-weight:bold;
    font-size:13px;
}

#timeGauges .gaugesNotAvailable {
	background-image: url("/assets/img/results/circle-graph-placeholder.png");
	margin-left: 37px;
	width: 135px;
	height: 118px;
	padding-top: 17px;
}

#timeGauges .gaugesNotAvailable p {
	font-size: 16px;
	text-align: center;
	font-weight: bold;
	position: relative;
}

#timeGauges .gaugesNotAvailable .chartToolTip {
	margin-top: 25px;
	margin-left: -48px;
	position:absolute;
	float:none;
}

#timeGauges .missingResult {
	font-size: 20px;
}

.greyContainer {
	background-color: #f1f1f1;
	padding: 10px 0;
}

#validationResults .button {
	padding: 5px 20px;
}

#validationResults .ion.ion-help {
	font-weight: normal;
	color: #999;
}

#validationResults .ion-help ~ .w3c {
	color: #999;
}

#validationResults .ion-checkmark-round {
	color: #b8e986;
}

#validationResults .ion-close-round {
	color: #bf392b;
}

/* Loader */
#loaderContainer{
	height: 100px;
 	position:relative;
}

#loaderImg{
	margin:auto;
 	position:absolute;
 	top:50px;
 	left:50px;
}

#bandwidthInfos {
	margin-top: -15px;
	color: rgb(204, 204, 204);
	font-size: 13px;
}

.actionsElement {
	margin-left: 15px;
}

.btnActionContent {
	vertical-align: 3px;
	font-weight: bold;
}
#marketingBox {
	margin-top: 40px;
	background-color: #FCFCFC;
}
#marketingBox .mainContainer {
	padding: 20px 0;
	color: #2C3E50;
}
#marketingBox h3 {
	font-size: 24px;
	color: #2C3E50;
}
#marketingBox h3 strong {
	font-size: 28px;
}
#marketingBox textarea, #marketingBox input {
	border-color: #EEE;
	margin-top: 10px;
}

#marketingBox .surveyThanks {
	color: #18AA57;
}

#wrongReportLang {
	width: auto;
	margin-top: 60px;
	font-size: 16px;
	padding-bottom: 20px;
}

/*
 * Console log
 */
#validationResults #browserErrors {
    vertical-align: middle;
    font-size: 18px;
}

#validationResults .redSquare, #validationResults .orangeSquare, #validationResults .greenSquare {
    font-size: 16px;
    font-weight: bold;
    margin: 0 10px;
    height: 26px;
    min-width: 30px;
    text-align: center;
    padding: 3px 3px 0 3px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 5px;
}

#validationResults .redSquare {
    border: 2px solid #bf392b;
}

#validationResults .orangeSquare {
    border: 2px solid #EDAC4F;
}

#validationResults .greenSquare {
    border: 2px solid #27AD5F;
}

 #ngpopup #consoleLogs {
    height: 540px;
}

 #ngpopup.maximize #consoleLogs{
    height:100%;
}

#ngpopup #consoleLogsListContainer {
    height: calc(100% - 150px);
    overflow: auto;
}

#consoleLogs .logsCount{
  padding-top: 4px;
}

#consoleLogs .browser{
	float: right;
}

#consoleLogs .browser .sprite{
	display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
}
#consoleLogs .level{
	font-weight: bold;
}
.orange{
	color: #EDAC4F;
}

#consoleLogs ul{
    list-style: none;
    padding-left: 20px;
}

#consoleLogs li p{
    padding-left: 25px;
    margin: 7px 0 20px 0;
    word-wrap: break-word;
    padding-right: 20px;
}


/*
 *
 * Loading details popup
 *
 */
 /*
#ngpopup .tabs {
	padding: 5px 0;
	margin-top: 30px;
	border-bottom: 1px solid #CFD3D7;
}

#ngpopup .tabs li {
	font-size: 1.2em;
	font-weight: bold;
	display: inline;
	list-style: none;
	padding: 5px 25px;
	color: #9AA2AB;
	cursor: pointer;
}

#ngpopup .tabs li.selected {
	color: #2B3D4F;
	border-bottom: 1px solid #fff;
	border-left: 1px solid #CFD3D7;
	border-right: 1px solid #CFD3D7;
}
*/
/*
 * COMPARISON
 *
 */
.comparisonContainer{
    padding-top: 20px;
    width: 35%;
    float:right;
}

#comparisonInput{
	width: 200px;
}

.comparisonForm{
	padding-top: 6px;
}

.comparisonContainer .comparaisonAccesTitle {
	margin-bottom: 10px;
	font-size: 15px;
}

.comparaisonAccesTitle strong {
	color: #D75553;
}

#topReportButton {
	margin-top: 60px;
}


#markChart {
	margin-top: 30px;
}

.marketingDiscountCode {
	font-size: 25px;
    color: #859099;
}

#beforeTimeline{
	font-size: 14px;
}

.downloadHar{
    text-align: right;
}

.reportDiscount {
	margin-top: 30px;
	padding-top: 15px;
	background-color: #F1F1F1;
}

.http2Beta {
	width: 960px;
	font-size: 18px;
	background-color: #fff;
}

.http2Beta.fixToTop {
    box-shadow: 0 5px 5px #999;
}

.http2Beta strong {
	color: #D75553;
}

.http2Beta button {
	font-size: 16px;
}
