[class*=' badge-'],
[class^='badge-'] {
	display: inline-block; padding: 0 .375em;
	border-radius: $default-line-height / 2 * 1em;
	font-size: .875em; font-weight: $font-weight-bold;
}

@each $class, $bg, $color in $variants-list {
	.badge-#{$class} {
		background-color: $bg;
		color: $color;
	}
}
