/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/opensans/open-sans-v13-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans'), local('OpenSans'),
       url('/assets/fonts/opensans/open-sans-v13-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/opensans/open-sans-v13-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/opensans/open-sans-v13-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/opensans/open-sans-v13-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/opensans/open-sans-v13-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('/assets/fonts/opensans/open-sans-v13-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Semibold'), local('OpenSans-Semibold'),
       url('/assets/fonts/opensans/open-sans-v13-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/opensans/open-sans-v13-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/opensans/open-sans-v13-latin-600.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/opensans/open-sans-v13-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/opensans/open-sans-v13-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('/assets/fonts/opensans/open-sans-v13-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('/assets/fonts/opensans/open-sans-v13-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/assets/fonts/opensans/open-sans-v13-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('/assets/fonts/opensans/open-sans-v13-latin-700.woff') format('woff'), /* Modern Browsers */
       url('/assets/fonts/opensans/open-sans-v13-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/assets/fonts/opensans/open-sans-v13-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

html, body {
	background-color:#fff;
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
	font-size:14px;
}

/*TODO rajouter des styles de focus*/
select:focus, input:focus {
	outline:none;
}

a img {
	border:0;
	text-decoration: none;
}

a{
	color: #2ba6cb;
	text-decoration: none;
}

a:hover{
	text-decoration: underline;
}

.width5left {width: 5%; float:left;}
.width6left {width: 6%; float:left;}
.width10left {width: 10%; float:left;}
.width15left {width: 15%; float:left;}
.width17left {width: 17%; float:left;}
.width16right {width: 16%; float:right;}
.width19left {width: 19%; float:left;}
.width20left {width: 20%; float:left;}
.width20right {width: 20%; float:right;}
.width22left {width: 22%; float:left;}
.width22right {width: 22%; float:right;}
.width25left {width: 25%; float:left;}
.width25right {width: 25%; float:right;}
.width28left {width: 28%; float:left;}
.width30left {width: 30%; float:left;}
.width30right {width: 30%; float:right;}
.width33left {width: 33.33%; float:left;}
.width33right {width: 33.33%; float:right;}
.width35left {width: 35%; float:left;}
.width35right {width: 35%; float:right;}
.width36left {width: 36%; float:left;}
.width38left {width: 38%; float:left;}
.width40left {width: 40%; float:left;}
.width40right {width: 40%; float:right;}
.width45left {width: 45%; float:left;}
.width46left {width: 46%; float:left;}
.width46right {width: 46%; float:right;}
.width48left {width: 48%; float:left;}
.width48right {width: 48%; float:right;}
.width49left {width: 49%; float:left;}
.width49right {width: 49%; float:right;}
.width50right {width: 50%; float:right;}
.width50left {width: 50%; float:left;}
.width55left {width: 55%; float:left;}
.width55right {width: 55%; float:right;}
.width60left {width: 60%; float:left;}
.width60right {width: 60%; float:right;}
.width62left {width: 60%; float:left;}
.width65left {width: 65%; float:left;}
.width67left {width: 67%; float:left;}
.width67right {width: 67%; float:right;}
.width70left {width: 70%; float:left;}
.width70right {width: 70%; float:right;}
.width75left {width: 75%; float:left;}
.width77left {width: 77%; float:left;}
.width80left {width: 80%; float:left;}
.width85left {width: 85%; float:left;}
.width80middle {width: 80%; margin:auto;}
.width90left {width: 90%; float:left;}
.width92right {width: 92%; float:right;}
.width95left {width: 95%; float:left;}

.left{text-align:left;}
.right{text-align:right;}
.center{text-align:center;}
.middle{vertical-align:middle;}
.justify{text-align:justify;}
.floatleft{float: left;}
.floatRight{float: right;}
.small{font-size:12px;}

.underline{text-decoration: underline;}

.inline { display: inline;}
.flex { display: flex;}

.pointer{ cursor: pointer;}

.nowrap {white-space: nowrap;}

.hidden {visibility: hidden;}

h2 {
	color: #2b3d4f;
	font-size: 24px;
	padding: 0;
	margin: 40px 0 0 0;
}

h3 {
	padding:0;
	margin:0;
	color: #999;
	font-weight: normal;
	font-size: 16px;
}

.mainContainer {
	width:960px;
	margin:auto;
	padding-left:10px;
	padding-right: 10px;
}

.largeContainer {
	width:90%;
	margin:auto;
}

.overflowEllipsis {
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
}

/* To be remove when grid compatibility is no longer a problème */
.not-grid-compatible .footer-first section {
	width: 24%;
	min-width: 220px;
	display: inline-block;
}

#user {
	text-align: right;
	float: right;
    margin-top: 20px;
}

#user .dropdown-menu{
	text-align: left;
	min-width: 120px;
}

#user .dropdown-menu>li>a:hover{
	background-color: white;
	background-image: none;
}

#accountDropdown{
	display: inline;
	font-weight: normal;
}
/*
#dashboard {
	font-weight: bold;
}

#login:hover, #dashboard:hover {
	background-color: #ccc;
	color:  #fff;
}

#login,#dashboard{
	color: #777;
	padding: 9px 8px 9px 8px;
}
*/
#login{
	margin-right: 6px;
	text-decoration: underline;
}

.button {
	border-radius: 5px;
	padding: 8px 16px 8px 16px;
	line-height: 1.4;
	border: 1px solid transparent;
}

.button .ion {
	line-height: 1;
}

.button.smallButton {
	border-width: 1px;
	padding: 7px 15px;
	font-weight: normal;
}

.analyzeButton {
	background-color: #3299dc;
	color: #fff;
}

.analyzeButton:hover {
	background-color: #51A9DB;
}

.lightButton{
	background-color: #9ca6b2;
	color: #fff;
	display: inline-block;
}
.lightButton:hover{
	background-color: #aabbcd;
}

.validateButton {
	font-weight: bold;
	border: 1px solid #397fba;
	color: #fff;
	background-color: #3397db;
}

.redAction, .redColor {
	color: #d75553
}
.redAction:hover {
	color: #e06966;
	cursor:pointer;
}

.redButton {
	background-color: #d75553;
	color:  #fff;
	border: 1px solid #d2413f;
	display: inline-block;
}

.redButton:hover {
	background-color: #e06966;
}

.blueButton {
	background-color: #2ba6cb;
	color:  #fff;
	border: 1px solid #2ba6cb;
	display: inline-block;
}

.blueButton:hover {
	background-color: #48bdde;
	text-decoration: none;
}

.darkBlueButton {
	color: #fff;
	display: inline-block;
	background-color: #34465e;
	border: 1px solid #34465e;
}

.darkBlueButton:hover, .appMenu a.darkBlueButton:hover {
	background-color: #4E6273;
    color: #fff;
}

.emptyBlueButton, .emptyWhiteButton, .emptyGreyButton {
	display: inline-block;
	padding: 9px 35px;
	font-weight: 600;
}

.emptyBlueButton {
	color: #2B3D4F;
	border: 2px solid #2B3D4F;
}

.emptyBlueButton:hover, #totalTipsCountContainer:hover ~ .emptyBlueButton {
	color: #FFF;
	background-color: #2B3D4F;
}

.emptyWhiteButton {
	color: #FFF;
	border: 2px solid #FFF;
}

.emptyWhiteButton:hover {
	color: #2B3D4F;
	background-color: #FFF;
}

.emptyGreyButton {
	color: #ADBBBD;
	border: 2px solid #ADBBBD;
}

.emptyGreyButton:hover {
	color: #2B3D4F;
	background-color: #ADBBBD;
}

.greyButton {
	background-color: #7f8b8d;
	color:  #fff;
	display: inline-block;
}

.greyButton:hover {
	background-color: #33495d;
}

.sunButton {
	background-color: #F1C40F;
	color:  #fff;
	border: 1px solid #F1C40F;
	display: inline-block;
}

.sunButton:hover {
	background-color: #F8CF29;
	border: 1px solid #F8CF29;
}

.greenColor {color:#27ae60;}

.greenButton {
	background-color:#27ae60;
	border-color:#27ae60;
	color:#fff;
	font-weight:bold;
}

.greenButton:hover {
	background-color:#2ecc71;
	text-decoration:none;
}

.ctaButton {
	background-color: #f39b11;
	font-weight: bold;
	color: #fff;
	display: inline-block;
}

.ctaButtonDisabled{
	background-color: rgba(247, 183, 20, 0.45);
	font-weight: bold;
	color: #fff;
}

.ctaButtonDisabled:hover{
	cursor: default;
}

.ctaButton:hover {
	background-color: #f3ae41;
}

input[type=submit].ctaButton {
    border-color: #f39b11;
}

a.button {
	text-decoration: none;
}

.button:hover {
	cursor: pointer;
}

.button:active {
	position:relative;
	top:1px;
}

.button.disabledReadOnly:active, .disabledReadOnly .button:active {
	position:initial;
	top:0;
}

.dropdown a:hover {
	cursor:pointer;
}

a.dropdown-toggle {
	color: #333;
	cursor: pointer;
	display: block;
	font-weight: bold;
	height: 30px;
	padding: 10px;
}

.clear {
	clear: both;
	border: 0;
	height: 0;
}

.clearBoth {
    clear: both;
}

.noMargin{
	margin:0;
}

.contrastColorArea {
	background-color:#b1c2c4;
	border-top:2px solid #b3b3b3;
	border-bottom:2px solid #b3b3b3;
}

.grayMsg, .orangeMsg {
	padding: 8px 0;
	width: 100%;
	margin-top: -5px;
}
.grayMsg {
	color: #2B3D4F;
	background-color: #F1F1F1;
}

.orangeMsg {
	color: #AA5800;
	background-color: #FFEDA6;
}

.orangeMsg a, .grayMsg a {
	color: #2BA6CB;
	font-weight: bold;
	padding: 7px 20px;
	display: inline-block;
	text-decoration: underline;
}

.warningQuotaReached a {
	color: #2C3E50;
	padding: 0;
}

.warningQuotaReached p {
	font-size: 16px;
	padding: 5px 0;
}

.warningQuotaReached .smallText {
	font-size: 11px;
}

.warningQuotaReached .smallText a {
	color: #AA5800;
}

.orangeMsg.unavailableMsg {
    top: 0;
    z-index: 10000;
    position: fixed;
}

.orangeMsg a.button {
	color: #fff;
	margin-left: 12px;
	font-size: 15px;
	text-decoration: none;
}

.signupPopup input[type=password]::-ms-reveal, .signupPopup input[type=password]::-ms-clear{
    display: none;
}

.signupPopup #showHidebuttonIcons{
    position: absolute;
    right: 15px;
    top: 33px;
    cursor: pointer;
    font-size: 18px;
}

.field {
    text-align: left;
    position: relative;
	margin-bottom: 1.25em;
}

/*.login-fields label {
	display: none;
}*/

.login-fields input, .field input {
	font-family: 'Open Sans';
	padding: 8px 15px 8px 50px;
	width: 80%;
	display: block;
	margin: 0;
	height: 20px;
}

.cgfield{
	margin-top: 40px;
}

.cgfield input[type="checkbox"]{
	width:auto !important;
	height: auto !important;
	margin: 10px 0 0 0;
	padding:0;
	cursor: pointer;
}

.login-fields .cgfield label{
	display:inline !important;
	color: black;
	font-weight: normal;
	cursor:pointer;
	padding-left: 10px;
}

input.action {
	float: right;
}

#ngpopup .actions {
	float: right;
}

.login-actions, .signup-actions, .signup-actions-validated {
	margin-top: 5px;
	margin-bottom: 1.25em;
	margin-right: 30px;
}

.scroll{
	overflow:auto;
	max-height: 400px;
}

hr.clear {
	visibility: hidden;
	clear: both;
	border: 0;
	height: 0;
}

hr.clear.noMargin {
	margin: 0;
}

hr.separator {
	margin: 20px auto;
	width: 70%;
	border-bottom: solid 2px #EAEAEA;
	border-top: none;
}

[ng\:cloak], [ng-cloak], .ng-cloak, .ng-hide:not(.ng-hide-animate) {
    display: none !important;
}

code, pre {
    border-radius: 3px;
    color: #333333;
    font-family: Monaco,Menlo,Consolas,"Courier New",monospace;
    font-size: 12px;
    padding: 0 3px 2px;
}

code {
    background-color: #e8e8e8;
    color: #DD1144;
    padding: 2px 6px;
	display: inline-block;
}

pre {
    background-color: #f1f1f1;
    border-radius: 4px;
    display: block;
    font-size: 13px;
    line-height: 20px;
    margin: 0 0 10px;
    padding: 9.5px;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
}

pre.preExample {
    position: relative;
	background-color: #e6eff8;
}

pre.preExample:before {
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 4px;
    padding: 1px 4px;
    background-color: #9dc1e4;
    color: #ffffff;
}

pre.preExampleFR:before {
    content: "Exemple";
}

pre.preExampleEN:before {
    content: "Example";
}

.danger {
    background-color: #F6CECE;
}

.prettyButton{
	background-color: #7f8b8d;
	padding: 8px 16px 8px 16px;
	color: #fff;
	display: inline-block;
}
.prettyButton:hover{
	background-color: #33495d;
}

/*    DROPDOWN*/

/*!
 * Bootstrap v2.3.2
 *
 * Copyright 2013 Twitter, Inc
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Designed and built with all the love in the world @twitter by @mdo and @fat.
 */
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}
.clearfix:after {
  clear: both;
}
.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  box-sizing: border-box;
}
.dropup,
.dropdown {
  position: relative;
  float: right;
}
.dropdown-toggle {
  *margin-bottom: -3px;
}
.dropdown-toggle:active,
.open .dropdown-toggle {
  outline: 0;
}
/*
.caret {
  display: inline-block;
  width: 0;
  height: 0;
  vertical-align: top;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
}
*/
.dropdown .caret {
  margin-top: 8px;
  margin-left: 2px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 0;
  list-style: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  *border-right-width: 2px;
  *border-bottom-width: 2px;
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box;
}

.dropdown-lang.dropdown-menu {
/*	position: fixed;
	top: auto;
	left: auto;*/
	min-width: 60px;
}

.dropdown-lang.dropdown-menu li {
	padding: 1px 5px 0 4px;
	color: #777;
	cursor: pointer;
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}
.dropdown-menu .divider {
  *width: 100%;
  height: 1px;
  margin: 9px 1px;
  *margin: -5px 0 5px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #ffffff;
}
.dropdown-menu > li {
	cursor: pointer;
}

.dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 20px;
  color: #333333;
  white-space: nowrap;
  text-decoration: none;
}
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus,
.dropdown-submenu:hover > a,
.dropdown-submenu:focus > a {
  text-decoration: none;
  color: #ffffff;
  background-color: #0081c2;
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  color: #ffffff;
  text-decoration: none;
  outline: 0;
  background-color: #0081c2;
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x;
}
.dropdown-menu > .disabled > a,
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  color: #999999;
}
.dropdown-menu > .disabled > a:hover,
.dropdown-menu > .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  cursor: default;
}
.open {
  *z-index: 1000;
}
.open > .dropdown-menu {
  display: block;
}

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}
.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid #000000;
  content: "";
}
.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}
.dropdown-submenu {
  position: relative;
}
.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: -1px;
  border-radius: 0 6px 6px 6px;
}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
  border-radius: 5px 5px 5px 0;
}
.dropdown-submenu > a:after {
  display: block;
  content: " ";
  float: right;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  border-left-color: #cccccc;
  margin-top: 5px;
  margin-right: -10px;
}
.dropdown-submenu:hover > a:after {
  border-left-color: #ffffff;
}
.dropdown-submenu.pull-left {
  float: none;
}
.dropdown-submenu.pull-left > .dropdown-menu {
  left: -100%;
  margin-left: 10px;
  border-radius: 6px 0 6px 6px;
}
.dropdown .dropdown-menu .nav-header {
  padding-left: 20px;
  padding-right: 20px;
}
.typeahead {
  z-index: 1051;
  margin-top: 2px;
  border-radius: 4px;
}

#dropdown-menu a{
	color: #777;
}

#dropdown-menu a:hover{
	color: #aaa;
}


.circle {
    border-radius: 50%;
}

.red-text {
	color: red;
}
/*
.prettyBox {
	text-align: center;
	position: relative;
	transition: all 0.4s ease-in-out 0s;
    border: 2px solid #BCC3C6;
    border-radius: 10px;
    color: #33485C;
 }


.prettyBox .buttonContainer {
	margin-bottom: 30px;
	margin-top: 35px;
}

.prettyBox p, .prettyBox ul {
	margin-left: 10px;
	margin-right: 10px;
}

.prettyBox:hover {
	border: solid 2px #009DDD;
}

.prettyBox a {
	text-decoration: none;
}

.prettyBox .priceAndVat, .prettyBox .offerTitle {
	margin-top:8px;
	padding-bottom:15px;
	border-bottom: solid 1px #bcc3c6;
}

.prettyBox ul {
	list-style: none;
	text-align: left;
	padding-left: 15px;
	margin-bottom: 25px;
}

.prettyBox .priceAndVat span, .prettyBox .offerTitle span{
	display:inline !important;
}

.prettyBox .offerTitle{
	color:#989898;
	font-size: 24px;
	margin-top: 10px !important;
}

.prettyBox .priceAndVat{
	font-size: 16px;
}

.prettyBox .price{
	font-size:24px;
	font-weight:bold;
}

.prettyBox .euro{
	font-size:16px;
	vertical-align: top;
	font-weight:bold;
}
*/
.vat{
	font-size:13px;
	color:#989898;
}
/*
.prettyBox .bold{
	font-weight:bold;
}

.prettyBox .fui {
	margin-right: 10px;
}

.prettyBox h3 {
	margin-top: 20px;
	margin-bottom: 23px;
	color:#989898;
}
*/
#highlighted {
	position: absolute;
    right: -19px;
    top: 22px;
}

input, textarea, select {
	font-size: 13px;
	border: 1px solid rgb(186, 186, 186);
	border-radius: 5px;
	box-shadow: none;
	padding: 8px;
	color: #383838;
	background-color: #fff;
}

input[type=submit] {
	-webkit-appearance: none;
}

input:disabled, textarea:disabled, select:disabled {
	color: #C0C0C0;
	background-color: #EBEBE4;
	border-color: #E0E0E0;
}

label {
	color: #333333;
	font-weight: normal;
	font-size: 14px;
}

.blur {
	color: transparent;
	text-shadow: 0px 0px 5px #000;
}

#upsellFeaturesList {
	list-style: inherit;
}

input.password {
	-webkit-text-security: disc;
}

#adminStatsForm th, #adminStatsForm td {
    border: 1px solid;
    text-align: center;
}

#ngpopup.scenarioEnded{
	width: 500px;
}

#ngpopup.performanceTimings {
	width: 900px;
}

.overviewContainer{
	background-color: #ecf0f1;
	padding-bottom: 20px;
	margin-bottom: 20px;
}

.overviewFirstPart, .overviewSecondPart, .overviewThirdPart{
	display: inline-block;
	text-align:center;
	margin-left: 90px;
	font-size: 18px;
	padding-top: 13px;
}

.overviewTiming{
	font-size: 22px;
	font-weight: bold;
}

#overviewTitle{
	position: relative;
	top: -15px;
	left: 30px;
	font-weight:bold;
	color: #516579;
	font-size: 20px;
	display: inline-block;
}

.fixToTop {
	position: fixed;
	z-index: 1000;
	top: 0;
}

.popupFilterContainer, #eventsAndAlertsFilter .filterContainer{
    background-color: #ecf0f1;
    padding: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
}

img.appScreen {
	padding: 5px;
    margin: 0 45px 45px;
    border-radius: 5px;
    background-color: #FFF;
    border: 1px solid #E0E0E0;
}

img.grayscale {
    filter: grayscale(100%);
	opacity: .4;
}

img.grayscale:HOVER {
    filter: grayscale(0);
	opacity: 1;
}

.tableTooltipConfig{
	text-align: left;
	padding-top: 7px;
	border-collapse: collapse;
    table-layout: fixed;
    width: 550px;
}

.tableTooltipConfig td{
	color: #fff;
	font-size: 14px;
    vertical-align: top;
    padding-bottom: 7px;
}

.tableTooltipConfig tr td:nth-child(2n+1){
    padding: 0 7px;
	width: 115px;
}

.goodColor {
	color: #76c956;
}

.warningColor {
	color: #edac4f;
}

.criticalColor {
	color: #eb5d5c;
}

#tokenError, #tokenError .field {
	text-align: center;
}

#tokenError span.fui-field-icon{
    left: 403px;
}

.readOnly .isdisabledReadOnly, .readOnly .isdisabledReadOnly *, .readOnly .isdisabledReadOnly,  .readOnly .isdisabledReadOnly *  {
    color: #BBB !important;
    cursor: default !important;
    text-decoration: none !important;
}

.auditButton.isdisabledReadOnly, .emptybutton.isdisabledReadOnly {
    border-color: #DDD !important;
}

.readOnly .isdisabledReadOnly.disableReadOnlyBackground, .readOnly .isdisabledReadOnly.disableReadOnlyBackground, .readOnly .isdisabledReadOnly.disableReadOnlyBackground:hover, .readOnly .disabledReadOnly.disableReadOnlyBackground:hover{
	background-color: #DDD !important;
	border: none !important;
}

.readOnly .isdisabledReadOnly:hover, .readOnly .isdisabledReadOnly *:hover, .readOnly .isdisabledReadOnly:hover,  .readOnly .isdisabledReadOnly *:hover  {
	background-color: initial !important;
}

.pointerEventsNone {
	pointer-events: none;
}

.readOnly .hideReadOnly {
    display: none !important;
}

#consoleLogs .counter, #errorConsole .counter {
    font-size: 12px;
    font-weight: bold;
    margin: 1px 5px 0 0;
    height: 13px;
    min-width: 18px;
    text-align: center;
    padding: 1px 2px 4px 2px;
    vertical-align: top;
    display: inline-block;
    border-radius: 29px;
    color: #fff;
    cursor: help;
}

.totalWarnCount {
    background-color: #edac4f;
}

.totalErrorCount {
    background-color: #D75553;
}

.totalOtherCount{
	background-color: #777;
}

.customIonStyle {
	padding: 0;
	list-style: none;
	font-size: 18px;
	color: #555;
    margin-top: 40px;
	margin-bottom: 10px;
}

.customIonStyle li {
	margin-bottom: 16px;
}

.customIonStyle .ion {
	font-size: 22px;
    color: #2BA6CB;
}

#formContainerSignupHome .offerButtonContainer, .signupPopup .offerButtonContainer {
	margin-top: 40px;
}

/*
    custom tooltip used by donuts graph
*/
.customTooltip{
    z-index:3000;
    position: absolute;
    border-radius: 5px 5px 5px 0;
    visibility: hidden;
    padding:10px;
    background-color: white;
    border: 1px solid;
    font-size: 12px;
    color: #2B3D4F;
    text-align:left;
    min-width: 100px;
}

.customTooltipDetails{
    display: block;
    margin-left: 20px;
}

.legendModal {
	margin-left: 40px;
}
