.timeline {
	&-container {
		min-height: 35em;
	}
}

.request-map-container {
    height: 85vh;
}

.request-map-actions {
    position: fixed;
    bottom: 0;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #abb4be;
}

/* request map tooltips */
.tippy-box {
    opacity: 0.9;
    max-width: 800px;
    padding: 10px 20px;
    color: #fff;
    text-decoration: none;
    background-color: #333;
    border-radius: 4px;
    word-wrap:break-word;
}
.tippy-box a {
    color: #B7D0F5;
}

.tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
}
.tipy-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #333;
}

