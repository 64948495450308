.help-point {
	display: inline-block; width: 1.25em; height: 1.25em;
	border-radius: 50%;
	background-color: $softgrey;
	text-align: center; color: $white; font-style: normal; font-weight: $font-weight-bold; line-height: 1.25; text-decoration: none;
	vertical-align: middle;

	&:hover,
	&:focus {
		background-color: $focus-color;
	}

	svg {
		font-size: .75em;
	}
}

a.help-point {
	cursor: pointer;
}
