.front-title {
	border: none;
	background: none;
	text-align: center; font-family: "Roboto Condensed"; font-weight: normal;

	&-part1 {
		display: block;
		font-size: 3.125em; font-weight: inherit;
	}
	&-part2 {
		display: block;
		font-size: 2em;
	}

	a {
		color: inherit;
	}
}

.front-analysis-form {
	margin: 10% 0 5%;

	&.is-home {
		margin: 0;
		padding: 7.5em 0; // Trying to have a similar height with .home-top-signup
	}

	.analysis-form-url {
		border-color: $white;
	}

	.analysis-form-url,
	.analysis-form-url-icon {
		background-color: rgba($darkblue, .25);
	}

	.analysis-form-url-input {
		background-color: transparent;
		transition: none; // Avoid weird behaviour WUI-997
	}

	.analysis-form-url-icon,
	.analysis-form-url-input {
		color: $white;
	}

	.analysis-form-url-button {
		border-color: $white;
		background-color: $white;
		color: $darkblue;
		transition: 0s linear,
			background-color $default-duration $default-timing-function,
			color $default-duration $default-timing-function; // Avoid weird behaviour WUI-997

		&:hover,
		&:focus {
			background-color: $focus-color;
			color: $white;
		}

		&.rwd-under-small {
			width: 100%; margin-top: $global-gutters;
		}
	}
}

.compare-analysis-form {
	position: relative;

	&::after {
		position: absolute; top: 50%; left: 50%;
		transform: translate(-50%, -50%);
		font-size: 7.5em; font-weight: $font-weight-bold; color: $white;
		content: "VS"; opacity: .25; pointer-events: none;
	}
}

.about-us-gallery {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: $global-gutters; grid-auto-flow: row dense; justify-items: stretch; align-items: stretch;

	@include media('>small') {
		grid-template-columns: 1fr 1fr;
	}
}

.email-validation-form {
	max-width: 25em; margin: 0 auto;
}

.faq {
	&-menu {
		ul {
			@extend %ul-reset;
			margin-top: 1em;
		}

		li {
			margin: .5em 0;
		}
	}

	&-content {
		display: flex; margin: 0 #{$global-gutters / 2 * -1};
		flex-flow: row nowrap; justify-content: center; align-items: flex-start;

		& > * {
			margin: 0 #{$global-gutters / 2};
		}

		&-menu {
			@include media('<=medium') {
				display: none;
			}
			@include media('>medium') {
				position: sticky; top: $global-gutters; bottom: $global-gutters;
				padding: $global-gutters;
				flex: 0 0 31%;
				background: $softwhite;

				&-item {
					display: flex; padding: .5em 0;
					flex-flow: row wrap; justify-content: space-between; align-items: baseline;
					text-decoration: none;

					&-label {
						color: $default-color;
					}

					&-count {
						margin-left: auto;
						text-decoration: underline;
					}

					&:hover,
					&:focus {
						.faq-content-menu-item-count {
							text-decoration: none;
						}
					}
				}
			}
		}

		&-container {
			flex: 1 1 auto;

			section {
				margin: 10% 0;

				&:first-of-type {
					margin-top: 0;
				}
			}
		}
	}

	&-return-to-top {
		position: sticky; bottom: $global-gutters;
		display: block; width: 1em; height: 1em; margin: 0 $global-gutters 0 auto; padding: .25em;
		border-radius: 50%;
		background-color: $softgrey;
		font-size: 2em; color: $white; text-align: center;

		&:hover,
		&:focus {
			background-color: $focus-color;
		}

		svg {
			display: block;
		}
	}
}

.press {
	& > * {
		align-content: center;
	}
}
.partner-item {
	display: flex;
	flex-flow: column nowrap; justify-content: flex-start; align-content: stretch;

	.misc-item-title {
		justify-content: center;
	}

	&-icon {
		font-size: 3.75em;
	}

	&-desc {
		flex: 1 0 auto;
	}
}

.home-top {
	.dareboost-sunset-banner {
		border-radius: $global-border-radius; box-shadow: 0 0 10em rgba($darkblue, .1);
		background: rgba($white, .2);
	}
}
