[data-tooltip],
[data-tooltip-html-unsafe] {
	pointer-events: auto !important; cursor: help;
}

.tooltip {
	position: absolute; z-index: $zindex-tooltip;
	display: block;
	font-size: $tooltip-font-size; line-height: $default-line-height; font-style: normal;
	visibility: visible; opacity: 0; pointer-events: none;
	transition: opacity $default-duration $default-timing-function,
	            transform $default-duration * 2 ceaser(easeOutBack);

	&.top,
	&.top-left,
	&.top-right {
		padding-bottom: $tooltip-arrow-width;
	}
	&.right {
		padding-left: $tooltip-arrow-width;
	}
	&.bottom,
	&.bottom-left,
	&.bottom-right {
		padding-top: $tooltip-arrow-width;
	}
	&.left {
		padding-right: $tooltip-arrow-width;
	}

	&.in {
		opacity: 1;
	}

	&.in.top,
	&.in.top-left,
	&.in.top-right {
		transform: translateY(-$tooltip-offset);
	}
	&.in.right {
		transform: translateX($tooltip-offset);
	}
	&.in.bottom,
	&.in.bottom-left,
	&.in.bottom-right {
		transform: translateY($tooltip-offset);
	}
	&.in.left {
		transform: translateX(-$tooltip-offset);
	}

	&-inner {
		max-width: $tooltip-max-width; padding: $tooltip-padding;
		border-radius: $global-border-radius;
		background-color: $tooltip-bg;
		color: $tooltip-color; text-align: center; text-decoration: none; word-wrap: break-word; white-space: normal;
	}

	.tooltip-arrow {
		position: absolute;
		width: 0; height: 0;
		border-color: transparent; border-style: solid;
	}

	&.top .tooltip-arrow {
		bottom: 0; left: 50%;
		margin-left: -$tooltip-arrow-width;
		border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
		border-top-color: $tooltip-bg;
	}

	&.top-left .tooltip-arrow {
		bottom: 0; left: $tooltip-arrow-width;
		border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
		border-top-color: $tooltip-bg;
	}
	&.top-right .tooltip-arrow {
		bottom: 0; right: $tooltip-arrow-width;
		border-width: $tooltip-arrow-width $tooltip-arrow-width 0;
		border-top-color: $tooltip-bg;
	}
	&.right .tooltip-arrow {
		top: 50%; left: 0;
		margin-top: -$tooltip-arrow-width;
		border-width: $tooltip-arrow-width $tooltip-arrow-width $tooltip-arrow-width 0;
		border-right-color: $tooltip-bg;
	}
	&.left .tooltip-arrow {
		top: 50%; right: 0;
		margin-top: -$tooltip-arrow-width;
		border-width: $tooltip-arrow-width 0 $tooltip-arrow-width $tooltip-arrow-width;
		border-left-color: $tooltip-bg;
	}
	&.bottom .tooltip-arrow {
		top: 0; left: 50%;
		margin-left: -$tooltip-arrow-width;
		border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
		border-bottom-color: $tooltip-bg;
	}
	&.bottom-left .tooltip-arrow {
		top: 0; left: $tooltip-arrow-width;
		border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
		border-bottom-color: $tooltip-bg;
	}
	&.bottom-right .tooltip-arrow {
		top: 0; right: $tooltip-arrow-width;
		border-width: 0 $tooltip-arrow-width $tooltip-arrow-width;
		border-bottom-color: $tooltip-bg;
	}
}

.externalTooltip {
	position: absolute; z-index: $zindex-tooltip;
	max-width: $tooltip-max-width; padding: $global-gutters;
	border-radius: $global-border-radius;
	background-color: $tooltip-bg;
	color: $tooltip-color;
	visibility: hidden; opacity: .8; pointer-events: none;

	&.visible {
		visibility: visible;
	}

	.legendColor {
		display:inline-block; width: 1.5em; height: 1em; margin-right: .25em;
		border: 1px solid $white; border-radius: $global-border-radius / 2;
	}

	.tooltipLegendLabel {
		margin-right: 2em;
	}
}
