/*
 * http://ionicons.com/
 */
@font-face {
	font-family: "Ionicons";
	src: url("/assets/fonts/ionicons.eot");
	src:
		url("/assets/fonts/ionicons.eot#iefix")
		format("embedded-opentype"),
		url("/assets/fonts/ionicons.woff2") format("woff2"),
		url("/assets/fonts/ionicons.woff") format("woff"),
		url("/assets/fonts/ionicons.ttf") format("truetype"),
		url("/assets/fonts/ionicons.svg#Ionicons") format("svg");
	font-weight: normal;
	font-style: normal;
}

@keyframes spin { 0% { transform: rotate(0deg); }
	100% { transform: rotate(359deg); }
}

.ion-loading-a {
	animation: spin 1s infinite linear;
	animation-timing-function: steps(8, start);
}

.ion{
	display: inline-block;
	font-family: "Ionicons";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.ion.coreWebVitals { font-size: 19px; color: #b9c2c6; }

.ion.before { padding-right: 8px; }

.ion.after, .ion-after { padding-left: 8px; }

.ion-alert::before { content: "\f101"; }

.ion-alert-circled::before { content: "\f100"; }

.ion-android-clock::before { content: "\f2d6"; }

.ion-android-information::before { content: "\f2e6"; }

.ion-android-mail::before { content: "\f2eb"; }

.ion-android-note::before { content: "\f2ef"; }

.ion-android-settings::before { content: "\f2f7"; }

.ion-android-stair-drawer::before { content: "\f311"; }

.ion-arrow-down-b::before { content: "\f104"; }

.dropdown.open .ion-arrow-down-b::before { content: "\f10d"; }

.ion-arrow-down-c::before { content: "\f105"; }

.ion-arrow-left-b::before { content: "\f107"; }

.ion-arrow-right-b::before { content: "\f10a"; }

.ion-arrow-up-b::before { content: "\f10d"; }

.ion-arrow-up-c::before { content: "\f10e"; }

.ion-calendar::before { content: "\f117"; }

.ion-card::before { content: "\f119"; }

.ion-checkmark::before { content: "\f122"; }

.ion-checkmark-circled::before { content: "\f120"; }

.ion-checkmark-round::before { content: "\f121"; }

.ion-chevron-down::before { content: "\f123"; }

.ion-chevron-right::before { content: "\f125"; }

.ion-chevron-up::before { content: "\f126"; }

.ion-clipboard::before { content: "\f127"; }

.ion-close-circled::before { content: "\f128"; }

.ion-close-round::before { content: "\f129"; }

.ion-compass::before { content: "\f273"; }

.ion-document-text::before { content: "\f12e"; }

.ion-earth::before { content: "\f276"; }

.ion-edit::before { content: "\f2bf"; }

.ion-email::before { content: "\f132"; }

.ion-eye::before { content: "\f133"; }

.ion-eye-disabled::before { content: "\f306"; }

.ion-fork-repo::before { content: "\f2c0"; }

.ion-gear-b::before { content: "\f13e"; }

.ion-help::before { content: "\f143"; }

.ion-help-buoy::before { content: "\f27c"; }

.ion-help-circled::before { content: "\f142"; }

.ion-home::before { content: "\f144"; }

.ion-information-circled::before { content: "\f149"; }

.ion-ios7-albums::before { content: "\f14f"; }

.ion-ios7-arrow-back::before { content: "\f150"; }

.ion-ios7-arrow-down::before { content: "\f151"; }

.ion-ios7-arrow-forward::before { content: "\f152"; }

.ion-ios7-bell::before { content: "\f159"; }

.ion-ios7-bell-outline::before { content: "\f158"; }

.ion-ios7-box::before { content: "\f15f"; }

.ion-ios7-box-outline::before { content: "\f15e"; }

.ion-ios7-cart::before { content: "\f167"; }

.ion-ios7-checkmark::before { content: "\f16e"; }

.ion-ios7-circle-filled::before { content: "\f16f"; }

.ion-ios7-circle-outline::before { content: "\f170"; }

.ion-ios7-close::before { content: "\f2bc"; }

.ion-ios7-close-outline::before { content: "\f2bb"; }

.ion-ios7-cloud-download-outline::before { content: "\f173"; }

.ion-ios7-cloud-upload::before { content: "\f177"; }

.ion-ios7-copy-outline::before { content: "\f181"; }

.ion-ios7-email::before { content: "\f187"; }

.ion-ios7-email-outline::before { content: "\f186"; }

.ion-ios7-film-outline::before { content: "\f18e"; }

.ion-ios7-help-outline::before { content: "\f19b"; }

.ion-ios7-infinite::before { content: "\f19e"; }

.ion-ios7-person::before { content: "\f1c2"; }

.ion-ios7-photos-outline::before { content: "\f1c5"; }

.ion-ios7-play::before { content: "\f1c8"; }

.ion-ios7-plus-outline::before { content: "\f1ca"; }

.ion-ios7-pricetag-outline::before { content: "\f28c"; }

.ion-ios7-pulse::before { content: "\f335"; }

.ion-ios7-pulse-strong::before { content: "\f334"; }

.ion-ios7-search::before { content: "\f1da"; }

.ion-ios7-search-strong::before { content: "\f1d9"; }

.ion-ios7-star::before { content: "\f1e0"; }

.ion-ios7-star-outline::before { content: "\f1df"; }

.ion-ios7-stopwatch-outline::before { content: "\f1e1"; }

.ion-ios7-telephone::before { content: "\f1e6"; }

.ion-ios7-upload::before { content: "\f1f2"; }

.ion-key::before { content: "\f296"; }

.ion-load-a::before, .ion-loading-a::before { content: "\f29a"; }

.ion-location::before { content: "\f1ff"; }

.ion-locked::before { content: "\f200"; }

.ion-log-out::before { content: "\f29f"; }

.ion-man::before { content: "\f202"; }

.ion-minus::before { content: "\f209"; }

.ion-navicon-round::before { content: "\f20d"; }

.ion-pause::before { content: "\f210"; }

.ion-person::before { content: "\f213"; }

.ion-person-stalker::before { content: "\f212"; }

.ion-pie-graph::before { content: "\f2a5"; }

.ion-pinpoint::before { content: "\f2a7"; }

.ion-play::before { content: "\f215"; }

.ion-plus-round::before { content: "\f217"; }

.ion-podium::before { content: "\f344"; }

.ion-power::before { content: "\f2a9"; }

.ion-pricetag::before { content: "\f2aa"; }

.ion-record::before { content: "\f21b"; }

.ion-refresh::before { content: "\f21c"; }

.ion-ribbon-b::before { content: "\f349"; }

.ion-search::before { content: "\f21f"; }

.ion-settings::before { content: "\f2ad"; }

.ion-share::before { content: "\f220"; }

.ion-social-facebook::before { content: "\f231"; }

.ion-icon-social-google-plus::before { content: "\f146"; }

.ion-shuffle::before { content: "\f221"; }

.ion-social-rss::before { content: "\f23d"; }

.ion-social-twitter::before { content: "\f243"; }

.ion-social-youtube-outline::before { content: "\f24c"; }

.ion-star::before { content: "\f24e"; }

.ion-stats-bars::before { content: "\f2b5"; }

.ion-stop::before { content: "\f24f"; }

.ion-trash-b::before { content: "\f253"; }

.ion-trophy::before { content: "\f356"; }

.ion-unlocked::before { content: "\f254"; }

.ion-woman::before { content: "\f25d"; }

.ion-wrench::before { content: "\f2ba"; }

.ion-ios7-clock-outline::before { content: "\f171"; }
