.sprite {
	/* if you change the name, change the preload on basicHead.scala.html */
    background-image: url(../img/sprite-25.png);
    background-repeat: no-repeat;
    display: block;
}

.sprite-browsers-chrome {
    width: 28px;
    height: 28px;
    background-position: -5px -5px;
}

.sprite-browsers-chrome-bw {
    width: 29px;
    height: 30px;
    background-position: -43px -5px;
}

.sprite-browsers-firefox {
    width: 31px;
    height: 28px;
    background-position: -82px -5px;
}

.sprite-browsers-firefox-bw {
    width: 31px;
    height: 28px;
    background-position: -123px -5px;
}

.sprite-star {
    width: 20px;
    height: 20px;
    background-position: -164px -5px;
}

.sprite-info {
    width: 15px;
    height: 15px;
    background-position: -193px -5px;
}

.sprite-location {
    width: 29px;
    height: 29px;
    background-position: -228px -5px;
}

.sprite-location-bw {
    width: 29px;
    height: 29px;
    background-position: -267px -5px;
}

.sprite-logo-footer {
    width: 120px;
    height: 22px;
    background-position: -306px -5px;
}

.sprite-report-mark-down-no-focus,
.sprite-report-mark-down {
    width: 15px;
    height: 16px;
    background-position: -436px -5px;
}

.sprite-report-mark-down-hover,
.sprite-report-mark-down:hover {
    width: 15px;
    height: 16px;
    background-position: -461px -5px;
}

.sprite-report-mark-up-no-focus,
.sprite-report-mark-up {
    width: 15px;
    height: 16px;
    background-position: -486px -5px;
}

.sprite-report-mark-up-hover,
.sprite-report-mark-up:hover {
    width: 15px;
    height: 16px;
    background-position: -511px -5px;
}

.sprite-report-tip-average,
.sprite-report-tip-warning {
    width: 16px;
    height: 16px;
    background-position: -536px -5px;
}

.sprite-report-tip-bad,
.sprite-report-tip-fail {
    width: 16px;
    height: 16px;
    background-position: -562px -5px;
}

.sprite-report-tip-good,
.sprite-report-tip-success {
    width: 16px;
    height: 16px;
    background-position: -588px -5px;
}

.sprite-report-tip-info {
    width: 16px;
    height: 16px;
    background-position: -614px -5px;
}

.sprite-tip-arrow {
	width: 32px;
	height: 24px;
	background-position: -640px -5px;
}

.spaceBetweenAdviceAndLine:hover ~ .maximizeTipHover .sprite-tip-arrow,
.minimizedTip:hover ~ .maximizeTipHover .sprite-tip-arrow,
.maximizeTipHover:hover .sprite-tip-arrow {
	width: 32px;
	height: 24px;
	background-position: -682px -5px;
}

.sprite-top {
    width: 36px;
    height: 36px;
    background-position: -724px -5px;
}

.sprite-top:hover {
    width: 36px;
    height: 36px;
    background-position: -770px -5px;
}

.sprite-top-top {
    width: 36px;
    height: 36px;
    background-position: -816px -5px;
}

.sprite-top-top:hover {
    width: 36px;
    height: 36px;
    background-position: -862px -5px;
}

/* second line */
.sprite-weight{
	width: 25px;
    height: 25px;
	background-position: -5px -35px;
}

.sprite-flag-de{
	width: 16px;
    height: 11px;
	background-position: -40px -40px;
}

.sprite-flag-au{
	width: 16px;
    height: 11px;
	background-position: -66px -40px;
}

.sprite-flag-fr {
	width: 16px;
    height: 11px;
    background-position: -92px -40px;
}

.sprite-flag-en {
	width: 16px;
    height: 11px;
    background-position: -118px -40px;
}

.sprite-pdf{
    width: 18px;
    height: 18px;
    background-position: -144px -40px;
}

.sprite-alert{
    width: 16px;
    height: 16px;
    background-position: -172px -40px;
}

.sprite-monitor{
    width: 18px;
    height: 18px;
    background-position: -198px -40px;
}

.sprite-chrono{
    width: 16px;
    height: 16px;
    background-position: -226px -40px;
}

.sprite-flag-world {
	width: 16px;
    height: 16px;
    background-position: -252px -40px;
}

.sprite-compare-icon{
	width: 26px;
	height: 18px;
	background-position: -278px -40px;
}

.sprite-screen {
	width: 18px;
    height: 16px;
    background-position: -314px -40px;
}

.sprite-graph {
	width: 18px;
	height: 18px;
	 background-position: -342px -40px;
}

.sprite-browsers-nexus,
.sprite-browsers-lg,
.sprite-browsers-htc,
.sprite-browsers-galaxy,
.sprite-browsers-iphone,
.sprite-browsers-mobile {
	width: 28px;
    height: 28px;
    background-position: -363px -35px;
}

.sprite-desktop {
	width: 28px;
    height: 28px;
    background-position: -395px -35px;
}

.sprite-mobile {
	width: 28px;
    height: 28px;
    background-position: -426px -35px;
}

.sprite-browsers-nexus-bw,
.sprite-browsers-lg-bw,
.sprite-browsers-htc-bw,
.sprite-browsers-galaxy-bw,
.sprite-browsers-iphone-bw,
.sprite-browsers-mobile-bw {
    width: 28px;
    height: 28px;
    background-position: -449px -35px;
}

.sprite-flag-hk {
    width: 16px;
    height: 11px;
    background-position: -481px -35px;
}

.sprite-flag-fr-gray {
    width: 16px;
    height: 11px;
    background-position: -507px -35px;
}

.sprite-flag-en-gray {
    width: 16px;
    height: 11px;
    background-position: -533px -35px;
}

.sprite-flag-jp {
    width: 16px;
    height: 11px;
    background-position: -559px -35px;
}

.sprite-flag-gb {
    width: 16px;
    height: 11px;
    background-position: -585px -35px;
}

.sprite-flag-in {
    width: 16px;
    height: 11px;
    background-position: -611px -35px;
}

.sprite-flag-br {
    width: 16px;
    height: 11px;
    background-position: -637px -35px;
}

.sprite-pdf-blue{
    width: 18px;
    height: 18px;
    background-position: -663px -35px;
}

.sprite-flag-nl {
    width: 16px;
    height: 11px;
    background-position: -481px -51px;
}

.sprite-flag-mx {
    width: 16px;
    height: 11px;
    background-position: -507px -51px;
}

.sprite-flag-it {
    width: 16px;
    height: 11px;
    background-position: -533px -51px;
}

.sprite-flag-ca {
    width: 16px;
    height: 11px;
    background-position: -559px -51px;
}

.sprite-flag-no {
    width: 16px;
    height: 11px;
    background-position: -585px -51px;
}

.sprite-flag-kr {
    width: 16px;
    height: 11px;
    background-position: -611px -51px;
}

.sprite-flag-sg {
    width: 16px;
    height: 11px;
    background-position: -637px -51px;
}
