.contentPage {
	font-size: 16px;
    background-color: #FCFCFC;
}

.contentPage section {
	padding: 60px 0;
}

.contentPage h1 {
	font-size: 28px;
    font-weight: 600;
	padding: 0 0 50px;
	border: none;
	background: none;
}

.contentPage h2 {
	margin: 0 0 20px;
}

.contentPage .banner p {
	width: 60%;
    float: left;
	color: #2C3E50;
    font-size: 22px;
    line-height: 1.5;
    text-align: justify;
}

.bannerCTA {
    display: block;
}

.contentPage .banner p a {
	font-size: 14px;
	margin-top: 20px;
}

.contentPage .fullBanner p {
	width: 100%;
    float: left;
	color: #2C3E50;
    font-size: 22px;
    line-height: 1.5;
    text-align: justify;
}

.contentPage .banner div {
	padding-left: 40px;
	border-left: 3px solid #e4eeed;
}

.contentPage .banner ul {
	padding-left: 20px;
}

.contentPage .childBanner p {
	color: #2C3E50;
    font-size: 22px;
    line-height: 1.5;
    text-align: justify;
}

.contentPage table {
	width: 400px;
	margin: auto;
	margin-bottom: 40px;
}

#childSubtitle {
	width: 60%;
	padding-left: 40px;
	border-left: 3px solid #e4eeed;
}


.contentPage .contentPageHightlightRow {
	background-color: #e5efee;
}

.contentPage .contentPageHightlightRow img {
	margin: 40px 0 0;
}

.contentPage .customerSuccess img {
	margin: 0px 20px;
}

.samplesContainer .customIonStyle li {
	margin-bottom: 30px;
	color: #555;
}

#vanillaPopup {
	display: none;
}

.mixedLoginSignupPopup #showHidebuttonIcons{
    position: absolute;
    right: 36px;
    top: 28px;
    cursor: pointer;
    font-size: 18px;
}

@media screen and (max-width: 1000px){
	.contentPage div, .contentPage p, .contentPage h2 {
		width: 100% !important;
		box-sizing: border-box;
    	padding: 0 10px;
	}

	.contentPage li {
		margin-bottom: 16px;
	}

	.contentPage .customIonStyle {
	    font-size: 16px;
	}

	.contentPage .samplesContainer ul {
		margin: 0 1% 0 5%;
	}

	.contentPage  #childSubtitle {
		padding-left: 0;
	}

	.contentPage  .childBanner strong {
		margin-left: 10px;
	}
}

.testProbeList {
	list-style: none;
}

.testProbeList ul {
	list-style: none;
	padding-left: 10px;
}

.testProbeList li {
	margin-bottom: 40px;
}

.testProbeList ul li {
	margin: 15px 0 15px 15px;
    color: #2c3e50;
}

.testProbeList .sprite {
	margin-top: 5px;
    margin-right: 5px;
}

.reverseNdd{
	font-style: italic;
}
