.app-header {
	display: flex; padding: $global-gutters / 2 0;
	flex-flow: row nowrap; justify-content: space-between; align-items: center;
	line-height: $nav-header-logo-height;
}

.app-header-mobile {
	background-color: $header-bg;
}

.header-profile {
	margin-left: $global-gutters;

	.header-profile-button {
		white-space: nowrap; color: inherit; text-decoration: none;

		&:hover,
		&:focus {
			color: $focus-color;
		}
	}

	.header-profile-menu {
		z-index: $zindex-header-profile;
	}

	@include media('<=small') {

		.header-profile-button {
			position: fixed; top: 0; right: 0; z-index: $zindex-header-profile;
			padding: $nav-trigger-padding;
			color: $header-color; text-decoration: none;
		}

		.header-profile-menu {
			position: fixed; top: 4em; right: 0;
    		border: 0;
    		border-radius: 0;
		}
	}

	.header-profile-icon {
		vertical-align: text-bottom;
	}

	.header-profile-menu-item-lang {
		padding: .375em .5em;
	}
}

.app-nav-trigger {
	@extend .btn--reset;
	padding: $nav-trigger-padding; padding-left: 0;

	&::after {
		position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: $zindex-nav-trigger;
		background-color: rgba($darkblue, .5);
		content: ''; opacity: 0; visibility: hidden; pointer-events: none;
		transition: $nav-toggle-duration ceaser(easeOutCirc);
	}

	&.is-active {
		&::after {
			opacity: 1; visibility: visible; pointer-events: auto;
		}
	}

	&-icon {
		display: block; width: auto; height: $nav-trigger-height;
	}
}
