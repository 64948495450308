[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
.ng-cloak,
.ng-hide:not(.ng-hide-animate) {
	display: none !important;
}

.cursor-help {
	cursor: help;
}

.overflow {
	overflow: auto;
}

.is-sticky-top {
	position: sticky; top: 0; z-index: $zindex-sticky-top;
}

.pad-1 {
	padding: 1em;
}

.pad-v {
	padding-top: $global-gutters; padding-bottom: $global-gutters;
}

.marg-v {
	margin-top: $global-gutters; margin-bottom: $global-gutters;
}

.marg-h {
	margin-left: $global-gutters/2; margin-right: $global-gutters/2;
}

.marg-auto {
	margin: auto;
}

.is-flex {
	display: flex;
	flex-flow: column nowrap; align-items: stretch;
}

.is-full-height {
	flex: 1 0 auto;
}
.logged-out .is-full-height {
	padding: .01em 0;
}

.childs-space-between,
.childs-space-around {
	display: flex;
	flex-flow: row wrap; align-items: center;

	&.is-align-top {
		align-items: flex-start;
	}

	&.is-nowrap {
		flex-wrap: nowrap;
	}
}

.childs-space-between {
	justify-content: space-between;
}

.childs-space-around {
	justify-content: space-around;
}

.is-pushed-right {
	margin-left: auto;
}

.padding-front-frame {
	padding-top: 5%; padding-bottom: 5%;
}

.semi-padding-front-frame {
	padding-top: 2.5%; padding-bottom: 2.5%;
}

hr.print-page-break {
	height: 0; margin: 0;
	border: 0;
	visibility: hidden; page-break-after: always;
}

.border-radius {
	border-radius: $global-border-radius;
}

.bg-soft-grey {
	background-color: $softgrey;
}

.bg-soft-white {
	background-color: $softwhite;
}

.bg-white {
	background-color: $white;
}

.bg-blue-gradient {
	$blue-gradient: fixed linear-gradient(to bottom, #1b213d 0%, $blue 100%);
	border-bottom: 1rem solid $darkblue;
	background: $blue-gradient;

	&.w-rocket {
		@include media (">1200px") {
			// Don't forget to deal with cache with the file rocket.svg
			background: right 130% bottom 0 / auto 950px no-repeat fixed url('/assets/svg/rocket.svg'),
						$blue-gradient;
		}
		@include media (">1320px") {
			background-position: right 120% bottom 0, center;
		}
		@include media (">1440px") {
			background-position: right 110% bottom 0, center;
		}
		@include media (">1200px", "height>1280px") {
			background-position: right 140% bottom 0, center;
		}
		@include media (">1320px", "height>1280px") {
			background-position: right 130% bottom 0, center;
		}
		@include media (">1440px", "height>1280px") {
			background-position: right 120% bottom 0, center;
		}
	}

	&.w-rocket-mobile {
		@include media (">1200px") {
			// Don't forget to deal with cache with the file rocket.svg
			background: left -25% bottom 60% / auto 600px no-repeat fixed url('/assets/svg/rocket-mobile.svg'),
						$blue-gradient;
		}
		@include media (">1320px") {
			background-position: left -20% bottom 60%, center;
		}
		@include media (">1440px") {
			background-position: left -10% bottom 60%, center;
		}
		@include media (">1600px") {
			background-position: left -5% bottom 60%, center;
		}
		@include media (">1200px", "height>1280px") {
			background-position: left -35% bottom 60%, center;
		}
		@include media (">1320px", "height>1280px") {
			background-position: left -20% bottom 60%, center;
		}
		@include media (">1440px", "height>1280px") {
			background-position: left -10% bottom 60%, center;
		}
		@include media (">1600px", "height>1280px") {
			background-position: left -05% bottom 60%, center;
		}
	}
}

.with-bottom-border {
	@extend %hr;
}
