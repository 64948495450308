.popin {
	// Uncomment these lines when .ngpopup will be removed
	/*
	position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: $zindex-popin;
	overflow: auto;
	background-color: $popin-backdrop-color;
	*/

	padding: 0 $global-gutters; // To keep margins in responsive context

	&-frame {
		width: 100%; max-width: 35em; margin: 5% auto; overflow: hidden;
		border-radius: $global-border-radius; box-shadow: 0 0 5em $popin-box-shadow;
		background-color: $default-bg;
	}

	&-title {
		padding: $global-gutters;

		&-inner {
			font-size: 1.25em; font-weight: $font-weight-bold;
		}
	}

	@each $class, $bg, $color in $variants-list {
		&-frame.is-#{$class} &-title {
			background-color: $bg;
			color: $color;
		}
	}

	&-content {
		margin: $global-gutters;
	}

	&-control {
		margin: #{$global-gutters * 2} #{$global-gutters} #{$global-gutters};
	}

}
