.rnw-legend {
	& > * {
		margin: .5em 0;

		&::before {
			display: inline-block; width: .75em; height: .75em; margin-right: .25em;
			content: '';
		}
	}
	&-html::before   { background-color: #0D549C; }
	&-css::before    { background-color: #1274D9; }
	&-scripts::before { background-color: #6FB7FF; }
	&-images::before  { background-color: #b4daff; }
	&-others::before  { background-color: #ebf5ff; }
}
