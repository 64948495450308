.misc-item {
	&,
	&--colored {
		padding: $global-gutters;
		border-radius: $global-border-radius;
		& > :first-child {
			margin-top: 0;
		}
		& > :last-child {
			margin-bottom: 0;
		}
	}
	& {
		background-color: $default-bg;
	}
	&--colored {
		background: $colored-frame-bg;
	}
	&-title {
		display: flex; margin: 0;
		flex-flow: row nowrap; justify-content: space-between; align-items: flex-start;
		&-text {
			margin: 0 .5em 0 0;
			font-weight: $font-weight-normal; color: inherit;
			& > * {
				vertical-align: middle;
			}
		}
		[class*=' btn--'],
		[class^='btn--'] {
			&:not(.btn--link-like) {
				margin-top: $input-vpadding * -1; margin-bottom: $input-vpadding * -1;
			}
		}
	}
	&-hr {
		margin-left: -$global-gutters; margin-right: -$global-gutters;
	}

	.no-padding {
		margin: #{$default-line-height * -1em} #{-$global-gutters} 0;
	}
}
