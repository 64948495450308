.resume {
    border: solid 2px #bcc3c6;
    border-radius: 10px;
    color: #33485c;
    margin: auto;
    margin-bottom:20px;
}

.upHeader{
	width: 100%;
	margin-bottom: 20px;
	margin-left: 20px;
}

.customerAddress {
	float: right;
	width: auto;
	margin-right: 20px;
	min-width: 200px;
	margin-bottom: 30px;
}

.terms{
	white-space: pre-line;
	font-size: 12px;
}

#estimateResume {
    width:680px;
    padding: 15px;
}

.paymentTitle {
    margin-bottom:30px;
}

.paymentTitle .link{
	cursor: pointer;
}

.paymentTitle.banner{
	background-color:#fff;
}

#estimateResume .blueButton {
	border: none;
	font-weight: bold;
	font-size: 15px;
    padding: 14px 20px;
}

#estimateResume table {
    width: 100%;
    margin-bottom: 40px;
}

#estimateResume table#dateInfo {
	margin-top: 30px;
}

#estimateResume th, #estimateResume td  {
	border-bottom: 1px solid #C7C7C7;
	padding-left: 5px;
	padding-right: 5px;
}

.totalLine {
    background-color: #E3E3E3;
}

.totalLine th, .totalLine td {
    border-top: 1px solid #C7C7C7;
    line-height: normal;
    vertical-align: top;
}

#estimateResume th {
    text-align: left;
}

#estimateFields th {
    text-align: right;
}

#callbackResume td, #estimateResume td {
    text-align: right;
}

#validateDiscount {
	margin-left: 10px;
	padding: 4px 12px 5px 12px;
	background-color: #E3E3E3;
	border: 1px solid #C7C7C7;
	color: #33485C;
	font-size: 12px;
}

/*
 * Callback payment white label one shot
 */
#callbackWL {
	font-size: 16px;
}

#callbackWL h1 {
	margin: 50px 0;
}

#callbackWL h2 {
	margin: 0;
}

#callbackWL p {
	margin-top: 40px;
}

#callbackWL ul {
	padding: 10px 60px;
}

#callbackWL li {
  padding-bottom: 15px;
}

#callbackWL .subPdfConf, #callbackWL .pdfConf {
	list-style-type: none;
	margin: 0;
}

#callbackWL .subPdfConf {
	margin-left: 30px;
}

#callbackWL .subPdfConf li {
	display: inline;
	margin-right: 20px;
}

#callbackWL .subPdfConf.disabled{
    color: #ccc;
}

#callbackWL .subPdfConf li,
#callbackWL .pdfConf li{
	cursor: pointer;
}

#callbackWL .subPdfConf.disabled li{
    cursor: default;
}

#callbackWL .pdfConf .ion-checkbox{
	height: 12px;
	width: 12px;
	font-size: 16px;
	padding: 0 2px 4px;
	margin-right: 8px;
	border-radius: 5px;
	border: 2px solid #516579;
}

#callbackWL hr.separator {
	width: 90%;
	margin: 50px auto;
}

#discountEstimateContainer{
    width: 500px;
    border-radius: 10px;
    margin: 0 auto 15px;
	padding-right: 5px;
    text-align:center;
    border: 2px solid #48bdde;
    color: #33485c;
}

#discountEstimateContainer .close{
    float: right;
    display: inline-block;
}
