.donut-tooltip {
	position: fixed; z-index: 100;
	min-width: 10em; padding: .5em 1em;
	border: 1px solid $softgrey; border-radius: $global-border-radius $global-border-radius $global-border-radius 0;
	background-color: $white;
	font-size: .875em; text-align: left;
	visibility: hidden; pointer-events: none; will-change: top, left;

	&-details {
		display: block;
		margin-left: 1em;
	}
}
