.dbWaterfall {
	display: flex; height: 100%;
	flex-flow: column nowrap; justify-content: flex-start; align-items: stretch;
/*
	&TableContainer {
		flex: 1 1 auto;
	}
*/
	thead {
		position: sticky;
	}
}
