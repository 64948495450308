/* Header successor */
.headerSuccessor {
    clear: both;
    background-color: #fff;
}

.link {
    font-weight: bold;
    color: #2ba6cb;
    display: inline-block;
    font-size: 14px;
    text-decoration: underline;
}

/* Tips count */
.totalTips {
    margin: 36px 0 20px;
}

.totalTipsCountContainer {
    width: 75%;
    margin: auto;
    cursor: pointer;
}

.totalTipsCount {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    margin: auto;
    width: 68px;
    height: 51px;
    padding-top: 17px;
    -webkit-border-radius: 50%;
    -khtml-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    text-align:center;
    vertical-align: middle;
}

.totalSuccesCount {
    background-color: #9dadaf;
}

.globalResults {
    padding: 20px 0 20px;
    background-color: #2b3d4f;
    color: #fff;
}

.greyContainerLegend {
    font-size: 15px;
    font-weight: 600;
    color: #929292;
    margin: 8px 0 20px;
}

/*
 * Small Snasphot
 */

#snapshotContainer {
    min-height: 120px;
    margin-top: 50px;
    position: relative;
}

#snapshotContainer img{
    display:block;
    margin: auto;
}

.smallSnapshotContainer{
	border-right-style:solid;
    border-left-style:solid;
    margin:auto;
    background-color: grey;
}

.smallSnapshotPcContainer,.smallSnapshotMobileContainer {
    border-right-color:#2c3e50;
    border-left-color:#2c3e50;
}

.smallSnapshotPcContainer-dark,.smallSnapshotMobileContainer-dark {
    border-right-color:#000;
    border-left-color:#000;
}

.smallSnapshotPcContainer, .smallSnapshotPcContainer-dark{
    min-height:80px;
    width:180px;
    border-right-width:6px;
    border-left-width:5px;
}

#snapshotContainer.mobileSnapshotContainer{
    margin:5px 75px 0 0;
}

.smallSnapshotMobileContainer, .smallSnapshotMobileContainer-dark{
    min-height:130px;
    width:130px;
    border-right-width:2px;
    border-left-width:2px;
}

.margin1Left{
    margin-left:1px;
}


/**
*  tips
*/


.tip {
	font-size: 14px;
    margin-top: 35px;
    word-wrap:break-word;
}

.tip h4{
    color: #2b3d4f;
    font-size: 18px;
    padding: 0;
    margin:0 0 15px 0;
    min-height: 20px;
}

.tip h4 .sprite {
    margin-top:5px;
}

.tip h4 span{
    float:left;
    margin-right:10px;

}

.tip h4 span.tipTitle{
    width: 95%;
    font-weight: bold;
}

.categoryButton {
    background-color: #F1F1F1;
    font-size: 11px;
    color: #2b3d4f;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    padding: 4px 6px 4px 6px;
}

.tip .grade {
    color: #7f8b8d;
    font-size: 22px;
    font-weight: bold;
    margin: 6px 0 6px 0;
}

/*
.tip.success .grade{
    color: #27ad5f;
}

.tip.warning .grade{
    color: #EDAC4F;
}

.tip.fail .grade{
    color: #D75553;
}
*/

.maximizeTipSpan {
    margin: 0 auto -10px auto;
}

.spaceBetweenAdviceAndLine:hover ~ .tipsOverlay span, .minimizedTip:hover ~  .tipsOverlay span, .tipsOverlay:hover span {
    display:inline-block;
}

.maximizeTip{
    border-bottom: solid #e3e3e3 1px;
    position: relative;
    clear: both;
    top: -5px;
}

.spaceBetweenAdviceAndLine:hover, .maximizeTip:hover{
    cursor: pointer;
}

.maximizeTipSpan{
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #FFF;
    display: inline-block;
    padding: 0px 10px 0;
    vertical-align: middle;
}

.spaceBetweenAdviceAndLine:hover ~ .maximizeTip .maximizeTipSpan, .maximizeTip:hover .maximizeTipSpan, .minimizedTip:hover ~ .maximizeTip .maximizeTipSpan, .maximizeTipSpan:hover {
    border-color: #2B3D4F;
    cursor: pointer;
}

.minimizedTip {
    max-height: 80px;
    overflow: hidden;
}

.minimizedTip:hover{
    cursor: pointer;
}

h3.adviceSeparator {
    color: #777;
    font-weight: bold;
    font-size: 22px;
    margin-top: 20px;
    padding-bottom: 5px;
}

.readMore {
    display:none;
    color:#2ba6cb;
    position: absolute;
    font-size: 13px;
    top: -10px;
    left: 520px;
    z-index: 150;
}

.spaceBetweenAdviceAndLine:hover ~ .maximizeTip .readMore, .minimizedTip:hover ~ .maximizeTip .readMore, .maximizeTip:hover .readMore {
    display:inline-block;
}


#h2Resume{
    padding-top: 2px;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    cursor: default;
}

#h2Resume a .ion{
    font-size: 18px;
    vertical-align: middle;
    color: #fff;
}
