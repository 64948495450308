.front-quote {
	position: relative;
	overflow: hidden;

	&-icon {
		position: absolute; top: -.375em;
		margin-left: -.125em;
		font-size: 25em; color: rgba($white, .2);
	}

	&-title {
		font-size: 3em; font-family: "Roboto Condensed"; font-weight: normal;
	}
}
