html {
	font-family: $default-font-family;
	font-style: $default-font-style;
	font-weight: $default-font-weight;
	line-height: $default-line-height;
	color: $default-color;
}

html {
	font-size: .625em; /* fallback IE8+ */
	font-size: calc(1em * .625); /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
}

body {
	@include rwd-rem();
}

h1, h2, h3, h4, h5, h6, .h-like {
	font-family: $title-font-family; font-style: $title-font-style; font-weight: $title-font-weight; color: $title-color;
}

h1 {
	padding-top: 0; padding-bottom: 0;
	font-size: 1em; color: inherit;
	svg {
		font-size: .875em;
	}
}
h2 {
	font-size: $h2-font-size;
}
h3 {
	font-size: $h3-font-size;
}

strong, b, th, .text-strong {
	font-weight: $font-weight-bold;
}

.h-like, p, .p-like, ul, ol, dl, blockquote, pre, caption {
	margin: $default-line-height * 1em 0;
}

.underline-a-like {
	border-bottom: .15em solid #12a6c6;
    text-decoration: none;
    color: #2c3e50;
}

a, button.btn--link-like {
	color: $link-color; text-decoration: underline;
	&:hover,
	&:focus {
		text-decoration: none;
	}
	&.is-deco-reversed {
		text-decoration: none;
		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
	img, svg {
		text-decoration: none;
	}
}


// Typo helpers ---------------------------------------------------------------


a.cta-link {
	font-weight: $font-weight-bold; color: $link-cta-color;
}
.h-reset,
.text-reset {
	font: inherit; color: $default-color;
}
.h-reset {
	margin: initial; padding: 0;
}
@each $sizes in $font-sizes {
	$class: map-get($sizes, 'class');
	$size: map-get($sizes, 'size');

	.text-#{$class} {
		font-size: $size;
	}
}
.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.text-middle {
	vertical-align: middle;
}
.text-uppercase {
	text-transform: uppercase;
}
.text-uppercase-first-letter:first-letter {
	text-transform: uppercase;
}
.text-normal {
	font-weight: $font-weight-normal;
}
.text-bold {
	font-weight: $font-weight-bold;
}
.text-italic {
	font-style: italic;
}
.text-nowrap {
	white-space: nowrap;
}
.text-ellipsis {
	overflow: hidden;
	white-space: nowrap; text-overflow: ellipsis;
}
.text-wrap {
	white-space: normal;
}
.text-break {
	word-break: break-all; hyphens: none;
}
.text-pre-line {
	white-space: pre-line;
}
.text-regular {
	color: $default-color;
}
.text-focus {
	color: $focus-color;
}
.text-cta {
	color: $cta-color;
}
.text-grey, .text-gray {
	color: $darkgrey;
}
.text-softgrey, .text-softgray {
	color: $softgrey;
}
.text-white {
	color: $white;
}
.text-valid {
	color: $valid-color;
}
.text-warning {
	color: $warning-color;
}
.text-alert {
	color: $alert-color;
}
.text-favorite {
	color: $favorite-color;
}
.text-inactive {
	color: $inactive-color;
}
.text-placeholder {
	color: $input-placeholder-color;
}
