.monitoring-page-title-icon {
	@include media('<=small') {
		display: none;
	}
	@include media('>medium', '<=large') {
		display: none;
	}
}

.monitoring-summary {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: $global-gutters $global-gutters; justify-items: stretch; align-items: stretch;

	@include media('>large') {
		grid-template-columns: 2fr 3fr;
	}

	&-evol-item {
		margin: .75em;

		@include media('>large', '<=1350px') {
			flex-basis: 40%;
		}
	}
}

.monitoring-metrics-popin {
	padding: $global-gutters/2; box-sizing: border-box;

	&-form {
		display: flex; height: 100%;
		flex-flow: column nowrap;
	}

	&-tabs {
		flex: 0 0 auto;
	}

	&-main-frame {
		flex: 1 1 auto;
	}

	&-list {
		display: flex; margin: $global-gutters 0;
		flex-flow: row wrap; justify-content: flex-start; align-items: flex-start;
		&-item {
			min-width: 0; padding: .5em .5em .5em 0; box-sizing: border-box;
			flex: 0 1 (100% / 3);
		}
	}

	&-ctrl {
		padding-top: $global-gutters;
	}
}
