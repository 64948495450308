/* Usage:
	<svg class="loading-spinner">
		<use class="track" xlink:href="@views.utils.SVGRouter.getSpriteRoute("loopingPath")"/>
		<use class="line" xlink:href="@views.utils.SVGRouter.getSpriteRoute("loopingPath")"/>
	</svg>
*/

.loading-spinner {
	height: 1em;
	&-line, &-track {
		stroke-width: $spinner-stroke-width;
		stroke-linecap: $spinner-stroke-linecap;
	}
	&-track {
		stroke: $spinner-looping-bg;
	}
	&-line {
		stroke: $spinner-looping-color;
		stroke-dasharray: $spinner-looping-length, ($spinner-total-length - $spinner-looping-length);
		animation: loading-loop $spinner-total-duration linear infinite;
	}
	&.is-inherit {
		.loading-spinner-track {
			stroke: $spinner-looping-inherit-bg;
		}
		.loading-spinner-line {
			stroke: $spinner-looping-inherit-color;
		}
	}
}

@keyframes loading-loop {
	from { stroke-dashoffset: 0px; }
	to   { stroke-dashoffset: #{- $spinner-total-length}px; } // Needs unit to work in Edge
}


// Aplications

.generic-waiting {
	margin: 1em auto;
	font-size: 5em;
}
