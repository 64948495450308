.repeated-view-table {
	@extend .list-table;
	margin-top: 0;
	text-align: center;
	th, td {
		width: calc(100% / 8);
	}
	thead th {
		font-weight: $font-weight-normal;
	}
	tbody tr {
		&.repeated-view-table-title {
			background-color: $list-table-tr-bg-alternate;
		}
		&:nth-child(even) {
			background-color: $list-table-tr-bg;
		}
		&:hover {
			background-color: $list-table-tr-bg-hover;
		}
	}
	tbody {
		th,
		td {
			padding-top: .25em; padding-bottom: .25em;
		}
	}
}
