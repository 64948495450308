.toolbar-legend {
	@extend %hr;
	.wrapper-full {
		padding-top: $global-gutters; padding-bottom: $global-gutters;
	}
	@include media(">small") {
		&::before,
		&::after {
			position: absolute; left: 0; right: 0; height: $global-gutters;
			background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 100%);
			content: ''; pointer-events: none;
		}
		&::before {
			top: 0;
			background: linear-gradient(to top, rgba($white, 0) 0%, rgba($white, 1) 90%);
		}
		&::after {
			bottom: 0;
			background: linear-gradient(to bottom, rgba($white, 0) 0%, rgba($white, 1) 90%);
		}
		.wrapper-full {
			overflow: auto;
			// (default-line-height + legend-item margins) * number of lines
			max-height: ($default-line-height + .5em) * 3;
		}
	}
}
