.home-top {
	padding: 5% 0;
	color: $white;

	a {
		color: inherit;
	}

	.front-title {
		margin: 5% 0 2.5%;
	}

	&-sub-title {
		color: inherit;
	}
}

.home-top-signup {
	padding: .01em $global-gutters;
	border-radius: $global-border-radius; box-shadow: 0 0 10em rgba($darkblue, .1);
	background: rgba($white, .2);

	&-form {
		max-width: 30em; margin: 0 auto;
	}
}

.home-opening-sentence {
	font-size: 2em;
	strong {
		color: $darkblue;
	}
}

.home-illustration {
	margin: auto;

	&-first {
		@include media(">large") {
			order: -1;
		}
	}
}
