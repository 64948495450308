.analysis-form {

	&-name,
	&-url {
		@extend input;
		display: flex; margin-top: $global-gutters; padding: 0; overflow: hidden;
		flex-flow: row nowrap; align-items: stretch;

		&-icon {
			display: flex; padding: 0 .5em;
			justify-content: center; align-items: center;
			border-radius: $global-border-radius 0 0 $global-border-radius;
			background-color: $softwhite;
			font-size: 1.5em;
		}

		&-input {
			width: auto; margin: 0; padding: 1em $input-hpadding;
			border-radius: 0;
			flex: 1 1 auto;
			border: none; box-shadow: none;
			&,
			&:focus {
				box-shadow: none;
			}
		}
	}

	&-url {
		&-button {
			margin: $input-border-width*-1; padding: 1em $btn-hpadding*2; overflow: hidden;
			border: $input-border-width solid darken($focus-color, 10%); border-radius: 0 $global-border-radius $global-border-radius 0;
			background-color: $focus-color;
			font-style: $button-font-style; font-weight: $button-font-weight; font-family: $button-font-family; text-align: center; text-decoration: none; color: $white;

			&.rwd-under-small {
				margin: 0; padding: $input-vpadding * 2 $btn-hpadding;
				border-radius: $global-border-radius;
			}

			&:hover,
			&:focus {
				background-color: darken($focus-color, 5%);
			}

			&:disabled {
				cursor: not-allowed;
			}

			@include disabled-button($btn-disabled-border, $btn-disabled-bg, $btn-disabled-color);
		}
	}

	&-device,
	&-har {
		padding-top: $input-vpadding * 2; padding-bottom: $input-vpadding * 2;
	}

	&-drpdn-har-menu {
		display: flex; padding: $global-gutters/2;
		flex-flow: row wrap; justify-content: center; align-items: flex-start;

		& > * {
			flex: 1 1 9em;
		}

		&-col-title {
			display: block; margin-bottom: .5em;
			color: $inactive-color;
		}

		&-list {
			@extend %ul-reset;

			&-item {
				@extend .btn--reset, .text-ellipsis;
				display: block; width: 100%; padding: .375em .25em;
				border-radius: $global-border-radius;

				&:hover,
				&:focus {
					background-color: $dropdown-item-hover-bg;
					color: $dropdown-item-hover-color;
				}

				&-icon svg,
				&-text {
					vertical-align: middle;
				}

				&.is-selected {
					background: $dropdown-item-selected-bg;
				}
			}
		}
	}

	&-advanced-settings {
		.form-group--center,
		.form-group--nowrap {
			margin-left: -.5em; margin-right: -.5em;

			& > *:first-child {
				margin-left: .5em;
			}

			& > *:last-child {
				margin-right: .5em;
			}
		}

		.cookies-form-group {
			@include media('<medium') {
				flex-wrap: wrap;
			}
		}

		[id*='DrpdnResolution'] {
			width: 100%; max-width: 11em;
		}
		[id*='DrpdnBandwidth'] {
			width: 100%; max-width: 28em;
		}
		[id*='InpWaitRepeatedView'] {
			width: 100%; max-width: 6.5em;
		}
		.button-advanced-settings {
			width: 8em;
		}
	}
}
