.offers {
	line-height: initial;
}

.offers .banner{
	background-color:#fff;
}

.offers .banner h1 {
	padding-bottom: 0;
}

.offers h2 {
	color: #2b3d4f;
	font-size: 22px;
	margin: 40px 0 0;
	font-weight: bold;
}

.offers a {
	text-decoration: none;
}

.offers a:hover:not(.button) {
	text-decoration: underline;
}

.offers #punchline img{
	float:right;
	margin-right: 50px;
}

.offers #punchline .punchlineText{
	display: inline-block;
	font-size:25px;
	margin-top: 10px;
}
.offers h1 .bold, .offers h2 .bold{
	letter-spacing: 0.5px;
	font-size: 27px;
}

.separator{
	border:2px solid #eeeeee;
}

.titleSeparator {
	width: 35%;
	border-top: none;
	margin: 30px auto 60px;
    border-bottom: solid 2px #2C3E50;
}

.offers span.ion, .offers span.sprite{
	display: inline-block !important;
}

.offers span.ion-document-text {
	font-weight: normal;
}

.offers span.ion-person{
	font-size: 24px;
	padding: 0 3px;
	color: #34465e;
}

.offers span.ion-ios7-infinite{
	font-size: 30px;
}

.offers span.sprite-info{
	padding-left: 0;
	margin-right: 8px;
	float: right;
	margin-top: 13px;
}

.offers table {
	color: #2c3e50;
	font-size: 18px;
    border-collapse: collapse;
}

.offers th {
	font-weight: 600;
}

.offers th .price {
	font-size: 37px;
   	letter-spacing: 2px;
	padding-left: 5px;
	color: #12a6c6;
}

.offers th .ion {
	width: 35px;
    	height: 35px;
	font-size: 30px;
	padding-top: 2px;
	margin-right: 8px;
	margin-left: 8px;
	text-align: center;
	border: 2px solid #2c3e50;
	-webkit-border-radius: 50%;
	border-radius: 50%;
}

.offers th .ion.clock {
	border: 0px;
	font-size: 47px;
	font-weight: normal;
}

.offers thead th {
	font-size: 18px;
	font-weight: 100;
    	padding: 15px 0;
}

.offers input, .offers select {
	margin: 0;
	padding: 8px;
	width: 120px;
    font-size: 14px;
    border-width: 1px;
	font-family: Arial;
    box-sizing: border-box;
}

.offers .secondaryFeatures {
	font-size: 14px;
}

.offers .secondaryFeatures .offerFeatureDesc {
	font-size: 13px;
}


.offers .secondaryFeatures th .ion {
	width: 27px;
    	height: 25px;
	font-size: 20px;
	font-weight: normal;
	padding-top: 2px;
	border: 1px solid #859099;
}

.offers .secondaryFeatures th .ion-ios7-box-outline{
    font-weight: bold;
}

.offers .secondaryFeatures th {
	padding: 15px 1px;
}

.offers .secondaryFeatures td {
	height: auto;
}

.offers tr.featuresSeparation {
	border-top: 3px solid #E5E7E8;
}

.offers .secondaryFeatures tr p {
	margin: 0;
}

.offers td {
	font-size: 18px;
}
/*
.offers td.highlight {
	color: #27ae60;
	font-weight: bold;
}
*/

.offers td.number {
	font-size: 22px;
}

.offers .unlimitedValue {
	margin-top: 12px;
}


.offers tbody th {
	text-align: left;
	padding: 5px 2px 5px 2px;
}

.offers tbody th.offerTitlePart {
	padding: 10px 0 5px 10px;
    background-color: #2b3d4f;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
}

.offers .offerFeatureDesc {
	margin: 0;
	color: #859099;
	font-size: 13px;
    font-weight: normal;
    text-transform: none;
}

.offers tbody tr {
	border-top: 1px solid #E5E7E8;
}

.offers tbody td {
	text-align: center;
	height:90px;
}



.offers tbody td, .offers thead th {
	width: 164px;
	border-left: 1px solid #E5E7E8;
}

.offers .buttonRow th {
	text-align: right;
    padding-right: 15px;
    border-right: 1px solid #E5E7E8;
}

.offers .buttonRow td {
	border: none;
}

/*.offers .buttonRow td p {
	color: #999;
	font-size: 13px;
    	margin-bottom: 5px;
    	padding-top: 16px;
}*/

.offers .buttonRow .button.disabled {
	cursor: default;
	color: #CCC;
	padding: 10px 18px;
    border: 2px solid #CCC;
}

.offers .buttonRow .button.disabled:active {
    position: initial;
    top: 0px;
}

.offers .subText {
    color: #859099;
    text-transform: none;
    font-size: 15px;
}


.offers .subText.nbTests {
    font-size: 13px;
}

.offers th .subText, .offers td .vatText {
    font-size: 13px;
}

.offers .intro{
	width: 500px;
	color:#2c3e50;
	font-size: 19px;
	text-align:center;
	margin: 30px auto 5px auto;
}

.intro p{
	margin:0;
}

/**
 * Estimate
 */

#estimateResume .paiementMeans h3{
	margin: 14px 0 5px 0;
	font-size: 13px;
}

#estimateResume  .paiementMeans h3 img{
  vertical-align:middle;
}

#estimateResume .paiementMeans p{
	margin-top: 10px;
}

#estimateResume .paiementMeans{
	float:right;
	margin-left: 0px;
	display: block;
}

#estimateResume .paiementMeans p{
	font-size: 13px;
}

.infoOffer{
	padding: 0;
	height: 115px;
	border: solid 2px #34465e;
	border-radius: 8px;
	margin-bottom: 25px;
	margin-top: 50px;
}

.yearlyBlock{
	height: 60px;
	background-color: #34465e;
	color: #fff;
	font-size: 16px;
}

#chooseYearly{
	float: left;
	margin-top: 18px;
	padding-left: 30px;
	letter-spacing:0.8px;
	display: inline-block;
	font-size:18px;
	font-weight: bold;
}

.paiementMeans {
	padding-top: 50px;
	padding-bottom: 30px;
	text-align: center;
	color: #737373;
	font-size: 14px;
}

.paiementMeans img{
  	vertical-align:middle;
	margin-right: 10px;
	margin-bottom: 3px;
}

.paiementMeans p, .paiementMeans h3{
    color: #737373;
}
.paiementMeans p{
	margin-top: 16px;
	text-align: center;
}

#bankTransferPaiementMeans {
    margin-left: 45px;
	color: #2C3E50;
	font-weight: 600;
}

.totaling{
	color:#33485C;
	padding: 2px 0;
}

.offerHighlighted .totaling{
	padding: 3px 0;
}

/*
 * Tooltips
 */
.offer .tooltip {
	width: 450px;
}
.offer .tooltip-inner {
	padding: 13px;
	color: #fcfcfc;
	text-align: left;
	background-color: #5c6b7e;
	line-height: 1.5em;
	opacity: 1;
	font-weight: normal;
	font-size: 16px;
	margin-bottom: 11px;
}
.offer .tooltip.in {
	opacity: 1;
}

.offer .tooltip.top .tooltip-arrow {
  margin-left: -18px;
  border-width: 18px 18px 0;
  border-top-color: #5c6b7e;
}


#subscriptionsTitle {
	float: left;
}

.offers .toggleTitle {
	margin-bottom: 3px;
}

.billingContainer {
	color: #2c3e50;
	font-size: 16px;
	margin-top: 35px;
	margin-bottom: 35px;
}

#customPlanTH {
	padding-top: 0px;
}

.selectedBilling, #customPlanTitle {
	color: #12a6c6;
}

#customPlanPrice {
	font-size: 25px;
}

#currencyContainer {
	font-size: 16px;
	margin: 0 0 15px 0;
	padding: 8px 4px;
	border: none;
	border-radius: 0;
    border-bottom: 1px solid #2c3e50;
}

.yearlySwitchContainer{
    display: inline-block;
}

/**
 * Switch
 */
#paymentInfos .yearlySwitchContainer hr {
	margin: 0;
}

#paymentInfos .yearlySwitchContainer{
	margin:20px 0;
	display: block;
}

#paymentInfos .yearlySwitchContainer .toggleTitle {
	font-size: 16px;
	padding-bottom: 10px;
	padding-left: 3px;
	color: #aaa;
}

#paymentInfos .yearlySwitchContainer .toggle {
	font-size: 16px;
	color: #cdcdcd;
	background-color: #FFF;
	border: 1px solid #CCC;
	border-radius: 5px;
	text-align: center;
	padding: 1px;
	cursor: pointer;
}

.yearlySwitchContainer span {
	cursor: pointer;
}

#paymentInfos .offers .yearlySwitchContainer .toggle {
	display: flex;
}

#paymentInfos .yearlySwitchContainer .toggle > div {
	padding: 7px 0;
}

#paymentInfos .yearlySwitchContainer .toggleSelected {
	font-weight: bold;
	color: #FFF;
	background-color: #34465e;
	border-radius: 5px;
}

.packVat {
	color: #cdcdcd;
  	font-size: 14px;
}

.yearlySwitchContainer .switch {
	display: inline-block;
	margin: 0 5px -5px;
}

.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.cmn-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

input.cmn-toggle-round-flat + label {
  padding: 2px;
  width: 38px;
  height: 18px;
  background-color: #12a6c6;
  border-radius: 60px;
  transition: background 0.4s;
}
input.cmn-toggle-round-flat + label:after {
  display: block;
  position: absolute;
  content: "";
}
input.cmn-toggle-round-flat + label:after {
  top: 3px;
  left: 3px;
  bottom: 3px;
  width: 16px;
  background-color: white;
  border-radius: 52px;
  transition: margin 0.4s, background 0.4s;
}
input.cmn-toggle-round-flat:checked + label {
  background-color: #12a6c6;
}
input.cmn-toggle-round-flat:checked + label:after {
  margin-left: 20px;
}

/**
 * Customers
 */
.offers #customers {
	padding: 60px 0;
}


.offers #customers h2 {
    padding-bottom: 40px;
    font-style: italic;
    margin: 0;
}

.offers #discountEstimateContainer {
	width: auto;
}

.offers .offersContactBlock {
	background-color: #e5efee;
	padding-top: 1px;
	padding-bottom: 50px;
}

.offers .offersContactBlock .button {
	font-size: 20px;
	margin-top: 10px;
	padding: 10px 18px;
}

/**
 * FAQ
 */
.offersQuestions {
 	padding-bottom: 20px;
}

.offersQuestions h3 {
	color: #999;
	padding-top: 20px;
	font-size: 18px;
}

/**
* UPSELL
*/
#upsell{
	margin-bottom: 40px;
}

#upsell #offerUpsellInfo{
	margin: 0 auto 50px auto;
}

#upsell.offers tbody td, #upsell.offers thead th {
    width: 220px;
}

#upsell.offers tbody td.customOffer, #upsell.offers tbody th.customOffer{
    width: 370px;
}

#upsell.offers .offerFeatureDesc{
    padding-right: 80px;
}

#upsell.offers tr.buttonRow th{
    font-size: 20px;
    padding-left: 30px;
    text-align: left;
}

#upsell tr.highlight, #upsell tr.highlight select, #upsell tr.highlight input {
	color: #12a6c6;
}

#upsell tr.highlight select, #upsell tr.highlight input {
	font-weight: bold;
}

#upsell tr.highlight{
	background-color: #f1f3f5;
}

.offers .emptyBlueButton {
	color:#12a6c6;
	font-weight:bold;
	padding: 10px 18px;
	border: 2px solid #12a6c6;
}

.offers .emptyBlueButton:hover{
	color: #fff;
	background-color: #12a6c6;
}

.offers .buttonRow .blueButton {
	font-weight:bold;
	padding: 12px 18px;
}
