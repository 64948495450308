.breadcrumb {
	display: flex; min-width: 0;
	flex: 1 1 auto; flex-flow: row nowrap; justify-content: flex-start; align-items: center;

	&-icon {
		flex: 0 0 auto;

		svg {
			display: block;
			font-size: 1.25em; line-height: 1;
			vertical-align: middle;
		}
	}

	&-item {
		min-width: 0;
		flex: 0 1 auto;

		&[aria-current] {
			font-weight: $font-weight-bold;
		}
	}
}
