/* white label */
#profile .formContainer.whitelabelContainer {
	width: 910px;
}

#whiteLabelContainer {
	width: 800px;
	margin: auto;
}

#whiteLabelContainer #whiteLabelError{
	text-align:center;
	background-color: #1D8AC3;
	border: 1px solid #123F9F;
	color: #FFF;
	padding: 12px 40px;
	margin: 0;
	border-bottom: 0px;
}
#whiteLabelContainer #whiteLabelError a {
	color: #FFF;
	text-decoration: underline;
}

#whitelabelPreview {
	background-color: #FFF;
	border: 1px solid #000;
	padding-top: 50px;
}

/* override some css from pdf du to diff between pdf render and browsers */
#whiteLabelContainer .reportActionContainer {
	float: right;
	margin-top: 0;
}

#whiteLabelContainer .sunButton{
	padding: 7px 15px 7px 15px;
}

#whitelabelPreview #firstPDFPage h1 {
	text-align: center;
	font-size: 3em;
	line-height: 1.3;
}

#whitelabelPreview .headResume {
	width: 88%;
	margin-bottom: 200px;
}

#whitelabelPreview .headResume textarea {
	width: 97%;
}

#whitelabelPreview span.button {
	float: right;
	margin: 10px 0 0 10px;
}

#whitelabelPreview #firstPDFPage h3.url {
	font-size: 1.3em;
	margin-top: 70px;
}

#whitelabelPreview .editable {
	position: relative;
	border: 1px dashed #999;
	padding: 20px 40px;
	cursor: text;
}

#whitelabelPreview .item-progress-box {
	height: 3px;
	margin: 0 180px;
}

#whitelabelPreview .leftFooterLogo .item-progress-box {
	margin: 0 60px 0 0;
}

#whitelabelPreview .item-progress {
	background-color: #48BDDE;
	height: 100%;
}

#whitelabelPreview div.width80middle {
	margin-top: 30px;
}

#logobw {
	float: left;
	margin: 0 0 30px 50px;
	color:#c0c5ca;
}

#logobw img {
	height:35px;
}

#whitelabelPreview .smallSnapshotMobileContainer{
	height:118px;
	width:180px;;
	border-right-width:6px;
	border-left-width:5px;
}

#bottomright {
	float: right;
	margin: 18px 50px;
	text-align:right;
}

#bottomright .date {
	color:#c0c5ca;
}

#bottomright .pageNumber {
	color:#7f8b8c;
}

#ngpopup.deleteAssociatedAccount{
	width: 400px;
}

#ngpopup.deleteAssociatedAccount #emailToDelete{
	font-weight:bold;
	word-wrap: break-word;
}


/*
 * Popup stop recurring
 */

#ngpopup.stopRecurring{
    width: 540px;
}

#ngpopup.stopRecurring fieldset {
    border: none;
}

#ngpopup.stopRecurring legend {
    font-size: 16px;
    margin-bottom: 10px;
}

#ngpopup.stopRecurring input[type="text"] {
    margin-left: 22px;
}

#unsubMarketing{
	text-align: center;
	color: #2c3e50;
}

#unsubMarketing p{
    font-size: 15px;
    margin-top: 20px;
}

#unsubMarketing .button{
    margin-top: 50px;
}
