.pagination {
	@extend [class^='group-btn'];
	margin: 0; padding: 0;
	list-style: none;
	.is-active {
		border-color: darken($focus-color, 15%);
		background: $focus-color;
		color: $white;
		pointer-events: none; cursor: not-allowed;
	}
}
