.monitoring-item {
	display: flex; padding: $global-gutters;
	flex-flow: column nowrap;
	border-radius: $global-border-radius;
	background: $default-bg;
	transition: box-shadow $default-duration $default-timing-function;

	&.is-favorite {
		box-shadow: 0 0 0 2px $favorite-color;
	}

	&-title {
		@extend .h-reset;
		& > * {
			vertical-align: middle;
		}
	}

	&-sstitle {
		display: block; margin: 0;
	}

	/*
	position: relative;
	&-btn-delete {
		position: absolute; top: .25em; right: .25em;
		width: $default-line-height * 1em;
		text-align: center;
	}
	*/

	hr {
		margin-left: -$global-gutters; margin-right: -$global-gutters;
	}

	&-error {
		margin: #{$default-line-height * 1em} #{-$global-gutters}; padding: #{$default-line-height * 1em} $global-gutters;
		border: solid transparent; border-width: 1px 0;
		background-color: rgba($alert-color, .15);
		font-weight: $font-weight-bold; color: $alert-color;
		a:not([class*='btn']) {
			color: inherit;
		}
	}

	&-kpi {
		display: flex;
		flex-flow: row nowrap; justify-content: space-between; align-items: flex-end;
		@include media('<=medium') {
			flex-wrap: wrap; justify-content: center;
		}

		.vr {
			width: 1px; height: 2em; margin-bottom: 3em;
			flex: 0 0 1px;
			background: $hr-color;
			@include media('<=medium') {
				display: none;
			}
		}

		&-item {
			flex: 0 1 20%;
			@include media('<=medium') {
				margin-top: .5em;
				flex: 1 0 6em;
			}
		}

		&-title {
			@extend .h-reset;
			padding: 0 .25em;
		}

		&-progress {
			display: flex; width: 4.25em; height: 4.25em; margin: .5em auto;
			justify-content: center; align-items: center;
			border: 2px solid currentColor; border-radius: 50%;
			font-weight: $font-weight-bold;
		}

		&-donut {
			// 2px of 2 borders = 4px = 0.25em when 1.6rem on body
			// so size of 4.25em + 0.25em
			width: 4.5em ; height: 4.5em; margin: .5em auto;

			& > div {
				width: 1em; height: 1em;
				font-size: 4.5em;
			}

			svg {
				display: block; width: 1em !important; height: 1em !important;
			}
		}
	}

	&-info {
		display: flex; margin: 0;
		flex: 1 1 auto; flex-flow: column nowrap; justify-content: center; align-items: center;
	}
}

[class*=' grid-'],
[class^='grid-'] {
	.monitoring-item {
		height: 100%; box-sizing: border-box;
	}
}
